import { GET_ADDRESS} from '../actionTypes';

export default function(state = {}, action) {
    switch (action.type) {
        case GET_ADDRESS:
            return action.payload;
        
        default:
            return state;
    }
}
