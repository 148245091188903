import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Col,Descriptions, Row ,Skeleton} from 'antd';
import StatCard from './templates/StatCard';
import { ShoppingBag, ShoppingCart, Target, User } from 'react-feather';
import {analyticsdata,dashreport} from '../actions/dashboardAction';
import Chart from 'react-apexcharts'
import {userstats} from '../actions/chartAction';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          loading1:false,
          today:"",
          week:"",
          month:"",
          year:"",
          dashstats:"",
          series: [],
          options: {
          legend: {
            position: 'right'
          },
          labels: [],
           }, 
           series1: [],
           options1: {
           legend: {
             position: 'right'
           },
           labels: [],
            },
            series2: [],
           options2: {
           legend: {
             position: 'right'
           },
           labels: [],
            },
            series3: [],
           options3: {
           legend: {
             position: 'right'
           },
           labels: [],
            },
            series4: [],
            options4: {
            legend: {
              position: 'right'
            },
            labels: [],
             }
        };
      }

      componentDidMount()
      {
      this.setState({ loading: true,loading1: true,loadingchart:true });
      this.props.analyticsdata().then((result) =>{
        //  console.log('resolved',result)
        this.setState({ loading: false, dashstats:this.props.dashdata });
        })
      this.props.dashreport().then((result) => {
      //console.log(this.props.common.Today)
      this.setState({ today: this.props.common.today_arr_obj,week: this.props.common.week_arr_obj,month:this.props.common.month_arr_obj,loading1: false,year: this.props.common.year_arr_obj,  });
      }).catch((err) => {
        
      });
      
      this.props.userstats().then((result) =>{
       // console.log('resolved',result)
       this.setState({
         series: result.modelNumberArrAndroid,
         options: {...this.state.options,labels: result.modelNameArrAndroid,},
         series1: result.modelNumberArrIos,
         options1: {...this.state.options,labels: result.modelNameArrIos,},
         series2: result.versionNumberArrAndroid,
         options2: {...this.state.options,labels: result.versionNameArrAndroid,},
         series3: result.versionNumberArrIos,
         options3: {...this.state.options,labels: result.versionNameArrIos,},
         series4: result.deviceOSNumberArr,
         options4: {...this.state.options,labels: result.deviceOSNameArr,},
         loadingchart:false
        })
       }).catch((err) => {
       
       });
      }
      componentDidUpdate(prevProps) {
       
        // Typical usage (don't forget to compare props):
        if (this.props.common !== prevProps.common) {
          console.log("Did Update")
          this.setState({ today: this.props.common.today_arr_obj,week: this.props.common.week_arr_obj,month:this.props.common.month_arr_obj,year: this.props.common.year_arr_obj,  });
        }
        if(this.props.dashdata !== prevProps.dashdata) {
          console.log("Did Update new")
          this.setState({ dashstats:this.props.dashdata });
        }
      }  

  render() {
    const {today,week,year,month,dashstats} = this.state;
    return (
        <div>
        <div className="container-fluid addp">
        <div className="container-fluid">
        <div className="row">
        <div className="col-sm-12">
            <h4>Dashboard</h4>
        </div>
      
        </div>
            
        </div>
    </div>
    <div className="container-fluid allproduct">
        <Row gutter={16}>
      
        <Col xs={24} sm={12} md={6}>
          <Skeleton loading={this.state.loading}>
          <StatCard
            type="fill"
            title="Total Orders"
            value={dashstats.total_order}
            icon={<ShoppingCart size={20} strokeWidth={2} />}
            color={"primary"}
            
          />
          </Skeleton>
        </Col>
         
        <Col xs={24} sm={12} md={6}>
         <Skeleton loading={this.state.loading}>
          <StatCard
            type="fill"
            title="Total Customers"
            value={dashstats.total_customer}
            icon={<User size={20} strokeWidth={2} />}
            color={"primary"}
            
          />
          </Skeleton>
        </Col>
        <Col xs={24} sm={12} md={6}>
         <Skeleton loading={this.state.loading}>
          <StatCard
            type="fill"
            title="Total Shops"
            value={dashstats.total_shop}
            icon={<ShoppingBag size={20} strokeWidth={2} />}
            color={"primary"}
            
          />
          </Skeleton>
        </Col>
        <Col xs={24} sm={12} md={6}>
         <Skeleton loading={this.state.loading}>
          <StatCard
            type="fill"
            title="Total Turnover"
            value={dashstats.total_cost}
            icon={<Target size={20} strokeWidth={2} />}
            color={"primary"}
            
          />
          </Skeleton>
        </Col>
        
      </Row>
      <div className="m-t-30">
      <Row gutter={24}>
      <Col xs={24} sm={12} md={12}>
      <Skeleton loading={this.state.loading1}>
      <Descriptions size="small" title="Today" bordered>
    <Descriptions.Item label="Total Orders" span={4}>{today.total_order}</Descriptions.Item>
    <Descriptions.Item label="Pending Orders" span={4}>{today.pending_order}</Descriptions.Item>
    <Descriptions.Item label="Delivered Orders" span={4}>{today.completed_order}</Descriptions.Item>
    <Descriptions.Item label="Rejected Orders" span={4}>
    {today.rejected_order}
    </Descriptions.Item>
    </Descriptions>
    </Skeleton>
      </Col>
      <Col xs={24} sm={12} md={12}>
      <Skeleton loading={this.state.loading1}>
      <Descriptions size="small" title="This Week" bordered>
      <Descriptions.Item label="Total Orders" span={4}>{week.total_order}</Descriptions.Item>
    <Descriptions.Item label="Pending Orders" span={4}>{week.pending_order}</Descriptions.Item>
    <Descriptions.Item label="Delivered Orders" span={4}>{week.completed_order}</Descriptions.Item>
    <Descriptions.Item label="Rejected Orders" span={4}>
    {week.rejected_order}
    </Descriptions.Item>
    </Descriptions>
    </Skeleton>
      </Col>
      </Row>
      </div>
      <div className="m-t-30">
      <Row gutter={24}>
      <Col xs={24} sm={24} md={12}>
      <Skeleton loading={this.state.loading1}>
      <Descriptions size="small" title="This Month" bordered>
      <Descriptions.Item label="Total Orders" span={4}>{month.total_order}</Descriptions.Item>
    <Descriptions.Item label="Pending Orders" span={4}>{month.pending_order}</Descriptions.Item>
    <Descriptions.Item label="Delivered Orders" span={4}>{month.completed_order}</Descriptions.Item>
    <Descriptions.Item label="Rejected Orders" span={4}>
    {month.rejected_order}
    </Descriptions.Item>
    </Descriptions>
    </Skeleton>
      </Col>
      <Col xs={24} sm={24} md={12}>
      <Skeleton loading={this.state.loading1}>
      <Descriptions size="small" title="This Year" bordered>
      <Descriptions.Item label="Total Orders" span={4}>{year.total_order}</Descriptions.Item>
    <Descriptions.Item label="Pending Orders" span={4}>{year.pending_order}</Descriptions.Item>
    <Descriptions.Item label="Delivered Orders" span={4}>{year.completed_order}</Descriptions.Item>
    <Descriptions.Item label="Rejected Orders" span={4}>
    {year.rejected_order}
    </Descriptions.Item>
    </Descriptions>
    </Skeleton>
      </Col>
      </Row>
      </div>
      <div className="m-t-30">
      <Row gutter={24}>
      
      <Col xs={24} sm={24} md={12}>
      <Skeleton loading={this.state.loadingchart}>
        <div className="panel panel-default">
  <div className="panel-heading">Android Device Used</div>
  <div className="panel-body"><Chart options={this.state.options} series={this.state.series} type="pie" width={500} height={320} /></div>
</div>
        </Skeleton>
        </Col>
        <Col xs={24} sm={24} md={12}>
        <Skeleton loading={this.state.loadingchart}>
        <div className="panel panel-default">
  <div className="panel-heading">iOS Device Used</div>
  <div className="panel-body"><Chart options={this.state.options1} series={this.state.series1} type="pie" width={500} height={320} /></div>
</div>
        </Skeleton>
        </Col>
        <Col xs={24} sm={24} md={12}>
        <Skeleton loading={this.state.loadingchart}>
        <div className="panel panel-default">
  <div className="panel-heading">Android App Version Used</div>
  <div className="panel-body"><Chart options={this.state.options2} series={this.state.series2} type="pie" width={500} height={320} /></div>
</div>
        </Skeleton>
        </Col>
        <Col xs={24} sm={24} md={12}>
        <Skeleton loading={this.state.loadingchart}>
        <div className="panel panel-default">
  <div className="panel-heading">iOS App Version Used</div>
  <div className="panel-body"><Chart options={this.state.options3} series={this.state.series3} type="pie" width={500} height={320} /></div>
</div>
        </Skeleton>
        </Col>
        <Col xs={24} sm={24} md={12}>
        <Skeleton loading={this.state.loadingchart}>
        <div className="panel panel-default">
  <div className="panel-heading">Device OS Used</div>
  <div className="panel-body"><Chart options={this.state.options4} series={this.state.series4} type="pie" width={500} height={320} /></div>
</div>
        </Skeleton>
        </Col>
      </Row>
      </div>
      </div></div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
  user: state.user,
  dashdata:state.dashdata,
  common:state.common
};
}
export default connect(mapStateToProps, {analyticsdata,dashreport,userstats})(Dashboard);

