import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {emailLogin, logout} from '../actions/userAction';
import {StoreAllRequest} from '../actions/assetAction';
import {db} from '../firebase'
import {Icon,Button, Input, Tag, Table, Tooltip, Popconfirm,Divider } from 'antd';
import '../styles/css/App.css';
import Highlighter from 'react-highlight-words';
import { TimeAgo } from "@n1ru4l/react-time-ago";




  
class StoreRequest extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
            disabled: true,
            searchText: '',
            tdata:null,
            loading: true
        };

    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => { this.searchInput = node; }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: (text) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
      })

      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
      }
    
      handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
        console.log(this.state.searchText);
      }


      confirm = async (key) => {

        this.setState({
          loading: true
        });
    
    
    var washingtonRef = db.collection("Store_Request").doc(key);
    
    // Set the "capital" field of the city 'DC'
        try {
          await washingtonRef.update({
            Ref_MD_Store_Request_Status_Id: 3
          });
          console.log("Document successfully updated!");
          this.props.StoreAllRequest().then(res => {
            console.log(res);
            this.setState({
                tdata :this.props.order,
                loading:false
              });	
        })
        .catch(error => {
            console.log(error);
              }
          )
        }
        catch (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        }
    
      }
      cancel(e) {
        console.log(e);
        // message.error('Click on No');
      }
 
      
    componentWillMount()
    {
        //  componentWillMount
        
        this.props.StoreAllRequest().then(res => {
          console.log(res);
          this.setState({
              tdata :this.props.order,
              loading:false
            });	
      })
      .catch(error => {
          console.log(error);
          this.setState({
          
            loading:false
          });	
            }
        )
             
    }  
    handleResett = () => {
      //  componentWillMount
      
      const initialDate = new Date();
      
      this.setState({
        loading: true,
        
      });
  
      this.props.StoreAllRequest().then(res => {
        console.log(res);
        this.setState({
          tdata: this.props.order,
          loading: false,
          date:initialDate
        });
      })
        .catch(error => {
          console.log(error);
        }
        )
  
  
    }
//===============================================

    render() {
       // console.log(this.props.products);
        const columns = [
           
          {
            title: 'Store Name',
            dataIndex: 'store_name',
            key: 'store_name',
            ...this.getColumnSearchProps('store_name'),
          },
          {
            title: 'Store Address',
            dataIndex: 'address',
            key: 'address',
           
          },
          {
            title: 'Manager Name',
            dataIndex: 'manager_name',
            key: 'manager_name',
            ...this.getColumnSearchProps('manager_name'),
          },{
            title: 'Email Address',
            dataIndex: 'manager_email',
            key: 'manager_email',
            ...this.getColumnSearchProps('manager_email'),
          },
         
          {
            title: 'Mobile Number',
            dataIndex: 'manager_phone',
            key: 'manager_phone',
           
          },
          {
            title: 'Created Time',
            dataIndex: 'request_date',
            key: 'request_date',
           
          },
         
          {
            title: 'Status',
            dataIndex: 'request_status',
            key: 'request_status',
            render: (text, record) => (
              
              <span>
                {(() => {
                  switch (record.requeststatus_id) {
                    case 2:
                      return (
                        <Tag color="green">{record.request_status}</Tag>
                      );
                      case 3:
                      return (
                        <Tag color="volcano">{record.request_status}</Tag>
                      );
                      case 1:
                      return (
                        <Tag color="orange">{record.request_status}</Tag>
                      );

                      case 4:
                      return (
                        <Tag color="orange">{record.request_status}</Tag>
                      );

                    default:
                      return (
                        <Tag color="orange">{record.order_status}</Tag>
                      );
                  }
                })()}
               
              </span>
            ),
           
          },
          
          
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width	: 120,
            render: (text, record) => (
                <span>
                  {record.requeststatus_id === 2 || record.requeststatus_id === 3 || record.requeststatus_id === 4 ?
                   (
                    <Tooltip title="Approve">
                        <Button type="primary" shape="circle" icon="check-circle" disabled/>
                    </Tooltip>
                    ) : (<Link to={{ pathname: '/store',  requestid: record.key }}>
                    <Tooltip title="Approve">
                        <Button type="primary" shape="circle" icon="check-circle"/>
                    </Tooltip>
                    </Link>) }
                     <Divider type="vertical" /> 
                     {record.requeststatus_id === 2 || record.requeststatus_id === 3 || record.requeststatus_id === 4 ?
                     (
                    <Tooltip title="Reject">
                        <Button type="danger" shape="circle" icon="close-circle" disabled/>
                    </Tooltip>) : (<Popconfirm title="Are you sure ?" onConfirm={() => this.confirm(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
                    <Tooltip title="Reject">
                        <Button type="danger" shape="circle" icon="close-circle"/>
                    </Tooltip>
                
                </Popconfirm>) }
                  
                    
                </span>
              ),
            
          }
        ];



          return (
            <div>
            <div className="container-fluid addp">
                <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12">
                    <h4>Store Request</h4>
                </div>
                {/* <div className="col-sm-8 text-right">
                <Link to="/store"><Button className="pull-right" type="primary" icon="shop"></Button></Link>
                </div> */}
                
                </div>
                    
                </div>
            </div>
            <div className="container-fluid allproduct">
            <div className="text-right filterdiv">
            <span style={{ marginRight: 10 }}>Last Refreshed Time : <TimeAgo date={new Date()}>{({ value }) => <b><Icon type="clock-circle" theme="outlined" /> {value}</b>}</TimeAgo></span>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={this.handleResett}>
              Refresh
            </Button>

          </div>
           
                <Table columns={columns} dataSource={this.state.tdata} loading={this.state.loading} scroll={{ x: 1500 }}  bordered />
            </div>
            
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
    order: state.order
  };
}


export default connect(mapStateToProps, {emailLogin, logout, StoreAllRequest})(StoreRequest);
