import React, {Component} from 'react';
import {connect} from 'react-redux';
import {allcustomer} from '../actions/userAction';
import {pushmessage} from '../actions/assetAction';
import _ from 'lodash';
import {Form, Input, Row, Button,Select,message,Radio,Spin, Icon} from 'antd';
import request from "superagent";
import {Dev_URL} from '../actionTypes';
import '../styles/css/App.css';
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;


const { TextArea } = Input;
const { Option } = Select;

class pusher extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
          fileList1: "",
          iconLoading: false ,
          loading:true,
          visible:false,
          enable:true,
          phonenumber:[],
        };

    }

   
    componentWillMount()
    {
    //  componentWillMount
    this.props.allcustomer().then(res=>{
        this.setState({loading: false})
    })
    this.props.pushmessage().then(res=>{
        this.setState({message:res})
    })
}
    
    handleReset = () => {
      this.props.form.resetFields();
      this.setState({
        iconLoading: false,
        fileList1: "",
        enable:true,visible:false
      });	
    }
  

    handleSubmit = (e) => {
      e.preventDefault();
     

      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
            this.setState({loading:true})
         if(this.state.visible === true)
         {
          var dataobj = {
            customerid  : values.customer,
            description : values.message
          }
         }
         else
         {
          dataobj = {
            customerid  : "ALL",
            description : values.message
          }
         }
           
            request.post(Dev_URL+'/sendpushnotification')
            .send(dataobj)
            .then((res) => {
              console.log(res.body);
              if (res.body.returncode === 200) {
                console.log(res.body);
                this.setState({
                 loading: false,enable:true,visible:false
                });
                this.props.form.resetFields();
                message.success('Notification Sent Successfully!');
              } 
              else 
              {
                this.setState({
                  loader: false, visible: true,
                });
                message.error('Something went wrong!');
              }
            })
            .catch((err) => {
              console.log(err);
            });

        }
      });
    }
   
    renderCategory()
    {
         return _.map(this.props.driver,(driver,key) => {
                return (
                  <Option value={driver.key} key={key}> 
                  {driver.Customer_Name}
                  </Option>
                );
              })
    }
    renderCategory1()
    {
         return _.map(this.props.common,(common,key) => {
                return (
                  <Option value={common.key} key={key}> 
                  {common.type}
                  </Option>
                );
              })
    }
    renderCategory2()
    {
         return _.map(this.state.template,(template,key) => {
             
                return (
                  <Option value={template} key={key}> 
                  template - {key+1}
                  </Option>
                );
              })
    }
    onChange = e => {
      if(e.target.value === "2")
      {
        this.setState({
          visible: true,
        });
      }
      else
      {
        this.setState({
          visible: false,
        });
      }
      
    };
    notifychange = (e) =>{
        console.log(e)
        this.props.form.resetFields('template');
        this.props.form.resetFields('message');
        var prodeal = this.state.message;
        var uniquename = _.find(prodeal, function (o) {
            return o.key === e;
        });
        console.log(uniquename)
        this.setState({
            template: uniquename.template,
            enable:false
          });
    }
    templatechange = (e) => {
        this
        .props
        .form
        .setFieldsValue({message: e})
    }
// ======================================================

    render() {
     
      
    
      const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
      };


//==============================================================================================================//
       
          return (
<div>
<Spin  indicator={antIcon} spinning={this.state.loading}>
            <div className="container-fluid addp">
            <div className="container-fluid">
            <div className="row">
                <div className="col-sm-4">
                    <h4>Push Notification</h4>
                </div>
                {/* <div className="col-sm-8 text-right">
                <Link to="/subcategory"><Button className="pull-right" type="dark" icon="arrow-left">Back To Sub-Category</Button></Link>
                </div> */}
                </div>
            
            </div>
            </div>
          <div className="container-fluid text-left addproduct">
          
          <Row>
             <Form onSubmit={this.handleSubmit}>
             <Form.Item
                  {...formItemLayout}
                  label="Type"
                >
                  {getFieldDecorator('notificationtype', {
                    rules: [{ required: true, message: 'Select any one option'}],
                  })(
                    <Select onChange={this.notifychange}
                      placeholder="Select option"
                    >
                    {this.renderCategory1()}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  {...formItemLayout}
                  label="Template"
                >
                  {getFieldDecorator('template', {
                    rules: [{ required: true, message: 'Select any one template'}],
                  })(
                    <Select onChange={this.templatechange} disabled={this.state.enable}
                      placeholder="Select template"
                    >
                    {this.renderCategory2()}
                    </Select>
                  )}
                </Form.Item>
             <Form.Item  {...formItemLayout} label="Customer Selection">
          {getFieldDecorator('radio-group',{
                    rules: [{ required: true, message: 'Select option'}],
                  })(
            <Radio.Group onChange={this.onChange}>
              <Radio value="1">All</Radio>
              <Radio value="2">Custom</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
        {this.state.visible === true ? ( <Form.Item
                  {...formItemLayout}
                  label="Customer"
                >
                  {getFieldDecorator('customer', {
                    rules: [{ required: true, message: 'Select Customer', type: 'array'}],
                  })(
                    <Select 
                    showSearch
                    mode="multiple"
                    placeholder="Select Customer"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    >
                    {this.renderCategory()}
                    </Select>
                  )}
                </Form.Item>):null}
               

                <Form.Item
                  {...formItemLayout}
                  label="Message"
                >
                  {getFieldDecorator('message', {
                    rules: [
                        {
                          required: true,
                          message: 'please enter message',
                        },
                        { max: 160, message: 'Message text maximum 160 characters only' }
                      ],
                  })(
                    <TextArea rows ={5} />
                  )}
                </Form.Item>
              
{/* =============================== Submit Button ======================================== */}

        <Form.Item
          wrapperCol={{ span: 12, offset: 6 }}
        >
          <Button type="primary" size="large" htmlType="submit" loading={this.state.iconLoading}>Submit</Button>
          <Button style={{ marginLeft: 15 }} size="large" onClick={this.handleReset}>
              Clear
            </Button>
        </Form.Item>
          </Form>
         
        </Row>
       
         </div>
         </Spin>
        </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
    driver: state.driver,
    common:state.common
  };
}

const WrappedRegistrationForm = Form.create()(pusher);

export default connect(mapStateToProps, {allcustomer,pushmessage})(WrappedRegistrationForm);
