import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/userAction";
import { Layout, Icon,List } from "antd";
import { Link } from "react-router-dom";

const { Header } = Layout;

const data = [
  "Racing car sprays burning fuel into crowd.",
  "Japanese princess to wed commoner.",
  "Australian walks 100km after outback crash.",
  "Man charged over missing wedding girl.",
  "Los Angeles battles huge wildfires."
];

class TopHeader extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      current: "1"
    };
  }

  rendernotifications() {
    return (
      <List
        size="small"
        header=""
        footer=""
        bordered
        dataSource={data}
        renderItem={item => <List.Item>{item}</List.Item>}
      />
    );
  }

  render() {
    return (
      <Header className="text-right">
        {/* <Dropdown
          overlay={this.rendernotifications()}
          placement="bottomRight"
          trigger={["click"]}
          className="noticon"
        >
          <Badge count={2}>
            <Button shape="circle" icon="bell" />
          </Badge>
        </Dropdown> */}

        <Link to="/logout" onClick={() => this.props.logout()}>
          <Icon type="logout" />
          <span className="nav-text"> Logout</span>
        </Link>
      </Header>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(
  mapStateToProps,
  { logout }
)(TopHeader);
