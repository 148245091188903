import React, { Component } from "react";
import { connect } from "react-redux";
import { auth,firedb } from "../firebase";
import { allchat, singlechat, postchat } from "../actions/chatAction";
import { Link } from "react-router-dom";
import { Input, Avatar, Modal, Button, Badge, Empty,Popconfirm } from "antd";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import chatimg from "../styles/img/chat.png";
import "../styles/css/App.css";
import moment from "moment";
const { Search } = Input;

class Subcategory extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      admin_user: "",
      value: "",
      cust_id: "",
      chatall: ""
    };
  }

  componentWillMount() {
    //  componentWillMount
    var user = auth.currentUser.uid;
    this.setState({
      admin_user: user,
      chatall: this.props.chatall
    });

    this.props
      .allchat()
      .then(result => {
        var key = result[0].id;
        console.log(key);

        this.setState({
          active_user: key
        });
        this.props
          .singlechat(key)
          .then(result => {})
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chat !== this.props.chat) {
      const { scrollbars } = this.refs;
      scrollbars.scrollToBottom();
      this.props.allchat();
    }
    if (prevProps.chatall !== this.props.chatall) {
      console.log(this.props.chatall);
      this.setState({
        chatall: this.props.chatall
      });
    }
  }

  showchat = (e, data) => {
    console.log(data);
    var prevActive = this.state.active_user;
    this.props.singlechat(data, prevActive);
    this.setState({
      active_user: data
    });
  };
  deletemsg = (data) =>{
    var prevActive = this.state.active_user;
    var adaRef = firedb.ref("/Messages/"+ prevActive +"/"+ data);
    adaRef.remove()
      .then(function() {
        console.log("Remove succeeded.")
      })
      .catch(function(error) {
        console.log("Remove failed: " + error.message)
      });
  }

  renderuserlist() {
    return _.map(this.props.chatall, (chatall, key) => {
      return (
        <li key={key}>
          <Link to="#" onClick={e => this.showchat(e, chatall.id)}>
            <div
              className={
                chatall.id === this.state.active_user
                  ? "chat_list active_chat"
                  : "chat_list"
              }
            >
              <div className="chat_people">
                <div className="chat_img">
                  <span style={{ marginRight: 24 }}>
                    <Badge count={chatall.Count}>
                      {chatall.Ref_Customer_Profile_Pic !== "" ? (
                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                      ) : (
                        <Avatar
                          size={35}
                          style={{
                            color: "#f56a00",
                            backgroundColor: "#fde3cf"
                          }}
                        >
                          {chatall.Ref_Customer_Name.charAt(0)}
                        </Avatar>
                      )}
                    </Badge>
                  </span>
                </div>
                <div className="chat_ib">
                  <h5>
                    {chatall.Ref_Customer_Name}
                    <span className="chat_date">
                      {/* {moment(chatall.Updated_At)
                        .startOf("hour")
                        .fromNow()} */}
                      {moment.unix(chatall.Updated_At).format("LLL")}
                    </span>
                  </h5>
                  {/* <p>Test, which is a new approach to have all solutions 
                astrology under one roof.</p> */}
                </div>
              </div>
            </div>
          </Link>
        </li>
      );
    });
  }

  rendermessagelist() {
    return _.map(this.props.chat, (chat, key) => {
      if (this.state.admin_user === chat.From_Id) {
        return (
          <div className="outgoing_msg" key={key}>
            <div className="sent_msg">
              <span className="time_date">
                {" "}
                {moment.unix(chat.Time_Stamp).format("LLL")}
              </span>
              {chat.Text === "" ? (
                <div className="thumbnail">
                  <img src={chat.Image_Url} alt="" />{" "}
                </div>
              ) : (
                <p>{chat.Text}</p>
              )}
              <span className="pull-right"><Popconfirm title="Are you sure？"  onConfirm={e => this.deletemsg(key)}
     okText="Yes" cancelText="No">
              <Link className="text-danger small" to="#" >Delete</Link>
  </Popconfirm></span>
            </div>
          </div>
        );
      } else {
        return (
          <div className="incoming_msg" key={key}>
            <div className="incoming_msg_img">
              {" "}
              <img src={chatimg} alt="" />{" "}
            </div>
            <div className="received_msg">
              <div className="received_withd_msg">
                <span className="time_date">
                  {" "}
                  {moment.unix(chat.Time_Stamp).format("LLL")}
                </span>
                {chat.Text === "" ? (
                  <div className="thumbnail">
                    <img
                      onClick={e => this.showModal(e, chat.Image_Url)}
                      src={chat.Image_Url}
                      alt=""
                    />
                  </div>
                ) : (
                  <p>{chat.Text}</p>
                )}
              </div>
            </div>
          </div>
        );
      }
    });
  }

  myFunction = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("h5")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };
  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  handleKeyPress(event) {
    if (event.key !== "Enter") return;
    this.props
      .postchat(this.state.admin_user, this.state.active_user, this.state.value)
      .then(result => {
        this.setState({ value: "" });
      })
      .catch(err => {});
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props
      .postchat(this.state.admin_user, this.state.active_user, this.state.value)
      .then(result => {
        this.setState({ value: "" });
      })
      .catch(err => {});
  };
  showModal = (e, data) => {
    console.log(data);

    this.setState({
      visible: true,
      mimage: data
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  // ======================================================

  render() {
    //==============================================================================================================//

    return (
      <div className="container-fluid padd0">
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Close
            </Button>
          ]}
        >
          <div className="center-item">
            <img src={this.state.mimage} className="img-responsive" alt="" />
          </div>
        </Modal>
        <div className="messaging">
          <div className="inbox_msg">
            <div className="inbox_people">
              <div className="headind_srch">
                <Search
                  placeholder="Search User..!"
                  id="myInput"
                  onKeyUp={() => this.myFunction()}
                  onSearch={value => console.log(value)}
                  enterButton
                />
              </div>
              <Scrollbars style={{ height: "70vh" }} autoHide>
                <ul id="myUL">
                  {this.props.chatall !== undefined ? (
                    this.renderuserlist()
                  ) : (
                    <Empty />
                  )}
                </ul>
              </Scrollbars>
            </div>
            <div className="mesgs">
              <div className="conversation">
                <Scrollbars
                  ref="scrollbars"
                  style={{ height: "76vh" }}
                  autoHide
                >
                  <div className="convoinner">
                    {this.props.chatall !== undefined ? (
                      this.rendermessagelist()
                    ) : (
                      <Empty />
                    )}
                  </div>
                </Scrollbars>
              </div>

              <div className="type_msg">
                <form onSubmit={this.handleSubmit}>
                  <div className="input_msg_write">
                    <input
                      type="text"
                      value={this.state.value}
                      onChange={this.handleChange}
                      className="write_msg"
                      placeholder="Type a message"
                      required
                    />
                    <button
                      onKeyPress={this.handleKeyPress}
                      className="msg_send_btn"
                      type="submit"
                    >
                      <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    chatall: state.chatall,
    chat: state.chat
  };
}

export default connect(mapStateToProps, { allchat, singlechat, postchat })(
  Subcategory
);