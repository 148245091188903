import { GET_COMMON_SLOT,GET_ORDER_LIVE } from '../actionTypes';

export default function(state = {}, action) {
    switch (action.type) {
        case GET_COMMON_SLOT:
            return action.payload;
        case GET_ORDER_LIVE:
            return action.payload;
        default:
            return state;
    }
}