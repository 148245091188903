import React, { Component } from "react";
import Logo from "../styles/img/logo-main.svg";
import appstore from "../styles/img/app-store-icon.svg";

class Confirmation extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="center-item m-t-30">
          <div className="col-md-3">
            {" "}
            <img src={Logo} className="img-responsive thankyou-logo" alt="loginimage" />
          </div>
        </div>
        <h3 className="text-center m-t-30">Thank you for Signing Up!</h3>
        <h4 className="text-center m-t-30">
          Your account has been created successfully and is ready to use.
        </h4>
        <h4 className="text-center">
          Simply download or update the Shopezy app now and be more mobile.
        </h4>
        <div className="center-item m-t-30">
          <div className="col-md-2">
            {" "}
            <img src={appstore} className="img-responsive" alt="loginimage" />
          </div>
        </div>
      </div>
    );
  }
}

export default Confirmation;
