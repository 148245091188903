import {db, storage} from '../firebase';
import {GET_DRIVER, GET_CITY, GET_COUNTRY_CODE} from '../actionTypes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';

/*================================ Driver Module =======================*/

export function drivers() {

    return dispatch => {

        return new Promise((resolve, reject) => {
           
            var arr_ride_status = [];
            db.collection('MD_Driver_Ride_Status').get()
            .then(snap_driver_ride_status => {
                snap_driver_ride_status.forEach(doc_driver_ride_status => {
                    var statuskey = doc_driver_ride_status.data().Driver_Ride_Status;
                    var statusid = doc_driver_ride_status.data().Driver_Ride_Status_Id;
                    var obj_ride_status = {
                        'ride_statusId': statusid,
                        'ride_statuskey': statuskey
                    }
                    arr_ride_status.push(obj_ride_status);
                });
                
                get_driverridestatus(arr_ride_status)
           
            })
            .catch(err => {
                console.log('Error getting documents', err);
                reject(err);
            });
        
            
        function get_driverridestatus(arr_ride_status) {
            // console.log(arr_ride_status)
            //MD_Driver_Ride_Status
            var arr_driver_status = [];
            db.collection('MD_Driver_Status').get()
                .then(snap_driver_status => {
                    snap_driver_status.forEach(doc_driver_status => {
                        var driver_status_key = doc_driver_status.data().Driver_Status;
                        var driver_status_id = doc_driver_status.data().Driver_Status_Id;
                        var obj_driver_status = {
                            'driver_statusId': driver_status_id,
                            'driver_statuskey': driver_status_key
                        }
                        arr_driver_status.push(obj_driver_status);
                    });
                   // console.log(arr_driver_status);
                    get_driver_status(arr_ride_status, arr_driver_status)
                })
                .catch(err => {
                    console.log('Error getting documents', err);
                    reject(err);
                });
            // end MD_Driver_Ride_Status
        }
    
        function get_driver_status(arr_ride_status, arr_driver_status) {
            // console.log(arr_ride_status)
            // console.log(arr_driver_status)
            //MD_Country_Code
            var arr_country_code = [];
            db.collection('MD_Country_Code').get()
                .then(snap_country => {
                    snap_country.forEach(doc_country => {
                        var country_autoId = doc_country.id,
                        country_code = doc_country.data().Country_Code
                       var obj_country_code = {
                            country_autoId: country_autoId,
                            country_code: country_code
                        }
                        arr_country_code.push(obj_country_code)
                    });
                    get_country_code(arr_ride_status, arr_driver_status, arr_country_code)
                })
                .catch(err => {
                    console.log('Error getting country code', err);
                    reject(err);
                });
        }

        function get_country_code(arr_ride_status, arr_driver_status, arr_country_code) {

            var arr_driver = [];
            var arr_driver_commission = [];
            var arr_driver_availablestatus = [];
             
            db.collection("Driver_Available_Status").get()
            .then(function(docavailblestatus) {
                docavailblestatus.forEach(function(doc_availblestatus) {
                    var Driverid = doc_availblestatus.id;
                    // Driver_Outstanding_Commission
                    var dir_dirver_status_Id = doc_availblestatus.data().Driver_Status_Id;
                    arr_driver_availablestatus.push({
                        "driverid": Driverid,
                        "dirver_status_Id": dir_dirver_status_Id
                    });
                    
                })
          //console.log(arr_driver_availablestatus);
                var outstandingdriverdocRef = db.collection("Driver_Outstanding_Commission_Master");
            outstandingdriverdocRef.get().then(function(outstandingdriverdoc) {
               // var snap_sizecom = outstandingdriverdoc.size;
                outstandingdriverdoc.forEach(function(outstandingdriverdocs) {
                    var Driverid = outstandingdriverdocs.id;
                    // Driver_Outstanding_Commission
                    var Driver_Outstanding_Balance = outstandingdriverdocs.data().Driver_Outstanding_Balance;
                    var Driver_Currency_Symbol = outstandingdriverdocs.data().Driver_Currency_Symbol;
                    // if(Driver_Outstanding_Balance===undefined){
                    //     Driver_Outstanding_Balance="N/A";
                    // }
                    arr_driver_commission.push({
                        "driverid": Driverid,
                        "Driver_Outstanding_Balance": Driver_Outstanding_Balance,
                        "Driver_Currency_Symbol":Driver_Currency_Symbol
                    });
                })
                 console.log(arr_driver_commission);
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
            db.collection('Driver_Master').orderBy('Updated_At', 'desc').get()
            .then(sanp_driver => {
                var snap_size = sanp_driver.size;
                if (snap_size !== 0) {
                    sanp_driver.forEach(doc_dirver => {
                        var dri_doc_id = doc_dirver.id;
                 
                        var driverdocRef = db.collection("Driver_Shared_Information").doc(dri_doc_id);
                        driverdocRef.get().then(function(driverdoc) {
                            var dir_company_Id = doc_dirver.data().Ref_Driver_Company_Id;
                            var dri_country_code = driverdoc.data().Ref_Driver_Country_Code_Id
                            if (dir_company_Id === "") 
                            {
                                dri_doc_id = doc_dirver.id;
                               // console.log(dri_doc_id);
                                var dir_fname = driverdoc.data().Driver_First_Name
                                var dir_lname = driverdoc.data().Driver_Last_Name
                                var dir_mobile_num = driverdoc.data().Driver_Mobile_Number
                                
                                var dri_name = dir_fname + ' ' + dir_lname;
                                // console.log(dri_name)
                                var dir_email = doc_dirver.data().Driver_Email_Address;
                                // dir_mobile_num = doc_dirver.data().Driver_Mobile_Number;
                                var dir_ride_status_Id = doc_dirver.data().Driver_Ride_Status_Id;
                                var dir_update_date = moment(doc_dirver.data().Updated_At.toDate()).format('MMMM Do YYYY, h:mm:ss a');
                                //console.log(dir_ride_status_Id);

                                //arr_ride_status
                                if (dir_ride_status_Id) 
                                {
                                    var selectedride_status = arr_ride_status[arr_ride_status.map(function(item1) 
                                    {
                                        return item1.ride_statusId;
                                    }).indexOf(dir_ride_status_Id)];
                                    var dir_ride_status = selectedride_status.ride_statuskey;
                                   // console.log(dir_ride_status);
                                } 
                                else 
                                {
                                    dir_ride_status = "N/A";
                                }
                                 
                                //arr_driver_status
                                if (arr_driver_availablestatus) 
                                {
                                    var selecteddriver_status = arr_driver_availablestatus[arr_driver_availablestatus.map(function(item2) 
                                    {return item2.driverid;
                                    }).indexOf(dri_doc_id)];

                                    //console.log(selecteddriver_status);

                                    if(selecteddriver_status === undefined)
                                    {
                                        var dir_driver_status="N/A";
                                    }
                                    else
                                    {
                                        var driver_statusid =selecteddriver_status.dirver_status_Id;
                                        var selecteddriverstatus = arr_driver_status[arr_driver_status.map(function(item2) {
                                            return item2.driver_statusId;
                                        }).indexOf(driver_statusid)];
                                        dir_driver_status = selecteddriverstatus.driver_statuskey;
                                        var d_status_Id = selecteddriverstatus.driver_statusId;
                                       // console.log(dir_driver_status);
                                    }
                               
                                } 
                                else 
                                {
                                    dir_driver_status = "N/A";
                                }

                                //country code
                                if (dri_country_code) {
                                    var selectecountry_code = arr_country_code[arr_country_code.map(function(item) {
                                        return item.country_autoId;
                                    }).indexOf(dri_country_code)];
                                    var Country_code = selectecountry_code.country_code;
                                } else {
                                    Country_code = '';
                                }
                                var dri_phone_num = '+' + Country_code + dir_mobile_num;

                                // Driver Commission Status Check
                                var Outstanding_Amount;
                                var commission_status = arr_driver_commission[arr_driver_commission.map(function(item2) {
                                    return item2.driverid;
                                    }).indexOf(dri_doc_id)];
                                   //  console.log(commission_status);
                                    if(commission_status === undefined)
                                    {
                                        Outstanding_Amount = "--";
                                    }
                                    else
                                    {
                                        Outstanding_Amount=commission_status.Driver_Currency_Symbol+' '+commission_status.Driver_Outstanding_Balance
                                       
                                    }



                                var obj_arr = {
                                    key: dri_doc_id,
                                    driver: dri_name,
                                    phone: dri_phone_num,
                                    email: dir_email,
                                    organization: 'N/A',
                                   outstanding: Outstanding_Amount,
                                    trip: dir_ride_status,
                                    drivestatus: dir_driver_status,
                                    drivestatusid: d_status_Id,
                                    update_at:dir_update_date
                                    // Driver_Status_ID: dir_dirver_status_Id
                                };
                                arr_driver.push(obj_arr);
                                var arr_length = arr_driver.length;
                                if (arr_length === snap_size) 
                                {
                                    // console.log('response', arr_length + ' ' + snap_size)
                                   // console.log(arr_driver)
                                    dispatch({
                                        type: GET_DRIVER,
                                        payload: arr_driver
                                    });
                                    resolve(arr_driver);
                                   
                                }
                                // })
                                // console.log('snap_size', snap_size)
                                // console.log('obj_arr_length', arr_driver.length)
                            } 
                            else 
                            { 
                                // company id exist
                                // console.log(dir_company_Id)
                                // 
                                db.collection('Company_Master').doc(dir_company_Id).get()
                                    .then(snap_company => {
                                        var Company_count = snap_company.size;
                                        // console.log(dir_company_Id)
                                        if (Company_count !== 0) {
                                            var dir_company_name = snap_company.data().Company_Name;
                                            dri_doc_id = doc_dirver.id;
                                            dir_fname = driverdoc.data().Driver_First_Name
                                            dir_lname = driverdoc.data().Driver_Last_Name
                                            dir_mobile_num = driverdoc.data().Driver_Mobile_Number
                                            dri_name = dir_fname + ' ' + dir_lname;
                                            dir_email = doc_dirver.data().Driver_Email_Address;
                                            // dir_mobile_num = doc_dirver.data().Driver_Mobile_Number;
                                            dir_ride_status_Id = doc_dirver.data().Driver_Ride_Status_Id;
                                            // dir_dirver_status_Id = doc_dirver.data().Driver_Status_Id;
                                            //arr_ride_status
                                            if (dir_ride_status_Id) {
                                                selectedride_status = arr_ride_status[arr_ride_status.map(function(item1) {
                                                    return item1.ride_statusId;
                                                }).indexOf(dir_ride_status_Id)];
                                                dir_ride_status = selectedride_status.ride_statuskey;
                                            } else {
                                                dir_ride_status = "N/A";
                                            }

                                           //arr_driver_status
                                if (arr_driver_availablestatus) 
                                {
                                    var selecteddriver_status = arr_driver_availablestatus[arr_driver_availablestatus.map(function(item2) 
                                    {return item2.driverid;
                                    }).indexOf(dri_doc_id)];

                                    //console.log(selecteddriver_status);

                                    if(selecteddriver_status === undefined)
                                    {
                                        var dir_driver_status="N/A";
                                    }
                                    else
                                    {
                                        var driver_statusid =selecteddriver_status.dirver_status_Id;
                                        var selecteddriverstatus = arr_driver_status[arr_driver_status.map(function(item2) {
                                            return item2.driver_statusId;
                                        }).indexOf(driver_statusid)];
                                        dir_driver_status = selecteddriverstatus.driver_statuskey;
                                        var d_status_Id = selecteddriverstatus.driver_statusId;
                                        console.log(dir_driver_status);
                                    }
                               
                                } 
                                else 
                                {
                                    dir_driver_status = "N/A";
                                }

                                            //country code
                                            if (dri_country_code) {
                                                selectecountry_code = arr_country_code[arr_country_code.map(function(item) {
                                                    return item.country_autoId;
                                                }).indexOf(dri_country_code)];
                                                Country_code = selectecountry_code.country_code;
                                            } 
                                            else 
                                            {
                                                Country_code = '';
                                            }
                                            dri_phone_num = '+' + Country_code + dir_mobile_num;
                                            // console.log("Driver_Outstanding_Commission",Driver_Outstanding_Balance+'/'+dri_doc_id)
                                            // var Driver_Outstanding_Commission = outstandingdriverdoc.data().n
                                            obj_arr = {
                                                key: dri_doc_id,
                                                driver: dri_name,
                                                phone: dri_phone_num,
                                                email: dir_email,
                                                organization: dir_company_name,
                                               outstanding: '0',
                                                trip: dir_ride_status,
                                                drivestatus: dir_driver_status,
                                                drivestatusid :d_status_Id,
                                                update_at:dir_update_date
                                            };
                                            arr_driver.push(obj_arr);
                                            arr_length = arr_driver.length;
                                            if (arr_length === snap_size) {
                                                // console.log('response', arr_length + ' ' + snap_size)
                                                // console.log(arr_driver)
                                                 dispatch({
                                                    type: GET_DRIVER,
                                                    payload: arr_driver
                                                });
                                                resolve(arr_driver);
                                            }
                                            // })
                                        }
                                        // console.log('snap_size', snap_size)
                                        // console.log('obj_arr_length', arr_driver.length)
                                    }).catch(err => {
                                        console.log('Error getting country code', err);
                                        reject(err);
                                    });
                            }
                        }).catch(function(error) {
                            console.log("Error getting document:", error);
                            reject(error);
                        });
                    // }).catch(function(error) {
                    //     console.log("Error getting document:", error);
                    // });
                    });
                } else {
                    reject("No Data");
                }
            })
            .catch(err => {
                console.log('Error getting Driver_Master', err);
                reject(err);
            });

            }).catch(function(error) {
                console.log("Error getting document:", error);
            });



        }


            })

        }

    }

    export function DriverDetail(drvrid) {
        return dispatch => {
            var id = drvrid;
            return new Promise((resolve, reject) => {

                var docRef = db.collection("Driver_Master").doc(id);
                docRef.get().then(function (doc) {
                    if (doc.exists) {
                        // console.log("Document data:", doc.data());
                        var driverdoc = doc.data();
                        var Driver_Commission_Applicable = driverdoc.Driver_Commission_Applicable.toString();
                      //  console.log(Driver_Commission_Applicable);
                        
                        //var Driver_Status_Id = driverdoc.Driver_Status_Id;
                        var Driver_Email_Address = driverdoc.Driver_Email_Address;
                        var Ref_Driver_Company_Id = driverdoc.Ref_Driver_Company_Id;
                        var Ref_Operating_City_Id = driverdoc.Ref_Operating_City_Id;
                        var Driver_Commission_Percentage = driverdoc.Driver_Commission_Percentage;
                        var Driver_Documents = driverdoc.Driver_Documents

                        driver_shared_info(Driver_Documents,Driver_Commission_Applicable,Driver_Email_Address, Driver_Commission_Percentage, Ref_Driver_Company_Id, Ref_Operating_City_Id)
                        }
                        else {
                            // doc.data() will be undefined in this case
                            console.log("No such document! in Driver_Master");
                        }
                        }).catch(function (error) {
                        console.log("Driver_Master Error getting document:", error);
                        });



                        let driver_shared_info = (Driver_Documents,Driver_Commission_Applicable,Driver_Email_Address, Driver_Commission_Percentage, Ref_Driver_Company_Id, Ref_Operating_City_Id) =>
                        
                        {
                            var sharedinformationdriverdocRef = db.collection("Driver_Shared_Information").doc(id);
                            sharedinformationdriverdocRef.get().then(function (doc) {
                                if (doc.exists) 
                                {
                                    // console.log("Document data:", doc.data());
                                    var sharedinformationdriver = doc.data();
                                    var Driver_First_Name = sharedinformationdriver.Driver_First_Name;
                                    var Driver_Last_Name = sharedinformationdriver.Driver_Last_Name;
                                    var Driver_Mobile_Number = sharedinformationdriver.Driver_Mobile_Number;
                                    //var Driver_Profile_Image = sharedinformationdriver.Driver_Profile_Image;
                                    var Ref_Driver_Country_Code_Id = sharedinformationdriver.Ref_Driver_Country_Code_Id;
                                    //console.log(Ref_Driver_Country_Code_Id);
                                    drivercountrycode(Driver_Documents,Driver_Commission_Applicable,Driver_Email_Address, Driver_Commission_Percentage, Ref_Driver_Company_Id, Ref_Operating_City_Id,Driver_First_Name,Driver_Last_Name,Driver_Mobile_Number,Ref_Driver_Country_Code_Id)
    
                                } 
                                else 
                                {
                                    // doc.data() will be undefined in this case
                                    console.log("No such document! in Driver_Shared_Informatio");
                                }
                            }).catch(function (error) {
                                console.log("Driver_Shared_Information Error getting document:", error);
                            });
    

                        }

                        let drivercountrycode = (Driver_Documents,Driver_Commission_Applicable, Driver_Email_Address, Driver_Commission_Percentage, Ref_Driver_Company_Id, Ref_Operating_City_Id, Driver_First_Name, Driver_Last_Name, Driver_Mobile_Number, Ref_Driver_Country_Code_Id) => {

                           // console.log(Ref_Driver_Country_Code_Id);
                            db.collection("MD_Country_Code").doc(Ref_Driver_Country_Code_Id).get().then(function (doc) {
                                if (doc.exists) {
                                    // console.log("Document data:", doc.data());
                                    var drivercountrycode = doc.data();
                                    var Country_Code = drivercountrycode.documentId;

                                    county_code(Driver_Documents,Country_Code, Driver_First_Name, Driver_Last_Name, id, Driver_Mobile_Number, Driver_Email_Address, Ref_Driver_Company_Id, Ref_Operating_City_Id, Driver_Commission_Applicable, Driver_Commission_Percentage)
                                } 
                                else {
                                    // doc.data() will be undefined in this case
                                    console.log("No such document! in MD_Country_Code");
                                }
                            }).catch(function (error) {
                                console.log("MD_Country_Coden Error getting document:", error);
                            });


                        }

                        let county_code = (Driver_Documents,Country_Code, Driver_First_Name, Driver_Last_Name, id, Driver_Mobile_Number, Driver_Email_Address, Ref_Driver_Company_Id, Ref_Operating_City_Id, Driver_Commission_Applicable, Driver_Commission_Percentage) =>
                        {
                            var operatingcitiesRef = db.collection('Operating_City_Master').doc(Ref_Operating_City_Id);
                                    operatingcitiesRef.get().then((doc1) => {

                                        if (doc1.exists) {
                                            //console.log("Document data:", doc1.data().id);
                                            var operatingcity = Ref_Operating_City_Id;

                                            

                                            making_object(Driver_Documents,operatingcity,Country_Code, Driver_First_Name, Driver_Last_Name, id, Driver_Mobile_Number, Driver_Email_Address, Ref_Driver_Company_Id, Ref_Operating_City_Id, Driver_Commission_Applicable, Driver_Commission_Percentage)
                                        }
                                        else 
                                        {
                                            // doc.data() will be undefined in this case
                                            console.log("No such document! in Driver_Shared_Informatio");
                                        }
                                    }).catch(function (error) {
                                        console.log("Operating_City_Master Error getting document:", error);
                                    });
                        }

                        let making_object = (Driver_Documents,operatingcity,Country_Code, Driver_First_Name, Driver_Last_Name, id, Driver_Mobile_Number, Driver_Email_Address, Ref_Driver_Company_Id, Ref_Operating_City_Id, Driver_Commission_Applicable, Driver_Commission_Percentage) => {
                             
                            var arr_driver = {
                                id:id,
                                firstname: Driver_First_Name,
                                lastname:  Driver_Last_Name,
                                email: Driver_Email_Address,
                                mobile:Driver_Mobile_Number.toString(),
                                countycode:Country_Code,
                                commission: Driver_Commission_Applicable.toString(),
                                commission_value :Driver_Commission_Percentage,
                                company: 'N/A',
                                opeartingcity:operatingcity,
                                document :Driver_Documents
                
                            };
                            
                            dispatchevent(arr_driver)

                        }

                        let dispatchevent = (arr_driver) =>{

                            dispatch({
                                type: GET_DRIVER,
                                payload: arr_driver
                            });
                            resolve(arr_driver);


                        }


                        });

                        }

                        }



export function DriverUpdate(values , driverid, fileList) {

    return dispatch => {

        return new Promise((resolve, reject) => {

            var globalarray = [];
            fileList.forEach((f) => {
                storage.ref('Driver_Documents/'+ driverid + "/" +f.name).put(f).then(function (snapshot) {
                    snapshot.ref.getDownloadURL().then(function (downloadURL) {

                        globalarray.push(downloadURL);

                        if (fileList.length === globalarray.length) 
                        {

                        db.collection("Driver_Master").doc(driverid).update({
                            Driver_Documents: globalarray,
                            Ref_Operating_City_Id : values.city,
                            Driver_Email_Address: values.emailaddres,
                            Driver_Commission_Applicable:(values.commisonapplicable === "true"),
                            Driver_Commission_Percentage:values.commission,
                            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                        .then(function() {
                           // console.log("Document successfully updated!");

                            db.collection("Driver_Shared_Information").doc(driverid).update({
                                Driver_First_Name: values.firstname,
                                Driver_Last_Name : values.lastname,
                                Driver_Mobile_Number: parseInt(values.phone),
                                Ref_Driver_Country_Code_Id:values.countrycode,
                                Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
                            })
                            .then(function() {
                                console.log("Document successfully updated!");
                                resolve("Document successfully updated!");
                            })
                            .catch(function(error) {
                                // The document probably doesn't exist.
                                console.error("Error updating document: ", error);
                            });
    
                        })
                        .catch(function(error) {
                            // The document probably doesn't exist.
                            console.error("Error updating document: ", error);
                        });

                        }

                    })
                })

            })
            
           
        });

    }
    
}


export function CityTable() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            db.collection("Operating_City_Master")
                .onSnapshot(function (doc) {
                    const catList = [];
                    doc.forEach(function (doc) {
                        catList.push({
                            key: doc.id,
                            cname: doc.data().City_Name
                        });
                    });
                    // console.log("Current data: ", catList);
                    dispatch({
                        type: GET_CITY,
                        payload: catList
                    });
                    resolve(catList);
                });
        });
    }
}


export function CountryCodeTable() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            db.collection("MD_Country_Code")
                .onSnapshot(function (doc) {
                    const catList = [];
                    doc.forEach(function (doc) {
                        catList.push({
                            key: doc.id,
                            code: doc.data().Country_Code
                        });
                    });
                    // console.log("Current data: ", catList);
                    dispatch({
                        type: GET_COUNTRY_CODE,
                        payload: catList
                    });
                    resolve(catList);
                });
        });
    }
}

export function DriverSettleUp(drid , outamount,admin_user) {
  return dispatch => {
    return new Promise((resolve, reject) => {
        var Settledup = "SettledUp";
        var adminID = admin_user;
      var id = drid;
      var cretated_at = firebase.firestore.FieldValue.serverTimestamp();
      var OutstandingAmt = outamount;
     var OutstandingAmt_ary = OutstandingAmt.split(" ");
      var OutstandingAmount = parseFloat(OutstandingAmt_ary[1]);
      console.log(
        "OutstandingAmount",
        OutstandingAmount,
        "OutstandingAmt",
        OutstandingAmt
      );
      var Outstandings = 0;
      db
        .collection("Driver_Order_Commission")
        .where("Driver_Id", "==", id)
        .where("Ref_Driver_Settleup_Id", "==", "")
        .get()
        .then(snapshot => {
          if (snapshot.size !== 0) {
            console.log("snapshot_size", snapshot.size);
            var length = 1;
            snapshot.forEach(doc => {
              var Outstanding = parseFloat(doc.data().Outstanding);
              console.log("snapshot.size", snapshot.size);
              if (length === 1) {
                console.log("before size 1", Outstanding);
                Outstandings = Outstanding;
              } else {
                console.log("before", Outstanding);
                Outstandings += Outstanding;
              }
              console.log("after", Outstandings);
              length++;
            });
            // Outstanding = 0;
            // OutstandingAmount = 0;
            if (Outstandings === OutstandingAmount) {
              console.log("Outstanding equals", Outstandings);
              var data_outstanding = {
                Created_At: cretated_at,
                Ref_Driver_Id: id,
                Ref_admin_Id: adminID,
                OutstandingAmount_settledup: OutstandingAmount,
                status: Settledup
              };
              console.log(data_outstanding);
              db
                .collection("Driver_Settleup_Logs")
                .add(data_outstanding)
                .then(ref_log => {
                  var driver_log_autoid = ref_log.id;
                  db
                    .collection("Driver_Settleup_Logs")
                    .doc(driver_log_autoid)
                    .get()
                    .then(function(docRef_log) {
                      var ref_settledupdate = docRef_log.data().Created_At;
                      db
                        .collection("Driver_Outstanding_Commission_Master")
                        .doc(id)
                        .update({
                          Driver_Outstanding_Balance: 0,
                          Updated_At: cretated_at,
                          Settledup_Date: ref_settledupdate
                        })
                        .then(function(docRef) {
                          db
                            .collection("Driver_Order_Commission")
                            .where("Driver_Id", "==", id)
                            .where("Ref_Driver_Settleup_Id", "==", "")
                            .get()
                            .then(snapshot1 => {
                              if (snapshot1.size !== 0) {
                                snapshot1.forEach(doc => {
                                  var autoid_drivercommision = doc.id;
                                  db
                                    .collection("Driver_Order_Commission")
                                    .doc(autoid_drivercommision)
                                    .update({
                                      Ref_Driver_Settleup_Id: driver_log_autoid,
									  Outstanding:0
                                    });
                                  console.log(
                                    "Driver_Order_Commission for driverid: ",
                                    id,
                                    autoid_drivercommision
                                  );
                                });
                                resolve("Settledup successfully."
                                );
                              } else {
                                //console.error("Error document: ", error);
                               reject(
                                    "No data in Driver_Order_Commission collection"
            );
                              }
                            })
                            .catch(function(error) {
                              console.error("Error document: ", error);
                              reject(error);
                            });
                        })
                        .catch(function(error) {
                          console.error("Error document: ", error);
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      console.error("Error document: ", error);
                      reject(error);
                    });
                })
                .catch(function(error) {
                  console.error("Error message: ", error);
                  reject(error);
                });
            } else {
              
              reject(
                "Mismatch in outstanding amount."
);
            }
          } else {
            reject("No Outstanding Balance Available For Driver..!"
            );
          }
        })
        .catch(function(error) {
          console.error("Error message", error);
          reject(error);
        });
    });
  };
}