import React, { Component } from "react";
import Logo from "../styles/img/logo-main.svg";


class Failure extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="center-item m-t-30">
          <div className="col-md-3">
            {" "}
            <img src={Logo} className="img-responsive thankyou-logo" alt="loginimage" />
          </div>
        </div>
        <h3 className="text-center m-t-30">Sorry..!</h3>
        <h3 className="text-center m-t-30">Your request has been rejected</h3>
        <h3 className="text-center m-t-30">Due to Authentication Failure </h3>
        <h4 className="text-center m-t-30">
          Kinldy Contact Shopezy Administrator check your details 
        </h4>
        <h4 className="text-center">
          Contact : support@shopezy.app ,  Mobile : +44-2323232332
        </h4>
       
      </div>
    );
  }
}

export default Failure;
