import {db} from '../firebase';
import _ from "lodash";

export function userstats() {
return dispatch => {
    return new Promise((resolve, reject) => {
        var DeviceModelAnroid = [];
        var DeviceModelIos = [];
        var ClientVersionandroid = [];
        var ClientVersionios = [];
        var DeviceOs = [];
        
        db.collection("Customer_Device_Details").get()
        .then(function (querySnapshot) {
           // console.log(querySnapshot.size)
            querySnapshot.forEach(function (doc) {
        if (doc.data().Device_OS === "Android")
        {
        var deviceModelsandroid = doc.data().Device_Model;
        DeviceModelAnroid.push(deviceModelsandroid);
        }
        if (doc.data().Device_OS === "iOS")
        {
            var deviceModelsios = doc.data().Device_Model;
            DeviceModelIos.push(deviceModelsios);
        }
        if (doc.data().Device_OS === "Android")
        {
            var clientVersionsandroid = doc.data().App_Version;
            ClientVersionandroid.push(clientVersionsandroid);
        }
        if (doc.data().Device_OS === "iOS")
        {
            var clientVersionsios = doc.data().App_Version;
            ClientVersionios.push(clientVersionsios);
        }
       
        var deviceOSs = doc.data().Device_OS;
        DeviceOs.push(deviceOSs);
                            
            });
    console.log(ClientVersionandroid);
    //console.log(ClientVersion);
    //console.log(DeviceOs);

    var uSet = new Set(DeviceModelAnroid);
    var uniqueModelandroid = [...uSet];
    var uSet4 = new Set(DeviceModelIos);
    var uniqueModelios = [...uSet4];
    var uSet1 = new Set(ClientVersionandroid);
    var uniqueVersionandroid = [...uSet1];
    var uSet2 = new Set(ClientVersionios);
    var uniqueVersionios = [...uSet2];
    var uSet3 = new Set(DeviceOs);
    var uniqueDeviceOs = [...uSet3];
    let modelNameArrAndroid = [];
    let modelNumberArrAndroid = [];
    let modelNameArrIos = [];
    let modelNumberArrIos = [];
    let versionNameArrAndroid = [];
    let versionNumberArrAndroid = [];
    let versionNameArrIos = [];
    let versionNumberArrIos = [];
    let deviceOSNameArr = [];
    let deviceOSNumberArr = [];
    //console.log(uniqueVersionandroid);
    uniqueModelandroid.forEach(modelName => {
        // filter using modal name
        var filteredArray = DeviceModelAnroid.filter(modelItem => (modelItem === modelName));
        // pushing the total length modalname
        modelNameArrAndroid.push([modelName]);
        modelNumberArrAndroid.push(parseInt(filteredArray.length));
    })
    uniqueModelios.forEach(modelName => {
        // filter using modal name
        var filteredArray = DeviceModelIos.filter(modelItem => (modelItem === modelName));
       // console.log(modelName,filteredArray.length);
        modelNameArrIos.push([modelName]);
        modelNumberArrIos.push(parseInt(filteredArray.length));
    })
    uniqueVersionandroid.forEach(versionName => {
        var filteredArray = ClientVersionandroid.filter(versionItem => (versionItem === versionName));
        
        if(versionName === undefined)
        {
            versionName = "Unknown"
        }
        //console.log(versionName,filteredArray.length);
        versionNameArrAndroid.push([versionName]);
        versionNumberArrAndroid.push(parseInt(filteredArray.length));
    })
    uniqueVersionios.forEach(versionName => {
        var filteredArray1 = ClientVersionios.filter(versionItem => (versionItem === versionName));
        // console.log(versionName,filteredArray1.length);
        if(versionName === undefined)
        {
            versionName = "Unknown"
        }
        versionNameArrIos.push([versionName]);
        versionNumberArrIos.push(parseInt(filteredArray1.length));
    })
    uniqueDeviceOs.forEach(deviceOsName => {
        var filteredArray2 = DeviceOs.filter(deviceOsItem => (deviceOsItem === deviceOsName));
        // console.log(deviceOsName,filteredArray2.length);
        deviceOSNameArr.push([deviceOsName]);
        deviceOSNumberArr.push(parseInt(filteredArray2.length));
    })
   //console.log(modelNameArrIos);
    //console.log(modelNumberArrIos);
    //console.log(deviceOSArr);
    var obj_arr ={
        modelNameArrAndroid,
        modelNumberArrAndroid,
        modelNameArrIos,
        modelNumberArrIos,
        versionNameArrAndroid,
        versionNumberArrAndroid,
        versionNameArrIos,
        versionNumberArrIos,
        deviceOSNameArr,
        deviceOSNumberArr
    }
    //console.log(obj_arr);
    resolve(obj_arr)

        });

    });
}
    
}

export function mostorder() {
    return dispatch => {
        return new Promise((resolve, reject) => {
        var productid = [];
        var productidArr = [];
        var allproduct = [];
        db.collection("Product_Details").get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                var obj_arr = {
                    key:doc.id,
                    name:doc.data().Product_Name,
                    id:doc.data().Ref_Product_Brand_Id
                }
                allproduct.push(obj_arr)
            });
            orderarr();
        })
        
  function orderarr() {

    db.collection("Order_Details").get()
        .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                var Ref_Products_Id = doc.data().Ref_Products_Id
                productid.push(Ref_Products_Id)
            })
            var uSet = new Set(productid);
            var uniqueproductid = [...uSet];
            uniqueproductid.forEach(pid => {
                var filteredArray2 = productid.filter(deviceOsItem => (deviceOsItem === pid));
                // console.log(deviceOsName,filteredArray2.length);
                 //arr_order_status
                 if (pid) {
                    var selectedorder_status =
                    allproduct[
                        allproduct
                          .map(function (item1) {
                            return item1.key;
                          })
                          .indexOf(pid)
                      ];
                    
                    var product_name = selectedorder_status.name;
                    var brand_id = selectedorder_status.id
        
                  } 
                 
                var obj_arr = {
                    productid : pid,
                    key :pid,
                    ordercount : parseInt(filteredArray2.length),
                    product_name:product_name,
                    brand_id:brand_id
                }
                productidArr.push(obj_arr);
                
            })
            var sortedarr = _.orderBy(productidArr, ['ordercount'],['desc']); 
            //console.log(sortedarr);
            resolve(sortedarr)
        })
      
  }
        
    });
    }
}