import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { subcatTable } from "../actions/assetAction";
import { db } from "../firebase";
import {
  Icon,
  message,
  Avatar,
  Button,
  Input,
  Tag,
  Table,
  Modal,
  Tooltip
} from "antd";
//import 'antd/dist/antd.css';
import "../styles/css/App.css";
import Highlighter from "react-highlight-words";

class AllSubCat extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      // category: "",
      // subcategory: "",
      disabled: true,
      searchText: "",
      tdata: null,
      loading: true,
      mimage: ""
    };
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
    console.log(this.state.searchText);
  };

  confirm(pid) {
    console.log(pid);
    db.collection("Sub_Categories")
      .doc(pid)
      .delete()
      .then(function() {
        message.success("Document successfully deleted!");
      })
      .catch(function(error) {
        message.error("Error removing document: ", error);
      });
  }

  cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }

  componentWillMount() {
    //  componentWillMount
    this.props
      .subcatTable()
      .then(res => {
        //console.log(res);
        this.setState({
          tdata: this.props.subcategories,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          tdata: [],
          loading: false
        });
      });
  }

  showModal = (e, data) => {
    console.log(data);

    this.setState({
      visible: true,
      mimage: data
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  //===============================================

  render() {
    // console.log(this.props.products);
    const columns = [
      {
        title: "Image",
        dataIndex: "imgUrl",
        key: "imgUrl",
        width: "10%",
        render: (text, record) => (
          <span>
            <Avatar
              shape="square"
              size={64}
              src={record.imgUrl}
              onClick={e => this.showModal(e, record.imgUrl)}
            />
          </span>
        )
      },
      {
        title: "ID",
        dataIndex: "sid",
        key: "sid",
        ...this.getColumnSearchProps("sid")
      },

      {
        title: "Name",
        dataIndex: "sname",
        key: "sname",
        ...this.getColumnSearchProps("sname")
      },
      {
        title: "Category Name",
        dataIndex: "category",
        key: "category",
        ...this.getColumnSearchProps("category")
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description"
      },
      {
        title: "Created At",
        dataIndex: "Created_At",
        key: "Created_At"
      },
      {
        title: "Status",
        dataIndex: "tags",
        key: "tags",
        render: (text, record) => (
          <span>
            {record.tags === "true" ? (
              <Tag color="green">Active</Tag>
            ) : (
              <Tag color="volcano">In-Active</Tag>
            )}
          </span>
        )
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <span>
            <Link to={{ pathname: "/editsubcategory", subcatid: record.key }}>
              <Tooltip title="Edit">
                <Button type="primary" shape="circle" icon="edit" />
              </Tooltip>
            </Link>

            {/* <Divider type="vertical" /> */}

            {/* <Popconfirm title="Are you sure delete this Product?" onConfirm={() => this.confirm(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
                  <Icon type="delete" />
                </Popconfirm> */}
          </span>
        )
      }
    ];

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>All Sub-Category</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/addsubcategory">
                  <Button className="pull-right" type="primary" icon="shopping">
                    Add Sub-Category
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid allproduct">
          <Table
            columns={columns}
            dataSource={this.state.tdata}
            loading={this.state.loading}
            bordered
          />
        </div>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Close
            </Button>
          ]}
        >
          <div className="center-item">
            <img src={this.state.mimage} className="img-responsive" alt="" />
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    subcategories: state.subcategories
  };
}

export default connect(
  mapStateToProps,
  { subcatTable }
)(AllSubCat);
