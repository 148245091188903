import React, { Component } from "react";
import { connect } from "react-redux";
import { createuser } from "../actions/userAction";
import { SingleManager, AuthFailure } from "../actions/assetAction";
import "../styles/css/App.css";
import Logo from "../styles/img/logo-main.svg";
import Loginimg from "../styles/img/undraw_deliveries_131a.svg";
import { Button, message, Form, Input } from "antd";

class VerifyUser extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      confirmDirty: false,
      loading: false,
      iconLoading: false,
      userid: ""
    };
    // bind
  }

  componentWillMount() {
    let params = new URLSearchParams(window.location.search);
    let foo = params.get("uid");
    console.log(foo);
    this.setState({
      userid: foo
    });
    this.props.SingleManager(foo);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({
          iconLoading: true
        });
        this.props
          .createuser(values.email, values.password, this.state.userid)
          .then(succ => {
            console.log(succ);

            message.success("User Created Successfully..!");
            this.props.history.push("/confirm");
          })
          .catch(err => {
            message.error(err);
            this.setState({
              iconLoading: false
            });

            if (err === "auth/email-already-in-use") {
              this.props
                .AuthFailure(this.state.userid)
                .then(result => {
                  this.props.history.push("/failure");
                })
                .catch(err => {});
            }
          });
      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <div className="cover-full">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 hidden-xs">
              <img src={Loginimg} className="img-responsive" alt="loginimage" />
            </div>
            <div className="col-md-5 col-sm-6 col-xs-12">
              <div className="panel panel-default">
                <div className="panel-body">
                  <img
                    src={Logo}
                    className="img-responsive loginlogo"
                    alt="Logo"
                  />

                  <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <Form.Item>
                      {getFieldDecorator("email", {
                        initialValue: this.props.common.Manager_Email_Address,
                        rules: [
                          {
                            type: "email",
                            message: "The input is not valid E-mail!"
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!"
                          }
                        ]
                      })(<Input placeholder="Email" disabled />)}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your password!"
                          },
                          {
                            validator: this.validateToNextPassword
                          }
                        ]
                      })(<Input.Password placeholder="Password" />)}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("confirm", {
                        rules: [
                          {
                            required: true,
                            message: "Please confirm your password!"
                          },
                          {
                            validator: this.compareToFirstPassword
                          }
                        ]
                      })(
                        <Input.Password
                          placeholder="Confirm Password"
                          onBlur={this.handleConfirmBlur}
                        />
                      )}
                    </Form.Item>

                    <Form.Item>
                      <Button
                        type="primary"
                        loading={this.state.iconLoading}
                        htmlType="submit"
                      >
                        Register
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    common: state.common
  };
}
const WrappedRegistrationForm = Form.create({ name: "register" })(VerifyUser);
export default connect(
  mapStateToProps,
  { createuser, SingleManager, AuthFailure }
)(WrappedRegistrationForm);
