import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {emailLogin, logout} from '../actions/userAction';
import {StoreAll} from '../actions/assetAction';
import {db} from '../firebase'
import {Icon,Button, Input, Tag, Table, Avatar, Popconfirm, Divider,Tooltip } from 'antd';
import firebase from 'firebase/app';
import 'firebase/firestore';
import '../styles/css/App.css';
import Highlighter from 'react-highlight-words';
import { TimeAgo } from "@n1ru4l/react-time-ago";





  
class AllStore extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
            disabled: true,
            searchText: '',
            tdata:null,
            loading: true
        };

    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
          setSelectedKeys, selectedKeys, confirm, clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => { this.searchInput = node; }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: (text) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
      })

      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
      }
    
      handleReset = (clearFilters) => {
        clearFilters();
        this.setState({ searchText: '' });
        console.log(this.state.searchText);
      }


      
      cancel(e) {
        console.log(e);
        // message.error('Click on No');
      }
 
      
    componentWillMount()
    {

        this.props.StoreAll().then(res => {
          console.log(res);
          this.setState({
              tdata :this.props.order,
              loading:false
            });	
      })
      .catch(error => {
          console.log(error);
          this.setState({
            loading:false
          });	
            }
        )
             
    }  

    handleResett = () => {
      //  componentWillMount
      
      const initialDate = new Date();
      
      this.setState({
        loading: true,
        
      });
  
      this.props.StoreAll().then(res => {
        console.log(res);
        this.setState({
          tdata: this.props.order,
          loading: false,
          date:initialDate
        });
      })
        .catch(error => {
          console.log(error);
          this.setState({
           
            loading: false,
            
          });
        }
        )
  
  
    }

    confirm = async (key) => {

      this.setState({
        loading: true
      });
  
  
  var washingtonRef = db.collection("Stores_Master").doc(key);
  
  // Set the "capital" field of the city 'DC'
      try {
        await washingtonRef.update({
          IsActive: false,
          Updated_At: firebase.firestore.FieldValue.serverTimestamp()
        });
        console.log("Document successfully updated!");
        this.props.StoreAll().then(res => {
          console.log(res);
          this.setState({
            tdata: this.props.order,
            loading: false,
          });
        })
          .catch(error => {
            console.log(error);
          });
      }
      catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      }
  
    }

    confirm1 = async (key) => {

      this.setState({
        loading: true
      });
  
  
  var washingtonRef = db.collection("Stores_Master").doc(key);
  
  // Set the "capital" field of the city 'DC'
      try {
        await washingtonRef.update({
          Store_Availability_Status: false,
          Updated_At: firebase.firestore.FieldValue.serverTimestamp()
        });
        console.log("Document successfully updated!");
        this.props.StoreAll().then(res => {
          console.log(res);
          this.setState({
            tdata: this.props.order,
            loading: false,
          });
        })
          .catch(error => {
            console.log(error);
          });
      }
      catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      }
  
    }

    activate = async (key) => {

      this.setState({
        loading: true
      });
  
  
  var washingtonRef = db.collection("Stores_Master").doc(key);
  
  // Set the "capital" field of the city 'DC'
      try {
        await washingtonRef.update({
          IsActive: true,
          Updated_At: firebase.firestore.FieldValue.serverTimestamp()
        });
        console.log("Document successfully updated!");
        this.props.StoreAll().then(res => {
          console.log(res);
          this.setState({
            tdata: this.props.order,
            loading: false,
          });
        })
          .catch(error => {
            console.log(error);
          });
      }
      catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      }
  
    }

    activate1 = async (key) => {

      this.setState({
        loading: true
      });
  
  
  var washingtonRef = db.collection("Stores_Master").doc(key);
  
  // Set the "capital" field of the city 'DC'
      try {
        await washingtonRef.update({
          Store_Availability_Status: true,
          Updated_At: firebase.firestore.FieldValue.serverTimestamp()
        });
        console.log("Document successfully updated!");
        this.props.StoreAll().then(res => {
          console.log(res);
          this.setState({
            tdata: this.props.order,
            loading: false,
          });
        })
          .catch(error => {
            console.log(error);
          });
      }
      catch (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      }
  
    }
//===============================================

    render() {
       // console.log(this.props.products);
        const columns = [
            {
                title: 'Image',
                dataIndex: 'logo',
                key: 'logo',
                render: (text, record) => (
                    <span>
                       <Avatar shape="square" size={64} src={record.logo} />
                    </span>
                  ),
                
          },
          {
            title: 'Store Name',
            dataIndex: 'store_name',
            key: 'store_name',
            ...this.getColumnSearchProps('store_name'),
          },{
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            ...this.getColumnSearchProps('address'),
          },
         
          {
            title: 'Message One',
            dataIndex: 'messageone',
            key: 'messageone',
           
          },
          {
            title: 'Additional Message',
            dataIndex: 'messagetwo',
            key: 'messagetwo',
           
          },
          {
            title: 'Outstanding Amount',
            dataIndex: 'Outstanding_Amount',
            key: 'Outstanding_Amount',
           
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
              <span>
                
                {record.status === "true" ?
          <Tag color="green">Active</Tag>
          :<Tag color="volcano">In Active</Tag>
          }
                
               
              </span>
            ),
           
          },
          {
            title: 'Store Available Status',
            dataIndex: 'store_available_status',
            key: 'store_available_status',
            render: (text, record) => (
              <span>
                
                {record.store_available_status === "true" ?
          <Tag color="green">Online</Tag>
          :<Tag color="volcano">Offline</Tag>
          }
                
               
              </span>
            ),
           
          },
          
          
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width:180,
            render: (text, record) => (
                <span>
                    <Link to={{ pathname: '/editstore',  requestid: record.key }}>
                        <Tooltip title="Edit Store">
                        <Button type="primary" shape="circle" icon="edit"/> 
                        </Tooltip>
                    </Link>
                    
                       
                  
                  
                   <Divider type="vertical" />

                   {record.status === "true" ?
                   (<Tooltip title="Deactivate"><Popconfirm title="Are you sure want to Deactivate Store?" onConfirm={() => this.confirm(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
                   <Button type="danger" shape="circle" icon="delete"/> 
                   </Popconfirm></Tooltip>) : (<Tooltip title="Activate"><Popconfirm title="Are you sure want to Activate Store?" onConfirm={() => this.activate(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
                   <Button type="primary" shape="circle" icon="check-circle"/> 
                   </Popconfirm></Tooltip>)}

                   <Divider type="vertical" />

                   {record.store_available_status === "true" ?
                   (<Tooltip title="Offline Store"><Popconfirm title="Are you sure want to Offline Store?" onConfirm={() => this.confirm1(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
                   <Button type="danger" shape="circle" icon="close-circle"/> 
                   </Popconfirm></Tooltip>) : (<Tooltip title="Online Store"><Popconfirm title="Are you sure want to Online Store?" onConfirm={() => this.activate1(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
                   <Button type="primary" shape="circle" icon="check-circle"/> 
                   </Popconfirm></Tooltip>)}
                    
                </span>
              ),
            
          }
        ];



          return (
            <div>
            <div className="container-fluid addp">
                <div className="container-fluid">
                <div className="row">
                <div className="col-sm-4">
                    <h4>Stores</h4>
                </div>
                {/* <div className="col-sm-8 text-right">
                <Link to="/store"><Button className="pull-right" type="primary" icon="shop">Add Store</Button></Link>
                </div> */}
                
                </div>
                    
                </div>
            </div>
            <div className="container-fluid allproduct">
            <div className="text-right filterdiv">
            <span style={{ marginRight: 10 }}>Last Refreshed Time : <TimeAgo date={new Date()}>{({ value }) => <b><Icon type="clock-circle" theme="outlined" /> {value}</b>}</TimeAgo></span>
            <Button type="primary" style={{ marginLeft: 15 }} onClick={this.handleResett}>
              Refresh
            </Button>

          </div>
           
                <Table columns={columns} dataSource={this.state.tdata} loading={this.state.loading} scroll={{ x: 1300 }}  bordered />
            </div>
            
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
    order: state.order
  };
}


export default connect(mapStateToProps, {emailLogin, logout, StoreAll})(AllStore);
