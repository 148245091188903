import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';


var config = {
  apiKey: "AIzaSyB4FEmoaqdy-tp01PQvkht1eRgUFFezo1E",
  authDomain: "shopezy-a4bc4.firebaseapp.com",
  databaseURL: "https://shopezy-a4bc4.firebaseio.com",
  projectId: "shopezy-a4bc4",
  storageBucket: "shopezy-a4bc4.appspot.com",
  messagingSenderId: "773321316486",
};

  firebase.initializeApp(config);

 
  export const db = firebase.firestore();
  export const storage = firebase.storage();
  export const auth = firebase.auth();
  export const firedb = firebase.database();


  
  // Secondary App Configuration

  // var config1 = {
    
  //   apiKey: "AIzaSyB4FEmoaqdy-tp01PQvkht1eRgUFFezo1E",
  //   authDomain: "shopezy-a4bc4.firebaseapp.com",
  //   databaseURL: "https://shopezy-a4bc4.firebaseio.com",
  //   projectId: "shopezy-a4bc4",
  //   storageBucket: "shopezy-a4bc4.appspot.com",
  //   messagingSenderId: "773321316486",
  
  // };
  
  // var secondaryApp = firebase.initializeApp(config1, "Secondary");

  // export const firedb = secondaryApp.database();