module.exports.PaymentCard = '1';
module.exports.paymentCash = "2";
module.exports.DriverRideStatusOnline = '1';
module.exports.DriverRideStatusOffline = "2";
module.exports.companydeleted = "1";
module.exports.companyactive = "2";
module.exports.driveractive = "1";
module.exports.driverreadytoaccepttrips = "2";
module.exports.driverdocumentnotsubmitted = "3";
module.exports.yettobereviewed = "4";
module.exports.driverblocked = "5";
module.exports.driverinactive = "6";
module.exports.couponcodeactive = "5";
module.exports.couponcodeinactive = "6";
module.exports.couponcodeuniversal = "3";
module.exports.couponcodeexclusive = "4";
module.exports.coupondeleted = "3";
module.exports.discountpercent = "1";
module.exports.discountvalue = "2";
module.exports.couponfulfilled = "8";
module.exports.couponusagelimitexceeded = "9";
module.exports.couponexpired = "10";
module.exports.stripekey = "sk_test_Ylv1MxaSBRzqrPy0y1G4BHP400VgMR4zLu"; //"sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTmn"; // "sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTmn"; //"sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTmn";//  sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTm sk_test_Ylv1MxaSBRzqrPy0y1G4BHP400VgMR4zLu"; //'sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTmn  sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTm'
module.exports.stripekeydev = "sk_test_Ylv1MxaSBRzqrPy0y1G4BHP400VgMR4zLu"; //"sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTmn"; //"sk_test_Ylv1MxaSBRzqrPy0y1G4BHP400VgMR4zLu"; //"sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTmn"; //  sk_live_kh6bf9y6e3J8dNO9VwJZyQIC000rIYSTm sk_test_Ylv1MxaSBRzqrPy0y1G4BHP400VgMR4zLu";
module.exports.ValidationFailed = "1";
module.exports.ValidationDeducted = "2";
module.exports.TransactionFailed = "4";
module.exports.TransactionSucessfull = "5";
module.exports.RefundInitiated = "3";
module.exports.smsnotification = "1";
module.exports.pushnotification = "3";
module.exports.emailnotification = "2";
module.exports.promostatusactive = "1";
module.exports.promostatusinactive = "2";
module.exports.promostatusyettostart = "3";
module.exports.promostatusexpired = "4";
module.exports.ProductCancelled = "3"
module.exports.StoreRequestAccept = "2"
module.exports.StoreRequestReject = "3"
module.exports.StoreRequestPending = "1"
module.exports.ProductAdded = "1"
module.exports.ProductPlaced = "2"
module.exports.ProductCancelled = "3"
module.exports.ProductReplaced = "4"
module.exports.ProductChanged = "5"
module.exports.ProductEdited = "6"
module.exports.timezone = "Asia/Kolkata"
// module.exports.timezone = "Europe/London"
module.exports.currencysymbol = "£"
module.exports.StoreRequestAcceptEmailNotification = "23"
module.exports.StoreRequestRejectEmailNotification = "24"
module.exports.StoreRequestPendingEmailNotification = "25"
module.exports.customerregisteredsms = "29"
// module.exports.customerregisteredsms = "29"

//SMS Template Id new
module.exports.GotoCustomer = "1";
module.exports.customerRegistration = "9";
module.exports.customerMakesOrderCustomer = "10";
module.exports.customerMakesOrderManager = "4";
module.exports.NewDriverApproved = "16";
module.exports.NewDriverRejected = "22";
module.exports.NewDriverSignup = "15";
module.exports.EmailToManagerWithOrderReceipt = "26"
//order delivered 12
module.exports.Order_Delivered_pushnotification_customer = "6"
module.exports.Order_Delivered_email_customer = "13"
module.exports.Order_Delivered_sms_customer = "3"
module.exports.Order_Delivered_pushnotification_manager = "7"
module.exports.GotoCustomer_pushnotification = "5"
module.exports.Chat_from_customer_to_manager = "20"
module.exports.Chat_from_manager_to_customer = "19"
module.exports.Chat_from_admin_to_customer = "21"
module.exports.Chat_from_customer_to_Admin = "27"
module.exports.RefundNotificationtoCustomer = "28"
module.exports.Order_Delivered_email_manager = "14"
module.exports.Driver_Status_Id_pushnotification = "8"
module.exports.Manager_Reject_Orderd_email = "11"
module.exports.Manager_Modified_Orderd_email = "12"
module.exports.Manager_Reject_Orderd_pushnotification = "2"
//end SMS Template Id new
module.exports.RideArrives = "1";
module.exports.ReAssignOrderToDriver = "22"
module.exports.DriverAcceptsRide = "2";
module.exports.RideCompleteCash = "3";
module.exports.RideCompleteCard = "4";
module.exports.CustomerBooksARide = "5";
module.exports.ReadyToAcceptTrips = "2"
module.exports.AdminRejected = "3"
module.exports.RideCompleteCardPaymentfailed = "6";
module.exports.smstoken = "1tdM-G8Ac-i744-RNNc"; //"1x6K-AYsV-CXSr-3COP";
module.exports.RideNow = "1";
module.exports.RideLater = "2";
module.exports.customerCancelledOrder = '10';
module.exports.DriverRejectedRequest = '5';
module.exports.CustomerCancelledTrip = '13';
module.exports.DriverCancelledTrip = '14';
module.exports.NoResponseFromDriver = '16';
module.exports.AdminCancelledTrip = '17';
module.exports.PayImmediately = '7';
module.exports.PayLater = '6';
//new
module.exports.discountpercent = "1";
module.exports.discountvalue = "2";
//order status
module.exports.AddedToCart = '1';
module.exports.OrderPlaced = '2';
module.exports.ManagerAccepted = '3';
module.exports.ManagerRejected = '4';
module.exports.WaitingForPickup = '5';
module.exports.RequestSent = '6';
module.exports.DriverAccepted = '7';
module.exports.DriverRejected = '8';
module.exports.DriverNotAvailable = '9';
module.exports.orderStarted = '10'; // Go To customer
module.exports.OutOfDelivery = '11';
module.exports.Completed = '12'; //Delivered
module.exports.customerCancelledOrder = '13'; //customerCancelledOrder
module.exports.driverCancelledOrder = '14';
module.exports.WaitingToAcceptF = '15';
module.exports.WaitingToAcceptB = '16';
module.exports.OrderPickedup = '17';
module.exports.SourceLocationNotAvailable = '18';
//DriverAvailabilityStatus
module.exports.DriverAvailabilityStatus_online = '1';
module.exports.DriverAvailabilityStatus_offline = '2';
module.exports.DelayedDeliveries = '1'; //customerCancelledOrder
module.exports.DelayedPickups = '2';
module.exports.YetToBeAccepted = '3';
module.exports.DeliveredOrder = '4';

//Payment Type
module.exports.Cardold= 7; 
module.exports.Card= 1; 
module.exports.ApplePay= 3;
module.exports.Cash= 2;
