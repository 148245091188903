import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {db} from '../firebase';

import {emailLogin, logout} from '../actions/userAction';
import {updateAsset,catList, subcatList, currencyList,filtersubCat,couponList1,SingleProduct} from '../actions/assetAction';
import {Form, Input,InputNumber, Spin, Icon, Row, Col, Radio, Upload,Avatar, Button, Select, Rate ,message,Popconfirm} from 'antd';
import '../styles/css/App.css';


const { Option } = Select;
const { TextArea } = Input;



class EditProduct extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
          fileList: [],
          fileList1: "",
          iconLoading: false ,
          loading1:true,
          number:'',
          symbol: "",
          Discounted: "true",
          Marked: "false",
          Taxapp: "false",
          disabled: true,
          actualPrice: "",
         markedPrice: "",
          discountPrice: "",
          offertype: 1,
          rate: "Default Rating is 5",
          filetag:1
        };
       
    }

    handlerateChange = (value) => {
    
      this.setState({ 
        rate: "Rating is " + value 
      });
    }

    handleCurrencyChange = (value) => {
        db.collection("MD_Currency").doc(value).onSnapshot(doc =>{    
          console.log(doc.data().Currency_Symbol);
          this.setState({
            symbol: doc.data().Currency_Symbol,
          });
        })      
    }

    markedPrice = (e) => {
     
      this.setState({
        markedPrice: e.target.value
      });
     
    }


    actualPrice = (e) => {
      this.setState({
        actualPrice: e.target.value
      });
     
      setTimeout(
        function() {
          this.getdiscountprice();
        }
        .bind(this),
        500
      );
    }
    handleoffertype = (value) => {
      this.setState({
        offertype : value
      });
      console.log(this.state.offertype);
      setTimeout(
        function() {
          this.getdiscountprice();
        }
        .bind(this),
        500
      );
    }
    handleDiscountedprice = (value) =>{
      this.setState({
        discountPrice: value
      });
      
      setTimeout(
        function() {
          this.getdiscountprice();
        }
        .bind(this),
        500
      );
      
    }

    getdiscountprice(){
      console.log(this.state.offertype);
      var numVal1 = Number(this.state.actualPrice);
      var numVal2;
      var totalValue;
      if(this.state.offertype === 1 ){
        numVal2 = Number(this.state.discountPrice) / 100;
        totalValue = numVal1 - (numVal1 * numVal2)
        console.log(totalValue);
        this.props.form.setFieldsValue({
          Product_Discounted_Price: totalValue.toFixed(2),
        });
      }else if(this.state.offertype === 2 ){
        numVal2 = Number(this.state.discountPrice);
        totalValue = numVal1 -  numVal2
        console.log(totalValue);
        this.props.form.setFieldsValue({
          Product_Discounted_Price: totalValue.toFixed(2),
        });
      }
          
    }

    handleDiscounted = (e) => {
        this.setState({
          Discounted: e.target.value,
        });
    }
    handleMarked = (e) => {
      this.setState({
        Marked: e.target.value,
      });
  }
    handleTax = (e) => {
    this.setState({
      Taxapp: e.target.value,
    });
    }
    componentWillMount()
    {
    //  componentWillMount
    this.props.catList();
    this.props.subcatList();
    this.props.currencyList();
    this.props.couponList1();
    var proid;
    if (this.props.location.productid === undefined)
   {
        proid = localStorage.getItem('prodid');
        this.props.SingleProduct(proid).then((result) => {
          db.collection("MD_Currency").doc(result.Ref_Currency_Id).onSnapshot(doc =>{    
            this.setState({
              Discounted: result.Product_Is_Discounted,
              Taxapp: result.Product_Tax_Applicable,
              symbol: doc.data().Currency_Symbol,
              fileList: result.Product_Images, 
              Marked:result.Is_Marked_Up_Price_Enabled,
              actualPrice:result.Product_Actual_Price,
              markedPrice:result.Marked_Up_Price,
              loading1:false
            });	
            this.props.form.setFieldsValue({dragger: result.Product_Images});
          })
          
        }).catch((err) => {
          
        });
        
    }
    else
    {
        
        localStorage.setItem('prodid', this.props.location.productid);
        proid = localStorage.getItem('prodid');
        this.props.SingleProduct(proid).then((result) => {
          db.collection("MD_Currency").doc(result.Ref_Currency_Id).onSnapshot(doc =>{    
            this.setState({
              Discounted: result.Product_Is_Discounted,
              Taxapp: result.Product_Tax_Applicable,
              symbol: doc.data().Currency_Symbol,
              fileList: result.Product_Images, 
              Marked:result.Is_Marked_Up_Price_Enabled,
              actualPrice:result.Product_Actual_Price,
              markedPrice:result.Marked_Up_Price,
              loading1:false
            });	
            this.props.form.setFieldsValue({dragger: result.Product_Images});
          })
          
        }).catch((err) => {
          
        });
    }
    }  

    handleReset = () => {
      this.props.form.resetFields();
      this.setState({
        iconLoading: false,
        fileList: [],
        fileList1: [],
        symbol: "",
        Discounted: "false",
          Taxapp: "false",
          disabled: true,
          actualPrice: "",
          discountPrice: "",
          offertype: 1
      });	
      
    }
    // handleCategory = (value) => {
    //   this.setState({
    //     category: value,
    //     disabled: false
    //   });
    //   this.props.form.setFieldsValue({
    //     Ref_Sub_Category_Id: "",
    //   });
    //   this.props.filtersubCat(value);
    // }
    handlesubCategory = (value) => {
      db.collection("Sub_Categories").doc(value).onSnapshot(doc =>{    
        // console.log(doc.data().Ref_Category_Id);
        this.props.form.setFieldsValue({
          Ref_Category_Id: doc.data().Ref_Category_Id,
        });
      })  
    }
    
    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {

      console.log(values);
         
          this.setState({
            iconLoading: true,
            loading1:true
          });	
          var proid = localStorage.getItem('prodid');
       this.props.updateAsset(proid,values, this.state.fileList, this.state.fileList1).then(res => {
                console.log(res);
                this.setState({
                  iconLoading: false,
                  loading1:false,
                  fileList: "",
                  fileList1: "",
                  symbol: "",
                  Discounted: "true",
                  Taxapp: "false",
                  disabled: true,
                  actualPrice: "",
                  discountPrice: "",
                });	
               
                this.props.form.resetFields();
                message.success("Product updated successfully!");
                setTimeout(() => {
                  this.props.history.push("/premierproducts");
               }, 3000);

            }
        ).catch(error => {
          console.log(error);
            }
        )
    
        }
      });
    }

    checkPrice = (rule, value, callback) => { 
      // console.log('changed', value);
      // const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
       if ((!Number.isNaN(value)) || value === '')
        {
         callback();
         return;
       }
       callback('Input only a numermic value...!');
     }
 

    renderCategory()
    {
         return _.map(this.props.categories,(categories,key) => {
                return (
                  <Option value={categories.id} key={key}> 
                  <Avatar src={categories.imgUrl} />
                  {categories.cname}
                  {/* ({categories.cid})  */}
                  </Option>
                );
              })
    }
    renderSubCategory()
    {
         return _.map(this.props.subcategories,(subcategories,key) => {
                return (
                  <Option value={subcategories.id} key={key}> 
                  <Avatar src={subcategories.imgUrl} />
                  {subcategories.sname}
                  {/* ({subcategories.sid})  */}
                  </Option>
                );
              })
    }
    renderCurrency()
    {
         return _.map(this.props.currency,(currency,key) => {
                return (
                  <Option value={currency.id} key={key}> 
                  {currency.symbol} - {currency.name} - ({currency.code}) </Option>
                );
              })
    }
    renderCoupon()
    {
         return _.map(this.props.coupon,(coupon,key) => {
                return (
                  <Option value={coupon.Coupon_Value} key={key}>{coupon.Coupon_Status}</Option>
                );
              })
    }
    normFile = e => {
      console.log("Upload event:", e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
    deleteImage = () => {
      this.setState({
          filetag:0
        });	
  }
  updatefilelist = (d) => {
    console.log(d);
    this
        .props
        .form
        .setFieldsValue({dragger: d});
}
// ======================================================

    render() {
      
      const { fileList } = this.state;
      const { fileList1 } = this.state;
      
      const props = {
      multiple: true, 
      accept: ".png,.jpg",
      listType: "picture",
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          this.updatefilelist(newFileList);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
       fileList,
    };
    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".png,.jpg",
      showUploadList	: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList1.indexOf(file);
          const newFileList = state.fileList1.slice();
          newFileList.splice(index, 1);
          return {
            fileList1: newFileList,
          };
        });
        console.log(this.state.fileList1);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState(state => ({
          fileList1: [...state.fileList1, file],
        }));
        return false;
      },
      fileList1,
    };
    
      const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };


//==============================================================================================================//
       
          return (
<div>
<Spin size="large" spinning={this.state.loading1}>
            <div className="container-fluid addp">
              <div className="container-fluid">
               <div className="row">
                <div className="col-sm-4">
                    <h4>Edit Product</h4>
                </div>
                <div className="col-sm-8 text-right">
                <Link to="/premierproducts"><Button className="pull-right" type="dark" icon="arrow-left">Back To Products</Button></Link>
                </div>
                </div>
              </div>
            </div>
          <div className="container-fluid text-left addproduct">
          
           <Row gutter={15}>
             <Form onSubmit={this.handleSubmit}>
             <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
             <h4 className="formtitle" style={{ marginTop: 0}}>Product Info</h4>
             </Col>
             <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="ID"
                >
                  {getFieldDecorator('Product_Id', {
                   initialValue: this.props.products.Product_Id,
                    rules: [
              {
                required: true,
                pattern: new RegExp("^[A-Za-z0-9]+$"),
                message: "Product ID should be alphanumeric only"
              }
            ],
                  })(<Input />)}
                </Form.Item>
                </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Name"
                >
                  {getFieldDecorator('Product_Name', {
                    initialValue: this.props.products.Product_Name,
                    rules: [
                      { transform: (value) => value.trim() },{ required: true, message: 'Enter Product name', whitespace: true }],
                  })(
                    <Input />
                  )}
                </Form.Item>
                </Col>
                {/* <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Display Order"
                >
                  {getFieldDecorator('Product_Display_Order', {
                    rules: [{ required: true, validator: this.checkPrice }],
                  })(
                    <InputNumber style={{ width: 300 }} />
                  )}
                </Form.Item></Col> */}
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Description"
                >
                  {getFieldDecorator('Product_Description', {
                    initialValue: this.props.products.Product_Description,
                    rules: [{ required: true, message: 'Enter Product Description', whitespace: false },{ max: 260, message: 'Description should be less than 260 characters!' }],
                  })(
                    <TextArea rows ={5} />
                  )}
                </Form.Item></Col>

                <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
                  <h4 className="formtitle">Category Info</h4>
                  </Col>

                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Brand ID"
                >
                  {getFieldDecorator('Ref_Product_Brand_Id', {
                    initialValue: this.props.products.Ref_Product_Brand_Id,
                    rules: [{ required: true, message: 'Enter Product Brand Id', whitespace: false }],
                  })(
                    <Input />
                  )}
                </Form.Item></Col>
               
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Sub-Category"
                >
                  {getFieldDecorator('Ref_Sub_Category_Id', {
                    initialValue: this.props.products.Ref_Sub_Category_Id,
                    rules: [{ required: true, message: 'Enter Sub-Category', whitespace: false }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select Sub-Category"
                      optionFilterProp="children"
                      onChange={this.handlesubCategory}
                      filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                    >
                    {this.renderSubCategory()}
                    </Select>
                   
                  )}
                </Form.Item></Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Category"
                  extra="Category will be selected based on the selection of Sub-Category"
                >
                  {getFieldDecorator('Ref_Category_Id', {
                    initialValue: this.props.products.Ref_Category_Id,
                    rules: [{ required: true, message: 'Enter Category', whitespace: false }],
                  })(
                   
                    <Select
                      // showSearch
                      placeholder="Select Category"
                      // optionFilterProp="children"
                      // onChange={this.handleCategory}
                      disabled = {this.state.disabled}
                      // value = {this.state.category}
                      // filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                    >
                    {this.renderCategory()}
                    </Select>
                   
                  )}
                </Form.Item>
                </Col>
                <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
                  <h4 className="formtitle">Price Info</h4>
                  </Col>
                {/* <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Measuring Quantity"
                >
                  {getFieldDecorator('Product_Measuring_Quantity', {
                    rules: [{ required: true, message: 'Enter Product Measuring Quantity', whitespace: false }],
                  })(
                    <Select>
                    <Option value="Gram">Gram</Option>
                    <Option value="KG">KG</Option>
                    <Option value="litre">litre</Option>
                  </Select>
                    
                  )}
                </Form.Item></Col> */}
                {/* <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="No of Units"
                >
                  {getFieldDecorator('Product_No_of_Units', {
                    rules: [{ required: true, message: 'Enter Product No of Units', whitespace: false }],
                  })(
                    <Input />
                  )}
                </Form.Item></Col> */}
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Currency"
                >
                  {getFieldDecorator('Ref_Currency_Id', {
                    initialValue: this.props.products.Ref_Currency_Id,
                    rules: [{ required: true, message: 'Enter Currency', whitespace: false }],
                  })(
                    <Select
                      placeholder="Select Currency type"
                      onChange={this.handleCurrencyChange}
                    >
                    {this.renderCurrency()}
                    </Select>
                  )}
                </Form.Item></Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Actual Price"
                 
                >
                  {getFieldDecorator('Product_Actual_Price', {
                    initialValue: this.props.products.Product_Actual_Price,
                   
                    
                   rules: [
              {
                required: true,
                pattern: new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"),
                message: "Wrong format!"
              }
            ]
                  })(
                    // <InputNumber  prefix={this.state.symbol} style={{ width: 300 }} />
                    // <InputNumber
                    //   defaultValue={1000}
                    //   formatter={value => `${this.state.symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    //   parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    // />
                   <Input placeholder="00.00" prefix={this.state.symbol} onChange={this.actualPrice} />
                    
                  )}
                </Form.Item></Col>
                <Col xs={{ span: 24}} sm={{span: 10}} lg={{ span: 7}}>
                <Form.Item
                  {...formItemLayout}
                  label="Marked Up Price"
                >
                  {getFieldDecorator('Is_Marked_Up_Price_Enabled', {
                    initialValue: this.props.products.Is_Marked_Up_Price_Enabled,
                    rules: [{ required: true, message: 'Select YES if Product Is Discounted', whitespace: false }],
                  })(
                    <Radio.Group                       
                    onChange={this.handleMarked}
                    >
                      <Radio value="true">YES</Radio>
                      <Radio value="false">NO</Radio>
                    </Radio.Group>
                  )}
                </Form.Item></Col>
                {this.state.Marked === "true" ? 
                <div>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item  {...formItemLayout}
                  label="Marked Up Quantity">
                  {getFieldDecorator('Marked_Up_Quantity', {
                    initialValue: this.props.products.Marked_Up_Quantity,
                    rules: [
              {
                required: true,
                pattern: new RegExp("^[0-9]+$"),
                message: "Marked Up Quantity should be numeric only"
              }
            ],
                  })(<Input />
                  )}
                </Form.Item></Col>

                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item  {...formItemLayout}
                  label="Marked Up Price">
                  {getFieldDecorator('Marked_Up_Price', {
                    initialValue: this.props.products.Marked_Up_Price,
                    rules: [
              {
                required: true,
                pattern: new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"),
                message: "Wrong format!"
              }
            ]
                  })(
                    <Input placeholder="00.00" prefix={this.state.symbol} onChange={this.markedPrice} />
                  )}
                </Form.Item></Col></div> : null}
                
                <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
                  <h4 className="formtitle">Discount Info</h4>
                  </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Discounted"
                >
                  {getFieldDecorator('Product_Is_Discounted', {
                    initialValue: this.props.products.Product_Is_Discounted,
                    rules: [{ required: true, message: 'Select YES if Product Is Discounted', whitespace: false }],
                  })(
                    <Radio.Group                       
                    onChange={this.handleDiscounted}
                    >
                      <Radio value="true">YES</Radio>
                      <Radio value="false">NO</Radio>
                    </Radio.Group>
                  )}
                </Form.Item></Col>
                {this.state.Discounted === "true" ? 
                <div>

                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item  {...formItemLayout}
                  label="Offer Type">
                  {getFieldDecorator('Ref_Product_Offer_Type', {
                    initialValue: this.props.products.Ref_Product_Offer_Type,
                    rules: [{ required: true, message: 'Enter  Product Offer Type!' }],
                  })(
                    <Select onChange={this.handleoffertype}
                      placeholder="Select Offer Type"
                    >
                    {this.renderCoupon()}
                    </Select>
                  )}
                </Form.Item></Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Product Offer Value"
                >
                  {getFieldDecorator('Ref_Product_Offer_Value', {
                    initialValue: this.props.products.Ref_Product_Offer_Value,
                    rules: [{ required: true, validator: this.checkPrice }],
                  })(
                   <InputNumber onChange={this.handleDiscountedprice} style={{ width: 300 }} />
                  )}
                </Form.Item></Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Offer Shared By"
                >
                  {getFieldDecorator('Product_Offer_Shared_By', {
                    initialValue: this.props.products.Product_Offer_Shared_By,
                    rules: [{ required: true, message: 'Enter Product Offer Shared By!', whitespace: false }],
                  })(
                    <Input />
                  )}
                </Form.Item></Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Discounted Price"
                  extra="Enter Actual price and Offer value inorder to calculate Discounted price"
                >
                  {getFieldDecorator('Product_Discounted_Price', {
                    initialValue: this.props.products.Product_Discounted_Price,
                    rules: [{ required: true, message: 'Enter Product Discounted Price', whitespace: false }],
                  })(
                    <Input disabled prefix={this.state.symbol} />
                  )}
                </Form.Item></Col>
                </div>
                
                
                : 
                null
                }
                
                {/* <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
                  <h4 className="formtitle">Product Info</h4>
                  </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Premier promo"
                >
                  {getFieldDecorator('Product_Has_Mandatory_Selections', {
                    initialValue: "false",
                    rules: [{ required: true, message: 'Select YES if Product Has Mandatory Selections', whitespace: false }],
                  })(
                    <Radio.Group>
                      <Radio value="true">YES</Radio>
                      <Radio value="false">NO</Radio>
                    </Radio.Group>
                  )}
                </Form.Item></Col> */}
                <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
                  <h4 className="formtitle">Tax Info</h4>
                  </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Tax Applicable "
                >
                  {getFieldDecorator('Product_Tax_Applicable', {
                    initialValue: this.props.products.Product_Tax_Applicable,
                    rules: [{ required: true, message: 'Select YES if Product Is Tax Applicable!', whitespace: false }],
                  })(
                    <Radio.Group onChange={this.handleTax}>
                      <Radio value="true">YES</Radio>
                      <Radio value="false">NO</Radio>
                    </Radio.Group>
                  )}
                </Form.Item></Col>
              {this.state.Taxapp === "true" ? 
              <div>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label=" Tax Slab"
                >
                  {getFieldDecorator('Ref_Product_Tax_Slab', {
                    initialValue: this.props.products.Ref_Product_Tax_Slab,
                    rules: [{ required: true, message: 'Enter Reference Product Tax Slab!', whitespace: false }],
                  })(
                    <Input />
                  )}
                </Form.Item></Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item
                  {...formItemLayout}
                  label="Commision Slab"
                >
                  {getFieldDecorator('Ref_Product_Commision_Slab', {
                    initialValue: this.props.products.Ref_Product_Commision_Slab,
                    rules: [{ required: true, message: 'Enter  Product Commision Slab!', whitespace: false }],
                  })(
                    <Input />
                  )}
                </Form.Item></Col>
                </div>
                :
                null
                }
                <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
                  <h4 className="formtitle">Disclaimer Info</h4>
                  </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label=" Disclaimer"
                >
                  {getFieldDecorator('Product_Disclaimer', {
                    initialValue: this.props.products.Product_Disclaimer,
                    rules: [{ required: false, message: 'Enter Product Disclaimer!', whitespace: false }],
                  })(
                    <Input />
                  )}
                </Form.Item></Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label=" Rating"
                  extra={this.state.rate}
                >
                  {getFieldDecorator('Product_Rating', {
                    initialValue: this.props.products.Product_Rating,
                  })(
                    <Rate allowHalf allowClear={false} onChange={this.handlerateChange}/>
                  )}
                </Form.Item></Col>



                <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
                  <h4 className="formtitle">Product Images</h4>
                  </Col>
  {/* =============================== Product_Images ======================================== */}
            <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
            { this.state.filetag === 0 ? (
               <Form.Item
                {...formItemLayout}
                label="Product Display Image"
               
              >
                {getFieldDecorator('Product_Thumb_Image_Url', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normFile,
                    rules: [{
                        required: true, message: 'Please upload product display image!',
                    }],
                  })(
                  <Upload name="Thumb_Image"  {...propsthumb} >
                    {fileList1.length === 1 ? 
                      null : 
                      <div>
                        <Icon type="plus" />
                        <div className="ant-upload-text">Choose</div>
                      </div>
                      }
                    
                  </Upload>
                )}
              </Form.Item>):(
                <Form.Item
                  {...formItemLayout}
                  label="Product Display Image"
                >
                <Row gutter={15}>
                <Col span={16}>
                 <img className="img-responsive" src={this.props.products.Product_Thumb_Image_Url} alt="" />
                 </Col>
                  <Col span={8}>
                  <Popconfirm title="Are you sure delete this Image, This will cause permanent loss of image ?" onConfirm={() => this.deleteImage()} onCancel={this.cancel} okText="Yes" cancelText="No">  <Button
                              type="danger"
                             
                            >
                              <Icon type="delete" /> 
                            </Button></Popconfirm>
                          </Col></Row>
                </Form.Item>)}
              
              </Col>
              <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
              <Form.Item
                {...formItemLayout}
                label="Product Images"
                extra="Add Product Images"
              >
                <div className="dropbox">
                  {getFieldDecorator('dragger',{
                    rules: [{
                            required: true, message: 'Please upload product images',
                    }],
                  }
                  )(
                    <Upload.Dragger name="Product_Images"  {...props} listType="picture" >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                    </Upload.Dragger>
                  )}
                </div>
              </Form.Item>
              </Col>
{/* =============================== Submit Button ======================================== */}
<Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}} className="m-t-100">
        <Form.Item
          wrapperCol={{ span: 12}}
        >
          <Button type="primary" size="large" htmlType="submit" loading={this.state.iconLoading}>Submit</Button>
          <Button style={{ marginLeft: 15 }} size="large" onClick={this.handleReset}>
              Clear
            </Button>
        </Form.Item>
        </Col>
          </Form>
        </Row>
       
         </div>
         </Spin>
        </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
    categories: state.categories,
    subcategories: state.subcategories,
    currency: state.currency,
    status:state.status,
    coupon:state.coupon,
    products:state.products
  };
}

const WrappedRegistrationForm = Form.create()(EditProduct);

export default connect(mapStateToProps, {emailLogin,couponList1,logout, updateAsset, catList, subcatList, currencyList,filtersubCat,SingleProduct})(WrappedRegistrationForm);
