import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {subCat, catList, checksubcat} from '../actions/assetAction';
import _ from 'lodash';
import {Form, Input, Icon, Row, Upload,Radio, Button,Select,Avatar, message} from 'antd';
//import 'antd/dist/antd.css';
import '../styles/css/App.css';


const { TextArea } = Input;
const { Option } = Select;

class Subcategory extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
          fileList1: "",
          iconLoading: false ,
          valstatus:''
        };

    }

   
    componentWillMount()
    {
    //  componentWillMount
    this.props.catList();
    }  
    checkPrice = (rule, value, callback) => { 
      // console.log('changed', value);
       const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
       if ((!Number.isNaN(value) && reg.test(value)) || value === '')
        {
         callback();
         return;
       }
       callback('Input only a numermic value...!');
     }
    handleReset = () => {
      this.props.form.resetFields();
      this.setState({
        iconLoading: false,
        fileList1: ""
      });	
    }
  

    handleSubmit = (e) => {
      e.preventDefault();
     

      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
         
          this.setState({
            iconLoading: true
          });	
         
          this.props.checksubcat(values.Sub_Category_Id).then((result) => {
    
            console.log(result);
               this.setState({
                valstatus: "success",  
              });
              this.props.subCat(values, this.state.fileList1)
              .then((result) => {

                this.setState({
                  iconLoading: false,
                  fileList1: "",
                  valstatus: "",
                });	
                this.props.form.resetFields();
               
                message.success("Sub-Category added successfully!");
                
              }).catch((err) => {
                
              });
            
            
          }).catch((err) => {
            if (err.exists) 
            {
            console.log(err);
               this.setState({
                valstatus: "error", 
                iconLoading: false, 
              });
            message.error('Sub-Category ID already exist, Kindly input unique ID');
            }
            else
            {
              message.error(err);
            }
          });

        }
      });
    }
   
    renderCategory()
    {
         return _.map(this.props.categories,(categories,key) => {
                return (
                  <Option value={categories.id} key={key}> 
                  <Avatar src={categories.imgUrl} />
                  {categories.cname}
                  {/* ({categories.cid})  */}
                  </Option>
                );
              })
    }
    normFile = e => {
      console.log("Upload event:", e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

// ======================================================

    render() {
     
      const { fileList1 } = this.state;
     
    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".png,.jpg",
      showUploadList	: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList1.indexOf(file);
          const newFileList = state.fileList1.slice();
          newFileList.splice(index, 1);
          return {
            fileList1: newFileList,
          };
        });
        console.log(this.state.fileList1);
      },
      beforeUpload: (file) => {
        this.setState(state => ({
          fileList1: [...state.fileList1, file],
        }));
        return false;
      },
      fileList1,
    };
    
      const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
      };


//==============================================================================================================//
       
          return (
<div>
            <div className="container-fluid addp">
            <div className="container-fluid">
            <div className="row">
                <div className="col-sm-4">
                    <h4>Add Sub-Category</h4>
                </div>
                <div className="col-sm-8 text-right">
                <Link to="/subcategory"><Button className="pull-right" type="dark" icon="arrow-left">Back To Sub-Category</Button></Link>
                </div>
                </div>
            
            </div>
            </div>
          <div className="container-fluid text-left addproduct">
          
          <Row>
             <Form onSubmit={this.handleSubmit}>

                <Form.Item
                  {...formItemLayout}
                  label="Category Name"
                >
                  {getFieldDecorator('Ref_Category_Id', {
                    rules: [{ required: true, message: 'Select Category', whitespace: false }],
                  })(
                    <Select
                      placeholder="Select Category"
                    >
                    {this.renderCategory()}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  label="Sub-Category ID"
                  hasFeedback 
                  validateStatus={this.state.valstatus}
                >
                  {getFieldDecorator('Sub_Category_Id', {
                    rules: [
              {
                required: true,
                pattern: new RegExp("^[A-Za-z0-9]+$"),
                message: "Sub Category ID should be alphanumeric only"
              }
            ],
                  })(
                    <Input style={{ width: 250 }} id={this.state.valstatus}/>
                  )}
                </Form.Item>

               

                <Form.Item
                  {...formItemLayout}
                  label="Sub-Category Name"
                >
                  {getFieldDecorator('Sub_Category_Name', {
                    rules: [{ required: true, message: 'Enter Sub-Category name', whitespace: false }],
                  })(
                    <Input />
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  label="Sub-Category Description"
                >
                  {getFieldDecorator('Sub_Category_Description', {
                    rules: [{ required: true, message: 'Enter Sub-Category Description', whitespace: false }],
                  })(
                    <TextArea rows ={5} />
                  )}
                </Form.Item>

                <Form.Item
                  {...formItemLayout}
                  label="Sub-Category Status"
                >
                  {getFieldDecorator('Sub_Category_Status', {
                    initialValue: "true",
                    rules: [{ required: true, message: 'Enter Sub-Category Status', whitespace: false }],
                  })(
                    <Radio.Group>
                      <Radio value="true">Active</Radio>
                      <Radio value="false">In-Active</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>

                <Form.Item
                {...formItemLayout}
                label="Sub-Category Image"
                // extra="Sub-Category Image"
              >
                {getFieldDecorator('Sub_Category_Image_Url', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normFile,
                    rules: [{
                        required: true, message: 'Please upload product display image!',
                    }],
                  })(
                  <Upload name="Sub_Category_Image_Url"  {...propsthumb} >
                    {fileList1.length === 1 ? 
                      null : 
                      <div>
                        <Icon type="plus" />
                        <div className="ant-upload-text">Choose</div>
                      </div>
                      }                    
                  </Upload>
                )}
              </Form.Item>

             

              
{/* =============================== Submit Button ======================================== */}

        <Form.Item
          wrapperCol={{ span: 12, offset: 6 }}
        >
          <Button type="primary" size="large" htmlType="submit" loading={this.state.iconLoading}>Submit</Button>
          <Button style={{ marginLeft: 15 }} size="large" onClick={this.handleReset}>
              Clear
            </Button>
        </Form.Item>
          </Form>
        </Row>
       
         </div>
        </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
    categories: state.categories
  };
}

const WrappedRegistrationForm = Form.create()(Subcategory);

export default connect(mapStateToProps, {subCat, catList, checksubcat})(WrappedRegistrationForm);
