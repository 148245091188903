import { GET_SUCCESS, GET_ERROR } from '../actionTypes';

const initialState = {
    errors: '',
    success: ''
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ERROR:
        return {
            ...state,
            errors: '',
            success: action.payload
        };

        case GET_SUCCESS:
            return {
                ...state,
                success: '',
                errors: action.payload
            }

        default:
            return state;
        }
}
