import React, {Component} from 'react';
import {connect} from 'react-redux';
import {DriverDetail, DriverUpdate, CityTable, CountryCodeTable} from '../actions/driverAction';
import {Form, Row, Col, Input, Upload,Icon, Spin, Button, Radio, InputNumber, Select, message} from 'antd';
import _ from 'lodash';
const { Option } = Select;

class EditDriver extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            fileList: [],
            Discounted:''
        };
       
    }
    componentWillMount()
    {
      this.props.CityTable();
      this.props.CountryCodeTable();
        var drvrid;
        console.log(this.props.location.driverid);
        if (this.props.location.driverid === undefined)
        {
            drvrid = localStorage.getItem('drid');
            this.props.DriverDetail(drvrid).then((result) => {
                this.setState({ loading: false, Discounted:this.props.driver.commission});
            }).catch((err) => {
                
            });
            console.log("if Block ", drvrid);
        }
        else
        {
            localStorage.setItem('drid', this.props.location.driverid);
            drvrid = localStorage.getItem('drid');
            this.props.DriverDetail(drvrid).then((result) => {
              this.setState({ loading: false, Discounted:this.props.driver.commission});
            }).catch((err) => {
                
            });
            console.log(drvrid);
        }
    }
    checkPrice = (rule, value, callback) => { 
      // console.log('changed', value);
       const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
       if ((!Number.isNaN(value) && reg.test(value)) || value === '')
        {
         callback();
         return;
       }
       callback('Input only a numermic value...!');
     }

    handleDiscounted = (e) => {
      this.setState({
        Discounted: e.target.value,
      });
      console.log(this.state.Discounted)
  }

    handleSubmit = (e) => {
      e.preventDefault();
     
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) 
        { 
          this.setState({
            loading: true,
          });	
          var driverid = localStorage.getItem('drid');
          console.log(values);
          this.props.DriverUpdate(values, driverid , this.state.fileList)
          .then((result) => {

            message.success('Driver Data Updated Successfully..!');
            this.setState({
              loading: false,
            });	
            this.props.history.push('/user');
          }).catch((err) => {
            
          });
        }
      });
    }

    handleReset = () => {
      this.props.form.resetFields();
      this.setState({
        fileList: []
      });	
    }

    Codechange = (e) => {
      console.log(e);
    }

    renderCategory()
    {
         return _.map(this.props.categories,(categories,key) => {
                return (
                  <Option value={categories.key} key={key}>{categories.cname}</Option>
                );
              })
    }
    renderCode()
    {
         return _.map(this.props.subcategories,(subcategories,key) => {
                return (
                  <Option value={subcategories.key} key={key}>+{subcategories.code}</Option>
                );
              })
    }

    render() {

      const { fileList } = this.state;
      
      const props = {
      multiple: true, 
      listType: "picture",
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
       fileList,
    };

        const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      const prefixSelector = getFieldDecorator('countrycode', {
        initialValue: this.props.driver.countycode,
      })(
        <Select onChange={this.Codechange} style={{ width: 70 }}>
          {this.renderCode()}
        </Select>
      );

        return (

            <div>
                 <div className="container-fluid addp">
              <div className="container-fluid">
               <h4>Edit Driver Details</h4>
              </div>
            </div>
            <div className="container-fluid text-left addproduct">
            <Spin spinning={this.state.loading}>
          <Row gutter={15}>
            <Form onSubmit={this.handleSubmit}>
            <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
            <h4 className="formtitle" style={{ marginTop: 0}}>Personal Information</h4>
            </Col>
            <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="First Name:"
                >
                  {getFieldDecorator('firstname', {
                    initialValue: this.props.driver.firstname,
                   
                    rules: [{ required: true, message: 'Enter firstname', whitespace: false }],
                  })(<Input />)}
                </Form.Item>
                </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Last Name:"
                >
                  {getFieldDecorator('lastname', {
                    initialValue:this.props.driver.lastname,
                   
                    rules: [{ required: true, message: 'Enter Lastname', whitespace: false }],
                  })(<Input />)}
                </Form.Item>
                </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Email Address:"
                >
                  {getFieldDecorator('emailaddres', {
                    initialValue:this.props.driver.email,
                   
                    rules: [{ required: true, message: 'Enter valid email address', whitespace: false }],
                  })(<Input disabled/>)}
                </Form.Item>
                </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                label="Phone Number"
              >
                {getFieldDecorator('phone', {
                  initialValue:this.props.driver.mobile,
                  rules: [{ required: true, message: 'Please input your phone number!' }],
                })(
                  <Input addonBefore={prefixSelector} style={{ width: '85%' }} />
                )}
              </Form.Item>
                </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Operating City:"
                >
                  {getFieldDecorator('city', {
                    initialValue:this.props.driver.opeartingcity,
                   
                    rules: [{ required: true, message: 'Select operating city', whitespace: false }],
                  })(
                    <Select
                      showSearch
                      placeholder="Select city"
                      optionFilterProp="children"
                      onChange={this.handlesubCategory}
                      filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                    >
                    {this.renderCategory()}
                    </Select>
                  )}
                </Form.Item>
                </Col>
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Company:"
                >
                  {getFieldDecorator('company', {
                    initialValue:this.props.driver.company,
                   
                    rules: [{ required: true, message: 'Enter company name', whitespace: false }],
                  })(<Input />)}
                </Form.Item>
                </Col>
                <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
            <h4 className="formtitle" style={{ marginTop: 0}}>Commission Information</h4>
            </Col>
            <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>

                <Form.Item
                  {...formItemLayout}
                  label="Commission Applicable:"
                >
                  {getFieldDecorator('commisonapplicable', {
                    initialValue: this.props.driver.commission,
                    rules: [{ required: true, message: 'Select YES if comssion applicable', whitespace: false }],
                  })(
                    <Radio.Group                       
                    onChange={this.handleDiscounted}
                    >
                    <Radio value="true">YES</Radio>
                      <Radio value="false">NO</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                </Col>
                {this.state.Discounted === "true" ? (
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Commission %:"
                >
                  {getFieldDecorator('commission', {
                    initialValue: this.props.driver.commission_value,
                   
                    rules: [{ required: true, validator: this.checkPrice }],
                  })(<InputNumber style={{ width: 300 }} />)}
                </Form.Item>
                </Col>) : null}
                <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
                  <h4 className="formtitle">Uploaded Documents</h4>
                  </Col>
                  <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
              <Form.Item
                {...formItemLayout}
                label="Upload Document"
                extra="Accept all file format"
              >
                <div className="dropbox">
                  {getFieldDecorator('fileupload',{
                    
                    rules: [{
                            required: true, message: 'Please upload document',
                    }],
                  }
                  )(
                    <Upload.Dragger name="Product_Images" fileList={this.props.driver.document}  {...props} listType="picture" >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                    </Upload.Dragger>
                  )}
                </div>
              </Form.Item>
              </Col>
              <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
        <Form.Item
          wrapperCol={{ span: 12}}
        >
          <Button type="primary" size="large" htmlType="submit" loading={this.state.iconLoading}>Submit</Button>
          <Button style={{ marginLeft: 15 }} size="large" onClick={this.handleReset}>
              Clear
            </Button>
        </Form.Item>
        </Col>
            </Form>
            </Row>
            </Spin>
            </div>
            </div>
        )

    }
}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
    driver: state.driver,
    categories: state.categories,
    subcategories: state.subcategories,
  };
}

const WrappedRegistrationForm = Form.create()(EditDriver);

export default connect(mapStateToProps, {DriverDetail, DriverUpdate, CityTable, CountryCodeTable})(WrappedRegistrationForm);