import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {Button, Form,Upload,Icon,Input,message,Spin} from 'antd';
import {UploadProduct} from '../actions/assetAction';
//import * as Papa from 'papaparse';
import '../styles/css/App.css';


class massUpload extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
            iconLoading: false,
            fileList1: "",
            loading:false
        };

    }

    handleReset = () => {
        this.props.form.resetFields();
        this.setState({
          iconLoading: false,
          fileList1: "",
          loading:false
        });	
      }
    

    normFile = e => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      };

      handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
          iconLoading: true,
          loading:true
        });	
  
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) 
          {
            this.props.UploadProduct(values.baseurl, this.state.fileList1).then((result) => {
              message.success('Product Upload Completed');
              this.setState({
                iconLoading: false,
                loading:false
              });	
            }).catch((err) => {
              
            });
  
          }
        });
      }



    render() {
        const { fileList1 } = this.state;
        const propsthumb = {
            multiple: false,
            name: 'file',
            accept: ".csv",
            onRemove: (file) => {
              this.setState((state) => {
                const index = state.fileList1.indexOf(file);
                const newFileList = state.fileList1.slice();
                newFileList.splice(index, 1);
                return {
                  fileList1: newFileList,
                };
              });
              console.log(this.state.fileList1);
            },
            beforeUpload: (file) => {
              this.setState(state => ({
                fileList1: [...state.fileList1, file],
              }));
              return false;
            },
            fileList1,
          };

        const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
      };

        return(
            <div>
                 <div className="container-fluid addp">
              <div className="container-fluid">
               <div className="row">
                <div className="col-sm-4">
                    <h4>Product Bulk Upload</h4>
                </div>
                <div className="col-sm-8 text-right">
                <Link to="/"><Button className="pull-right" type="dark" icon="arrow-left">Back To Products</Button></Link>
                </div>
                </div>
              </div>
            </div>
            <Spin spinning={this.state.loading}>
            <div className="container-fluid text-left addproduct">
            <Form onSubmit={this.handleSubmit}>
            
                <Form.Item
                        {...formItemLayout}
                        label="Base URL"
                      >
                        {getFieldDecorator("baseurl", {
                          
                          rules: [
                            {
                              required: true,
                              message: "Enter Base URL",
                              whitespace: false
                            }
                          ]
                        })(<Input />)}
                      </Form.Item>
            <Form.Item
                  {...formItemLayout}
                  label="Upload File" extra="Upload accept only CSV File Format"
                >
                {getFieldDecorator('Store_Image_Url', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normFile,
                    rules: [{
                        required: true, message: 'Please upload file!',
                    }],
                  })(
                <Upload {...propsthumb}>
                {fileList1.length === 1 ? 
                      null : 
                     
                      <Button>
                <Icon type="upload" /> Click to Upload
                </Button>
                     
                      }
               
            </Upload>
                  )}
                </Form.Item>
               
                <Form.Item
          wrapperCol={{ span: 12, offset: 6 }}
        >
          <Button type="primary" size="large" htmlType="submit" loading={this.state.iconLoading}>Submit</Button>
          <Button style={{ marginLeft: 15 }} size="large" onClick={this.handleReset}>
              Clear
            </Button>
        </Form.Item>
            </Form>
            </div></Spin>
            </div>
        )
    }

}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
  };
}

const WrappedRegistrationForm = Form.create()(massUpload);

export default connect(mapStateToProps, {UploadProduct})(WrappedRegistrationForm);