import React from 'react';
import '../styles/css/loading.css';

class Loading extends React.Component {
      render() {
       return(
         <div className="flex-center position-ref full-height">
        <div className="three col">
            <div className="loader">
                <span className="div div1"></span>
                <span className="div div2"></span>
                <span className="div div3"></span>
                <span className="div div4"></span>
            </div>
        </div>  
        </div>
       );
      }
   }
export default Loading;