import { GET_SUBCATEGORIES, GET_COUNTRY_CODE } from '../actionTypes';

export default function(state = {}, action) {
    switch (action.type) {
        case GET_SUBCATEGORIES:
            return action.payload;
        case GET_COUNTRY_CODE:
            return action.payload;
        default:
            return state;
    }
}
