import React, { Component } from 'react';
import { connect } from 'react-redux';
import { emailLogin } from '../actions/userAction';
import '../styles/css/App.css';
import Logo from '../styles/img/logo-main.svg';
import Loginimg from '../styles/img/undraw_deliveries_131a.svg';
import {Button,message} from 'antd';


class Login extends Component {

  constructor(props) {
    super(props);
    // state
    this.state = {
        email: '',
        password: '',
        loading: false,
      };
    // bind
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
   
}

componentWillMount() 
{
  if (this.props.user !== null) {
    this.props.history.push('/');
  }
}
componentWillReceiveProps(nextProps) {
  if (nextProps.user !== null) {
    nextProps.history.push('/');
  }
}


// handle change
handleChange(e) {
  this.setState({
      [e.target.name]: e.target.value
  });
}

// handle submit
handleSubmit(e) {
  this.setState({
    iconLoading : true
  })
  e.preventDefault();
  this.props.emailLogin(this.state.email, this.state.password)
  .then((succ) => {
    message.success("Logged in!");
    this.setState({
      iconLoading : false
    })
  })
  .catch((err) => 
    {
      message.error("Your Username or Password is incorrect! Try again.");
      this.setState({
        iconLoading : false
      })
    });
}


  render() {
    return (
     
      <div className="cover-full">
          <div className="container-fluid">
          <div className="row">
        
          <div className="col-md-7 hidden-xs">
          <img src={Loginimg} className="img-responsive" alt="loginimage"/>
          </div>
          <div className="col-md-5 col-sm-6 col-xs-12">
            <div className="panel panel-default">
                    <div className="panel-body">
                      <img src={Logo} className="img-responsive loginlogo" alt="Logo"/> 
                                            
                            <form onSubmit={this.handleSubmit}>
                              <div className="form-group">
                                <input type="email" name="email" onChange={this.handleChange}
                                    value={this.state.email} className="form-control" id="email" placeholder="Email" required />
                              </div>
                              <div className="form-group">
                                <input type="password" name="password" onChange={this.handleChange}
                                    value={this.state.password} className="form-control" id="pwd"
                                    placeholder="Password" required />
                              </div>
                              <Button htmlType="submit" size="large" type="primary" loading={this.state.iconLoading} >Login</Button>
                             
                             </form>
                             
                     </div>
                  </div>
          </div>
          </div>
             </div>
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps, { emailLogin})(Login);
