import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { UpdatePromo,promotable, Singlepromo,currencyList } from "../actions/assetAction";
import debounce from "lodash/debounce";
import _ from "lodash";
import {db} from '../firebase';
import request from "superagent";
import {
  Form,
  Row,
  Button,
  message,
  Col,
  Card,
  Select,
  Spin,
  Input,
  DatePicker,
  Upload,
  Icon
} from "antd";
import moment from "moment";
import "../styles/css/App.css";

let bucket_baseurl;

const { Option } = Select;
const { Meta } = Card;
const { TextArea } = Input;

class EditPromo extends Component {
  constructor(props) {
    super(props);
    // state
    // this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      symbol: "",
      fileList: [],
      prodList: [],
      singleprod: [],
      PromoTypeID: null,
      loading1:false,
      proddup:null,
      filetag:1,
      promosid:""
      
    };
  }

componentDidMount() {
  this.setState({loading1: true});
  this.props.promotable();
  this.props.currencyList();
  var reqid;
  var promosid;
  if (this.props.location.promoid === undefined) 
  {
    reqid = localStorage.getItem('drid');
    promosid = localStorage.getItem('psid');
    this
      .props
      .Singlepromo(reqid)
      .then((result) => {
        console.log(result);
        this.setState({
          promosid: promosid,
          loading1: false,
          PromoTypeID: result.Ref_Promo_Type_Id,
          startValue: result.From_Date,
          singleprod: result.promo_group,
          symbol: result.symbol,
          fileList: result.Promo_Images
        });
        this
          .props
          .form
          .setFieldsValue({promo_type: result.Ref_Promo_Type_Id, Ref_Currency_Id: result.Ref_Currency_Id, dragger: result.Promo_Images});
      })
      .catch((err) => {});
      console.log(reqid, promosid);
  } else {
    localStorage.setItem('drid', this.props.location.promoid);
    localStorage.setItem('psid', this.props.location.Promo_Status);
    reqid = localStorage.getItem('drid');
    promosid = localStorage.getItem('psid');
    this
      .props
      .Singlepromo(reqid)
      .then((result) => {
        this.setState({
          promosid: promosid,
          loading1: false,
          PromoTypeID: result.Ref_Promo_Type_Id,
          startValue: result.From_Date,
          singleprod: result.promo_group,
          symbol: result.symbol,
          fileList: result.Promo_Images
        });
        this
          .props
          .form
          .setFieldsValue({promo_type: result.Ref_Promo_Type_Id, Ref_Currency_Id: result.Ref_Currency_Id, dragger: result.Promo_Images});
      })
      .catch((err) => {});
    console.log(reqid, promosid);
  }

  db.collection("Constants").get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
        bucket_baseurl = doc.data().Bucket_Base_Url;
    });
});

}

  fetchUser = async value => {
    //console.log('fetching user', value.length);
    if (value.length > 3) {
      this.setState({ data: [], fetching: true });
      try {
        const res = await request
          .post(
            "https://us-central1-pickmelocals.cloudfunctions.net/searchproduct"
          )
          .send({ PromoTypeID: this.state.PromoTypeID, ProductText: value });

        // res.body, res.headers, res.status
        console.log(res.body);
        if (res.body.ReturnCode === 201) {
          this.setState({ fetching: false });
        } else {
          const data = res.body.Product_Details.map(user => ({
            Ref_Products_Display_Name: `${user.Ref_Products_Display_Name}`,
            Ref_Products_Id: user.Ref_Products_Id,
            Ref_Products_Image_Url: user.Ref_Products_Image_Url[0],
            Promo_Exists_Already: user.Promo_Exists_Already
          }));
          this.setState({
            data,
            fetching: false,
            prodList: res.body.Product_Details
          });
        }
      } catch (err) {
        return err;
      }
    }
  };

  handleCurrencyChange = (value) => {
    db.collection("MD_Currency").doc(value).onSnapshot(doc =>{    
      console.log(doc.data().Currency_Symbol);
      this.setState({
        symbol: doc.data().Currency_Symbol,
      });
    })      
}

  handleChange = value => {
    console.log(value);
    const productlist = this.state.prodList;
    const productlist1 = this.state.singleprod
    var selected_Product =
      productlist[
        productlist
          .map(function(item) {
            return item.Ref_Products_Id;
          })
          .indexOf(value.key)
      ];

      var dup_Product =
      productlist1[
        productlist1
          .map(function(item) {
            return item.Key;
          })
          .indexOf(value.key)
      ];
    console.log(dup_Product);

    if(dup_Product !== undefined)
    {
      message.error('Product Already Selected..!');
    }
    else
    {
      var productarr = this.state.singleprod;
      productarr.push(selected_Product);
  
      this.setState({
        value,
        data: [],
        fetching: false,
        singleprod: productarr
      });
    }

  };




  handleBuyOne = value => {

    console.log(value);

    const productlist = this.state.prodList;
    var selected_Product =
      productlist[
        productlist
          .map(function(item) {
            return item.Ref_Products_Id;
          })
          .indexOf(value.key)
      ];

    console.log(selected_Product);
    if (selected_Product.Product_Is_Discounted === true) {
      this.props.form.setFieldsValue({
        promo_price: parseFloat(Math.round(selected_Product.Product_Discounted_Price * 100) / 100).toFixed(2)
      });
    } else {
      this.props.form.setFieldsValue({
        promo_price: parseFloat(Math.round(selected_Product.Product_Actual_Price * 100) / 100).toFixed(2)
      });
    }
    var productarr = [];
    productarr.push(selected_Product);

    this.setState({
      value,
      data: [],
      fetching: false,
      singleprod: productarr
    });
  };





  disabledStartDate = startValue => {
    return startValue && startValue < moment().startOf("day");
  };

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };

  onStartChange = value => {
    this.onChange("startValue", value);
  };

  onEndChange = value => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  renderCategory1() {
    return _.map(this.props.common, (common, key) => {
      return (
        <Option value={common.Promotion_Id} key={key}>
          {common.promoname}
        </Option>
      );
    });
  }

  renderCurrency()
  {
       return _.map(this.props.currency,(currency,key) => {
              return (
                <Option value={currency.id} key={key}> 
                {currency.symbol} - {currency.name} - ({currency.code}) </Option>
              );
            })
  }

  handlePromo = value => {
    console.log(`selected ${value}`);
    this.setState({ PromoTypeID: value });
    this.props.form.resetFields();
    this.setState({
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      fileList: [],
      prodList: [],
      singleprod: [],
    });
  };

  handleDeleteProduct = key => {
    console.log(key);
    const singleprod = [...this.state.singleprod];
    this.setState({ singleprod: singleprod.filter(item => item.Key !== key) });
    //this.setState({ singleprod:[] });
    this.props.form.setFieldsValue({
      product: ""
    });
    console.log(this.state.singleprod);
  };

  normFile = e => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      fileList: [],
      prodList: [],
      singleprod: [],
      PromoTypeID: null,
      symbol: "",
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        
        var promoid = localStorage.getItem('drid');
        // console.log(values)
        // console.log(this.state.fileList)
        // console.log(this.state.fileList1)
        // console.log(this.state.singleprod)
        this.setState({
          iconLoading: true,
          loading1:true
        });
        this.props
          .UpdatePromo(
            values,
            this.state.fileList,
            this.state.fileList1,
            this.state.singleprod,
            this.props.promo,
            promoid
          )
          .then(result => {
            this.setState({
              iconLoading: false
            });
            
            this.props.form.resetFields();
            this.setState({
              data: [],
              value: [],
              fetching: false,
              fileList1: "",
              fileList: [],
              prodList: [],
              singleprod: [],
              PromoTypeID: null,
              loading1:false,
              symbol: "",
            });
            message.success("Promo updated successfully!");
            setTimeout(() => {
               this.props.history.push("/premierpromo");
            }, 3000);
           
          })
          .catch(err => {
            this.setState({
              iconLoading: false,
              loading1:false
            });
            message.error(err.message);
          });
      }
    });
  };
  deleteImage = () => {
    this.setState({
        filetag:0
      });	
}
updatefilelist =(d) =>
{
 console.log(d);
 this.props.form.setFieldsValue({
  dragger:d
});
}
  // ======================================================

  render() {
    
    const { endOpen, fileList1,fileList } = this.state;
    const { singleprod } = this.state;
    const { getFieldDecorator  } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    };
    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".png,.jpg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList1.indexOf(file);
          const newFileList = state.fileList1.slice();
          newFileList.splice(index, 1);
          return {
            fileList1: newFileList
          };
        });
        
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList1: [...state.fileList1, file]
        }));
        return false;
      },
      fileList1
    };

    

    //==============================================================================================================//

    return (
      <div>
        <Spin size="large" spinning={this.state.loading1}>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>View Promo</h4>
              </div>
              <div className="col-sm-8 text-right">
                <Link to="/premierpromo"><Button className="pull-right" type="dark" icon="arrow-left">Back</Button></Link>
                </div>
            </div>
          </div>
        </div>
        <div className="container-fluid text-left addproduct">
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={15}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item {...formItemLayout} label="Promo Type">
                  {getFieldDecorator("promo_type", {
                      
                    rules: [
                      {
                        required: true,
                        message: "Please Select Promo Type",
                        whitespace: false
                      }
                    ]
                  })(
                    <Select disabled
                      onChange={this.handlePromo}
                      placeholder="Select Promo Type"
                    >
                      {this.renderCategory1()}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

              
              <Row gutter={15}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <h4 className="formtitle" style={{ marginTop: 50 }}>
                  Promo Info
                </h4>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
              <Form.Item
                  {...formItemLayout}
                  label="Currency"
                >
                  {getFieldDecorator('Ref_Currency_Id', {
                    rules: [{ required: true, message: 'Enter Currency', whitespace: false }],
                  })(
                    <Select disabled
                      placeholder="Select Currency type"
                      onChange={this.handleCurrencyChange}
                    >
                    {this.renderCurrency()}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item {...formItemLayout} label="Promo Price">
                  {getFieldDecorator("promo_price", {
                    initialValue:this.props.promo.Promo_Price,
                   
                  
                    rules: [
              {
                required: true,
                pattern: new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"),
                message: "Currency format wrong!"
              }
            ]
                  })(
                   <Input  placeholder="00.00" prefix={this.state.symbol} disabled />
                  )}
                </Form.Item>
              </Col>
              <div>
              {this.state.PromoTypeID === "201" ? (
                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item {...formItemLayout} label="Buy Quantity">
                  {getFieldDecorator("buy_quantity", {
                    initialValue:this.props.promo.Buy_Promo_Qty,
                    rules: [
                      {
                        required: true,
                        message: "Buy Quantity Should not be empty",
                        whitespace: false
                      }
                    ]
                  })(<Input disabled/>)}
                </Form.Item>
              </Col>
              ) : ( null )}
              </div>
             
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item {...formItemLayout} label="Promo Description">
                  {getFieldDecorator("Promo_Description", {
                    initialValue:this.props.promo.Promo_Description,
                    rules: [
                      {
                        required: true,
                        message: "Enter Promo Description",
                        whitespace: false
                      }
                    ]
                  })(<TextArea disabled rows={5} />)}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item {...formItemLayout} label="Activation Date">
                  {getFieldDecorator("activation_date", {
                    initialValue:this.props.promo.From_Date,
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "Enter Activation Date"
                      }
                    ]
                  })(
                    <DatePicker disabled
                      style={{ width: 350 }}
                      disabledDate={this.disabledStartDate}
                      format="DD-MM-YYYY"
                      placeholder="Activation Date"
                      onChange={this.onStartChange}
                      onOpenChange={this.handleStartOpenChange}
                      
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Form.Item {...formItemLayout} label="Expiration Date">
                  {getFieldDecorator("expiration_date", {
                    initialValue:this.props.promo.To_Date,
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "Enter Expiration Date"
                      }
                    ]
                  })(
                    <DatePicker disabled
                      style={{ width: 350 }}
                      disabledDate={this.disabledEndDate}
                      format="DD-MM-YYYY"
                      placeholder="Expiration Date"
                      onChange={this.onEndChange}
                      open={endOpen}
                      onOpenChange={this.handleEndOpenChange}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
              <Form.Item
                  {...formItemLayout}
                  label="Promo Image"
                ></Form.Item>
              <Row gutter={15}>
              {fileList.map(d => (
               <Col key={d.uid} span={24}>
               <Card
               style={{ width: 150 }}
                         cover={
                           <img
                             alt="example"
                             src={d.url}
                           />
                         }
                        
                       >
                         
                       </Card>
               
                </Col> ))}
                </Row>
               
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
              { this.state.filetag === 0 ?
                (<Form.Item
                  {...formItemLayout}
                  label="Banner Image"
                  // extra="Category Image"
                >
                  {getFieldDecorator("banner_Url", {
                    valuePropName: "fileList",
                    getValueFromEvent: this.normFile,
                    rules: [
                      {
                        required: true,
                        message: "Please Upload Banner Display Image!"
                      }
                    ]
                  })(
                    <Upload name="Category_Image_Url" {...propsthumb}>
                      {fileList1.length === 1 ? null : (
                        <div>
                          <Icon type="plus" />
                          <div className="ant-upload-text">Choose</div>
                        </div>
                      )}
                    </Upload>
                  )}
                </Form.Item>):(<Form.Item
                  {...formItemLayout}
                  label="Banner Image"
                >
                <Row gutter={15}>
               
                <Col span={24}>
                <Card
                style={{ width: 150 }}
                          cover={
                            <img
                              alt="example"
                              src={bucket_baseurl+this.props.promo.Banner_image}
                            />
                          }
                         
                        >
                          
                        </Card>
                
                 </Col>
                 </Row>
                </Form.Item>)}
              </Col>
            </Row>
            <Row gutter={15}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <h4 className="formtitle" style={{ marginTop: 50 }}>
                    Product Info
                  </h4>
                </Col>
                </Row>
            {(() => {
        switch(this.state.PromoTypeID) {
          case '101':
            return (
              <div>
            
              {singleprod.length !== 0 ? (
              <Row gutter={15}>
                <h5 className="resulttitle">Selected Product</h5>
                <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 24 }}>
                  <Row gutter={15}>
                    {singleprod.map(d => (
                      <Col
                        key={d.Key}
                        xs={{ span: 24 }}
                        sm={{ span: 8 }}
                        lg={{ span: 6 }}
                      >
                        <Card
                          cover={
                            <img
                              alt="example"
                              src={bucket_baseurl+d.Ref_Products_Image_Url[0]}
                            />
                          }
                         
                        >
                          <Meta title={d.Ref_Products_Display_Name} />
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>):null}
              </div>);
          case '201':
            return (      <div>
              
      
              {singleprod.length !== 0 ? (
              <Row gutter={15}>
                <h5 className="resulttitle">Selected Product</h5>
                <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 24 }}>
                  <Row gutter={15}>
                    {singleprod.map(d => (
                      <Col
                        key={d.Key}
                        xs={{ span: 24 }}
                        sm={{ span: 8 }}
                        lg={{ span: 4 }}
                      >
                        <Card
                          cover={
                            <img
                              alt="example"
                              src={bucket_baseurl+d.Ref_Products_Image_Url[0]}
                            />
                          }
                         
                        >
                          <Meta title={d.Ref_Products_Display_Name} />
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>):null}
              </div>);
              case '501':
            return (      <div>
              
      
              {singleprod.length !== 0 ? (
              <Row gutter={15}>
                <h5 className="resulttitle">Selected Product</h5>
                <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 24 }}>
                  <Row gutter={15}>
                    {singleprod.map(d => (
                      <Col
                        key={d.Key}
                        xs={{ span: 24 }}
                        sm={{ span: 8 }}
                        lg={{ span: 4 }}
                      >
                        <Card
                          cover={
                            <img
                              alt="example"
                              src={bucket_baseurl+d.Ref_Products_Image_Url[0]}
                            />
                          }
                         
                        >
                          <Meta title={d.Ref_Products_Display_Name} />
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>):null}
              </div>);
          case '301':
            return (<div>
              </div>);
            case '401':
            return (<div>
              </div>);
          default:
            return null;
        }
      })()}
           
 
      {this.state.promosid === "1" ?
      null:(<Row gutter={15} className="m-t-100">
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item wrapperCol={{ span: 12 }}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={this.state.iconLoading}
                    
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ marginLeft: 15 }}
                    size="large"
                    onClick={this.handleReset}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Col>
            </Row>)}
            
          </Form>
        </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    common: state.common,
    currency: state.currency,
    promo:state.promo
  };
}

const WrappedRegistrationForm = Form.create()(EditPromo);

export default connect(
  mapStateToProps,
  { UpdatePromo,promotable, Singlepromo,currencyList }
)(WrappedRegistrationForm);