import React, { Component } from "react";
import { Icon} from "antd";
import AddToHomescreen from 'react-add-to-homescreen';


class AddtoHome extends Component {

  handleAddToHomescreenClick = () => {
    alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);};
  
  render() {
    
    return (
       
        <AddToHomescreen onAddToHomescreenClick={() => this.handleAddToHomescreenClick()} icon={<Icon type="more" />} title="SimpliNEET"/>

    );
  }
}


export default (AddtoHome);
