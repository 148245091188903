import {auth, db} from '../firebase';
import {GET_USER, USER_STATUS, GET_DRIVER} from '../actionTypes';
import moment from 'moment';

export function getUser() {

  return dispatch => {
    // show loading status before getting user to true
    dispatch({type: USER_STATUS, payload: true});
    auth.onAuthStateChanged(user => {
      dispatch({type: GET_USER, payload: user});

      // show loading status to false
      dispatch({type: USER_STATUS, payload: false});

    });

  };
}

export function emailLogin(email, password) {
  return dispatch => auth.signInWithEmailAndPassword(email, password);
}

export function createuser(email, password, userid) {
  return dispatch => {
    return new Promise((resolve, reject) => {

      auth
        .createUserWithEmailAndPassword(email, password)
        .then(function (user) {
          //console.log(user);
          const uid = user.user.uid;

          db
            .collection("Store_Manager")
            .doc(userid)
            .update({Manager_Id: uid, IsActive: true})
            .then(result => {
              //resolve(true);
              db
                .collection("Store_Manager")
                .doc(userid)
                .get()
                .then(function (doc) {
                  if (doc.exists) {
                    var storeid = doc
                      .data()
                      .Ref_Store_Id;
                    var storemailid = doc
                      .data()
                      .Manager_Email_Address
                    //console.log(storeid);
                    db
                      .collection("Stores_Master")
                      .doc(storeid)
                      .update({IsActive: true, Store_Availability_Status: true})
                      .then((result) => {
                        resolve(true);
                        processcomplete(storemailid);
                      })
                      .catch((err) => {
                        reject(err);
                      });

                    function processcomplete(storemailid) {
                      db
                        .collection("Store_Request")
                        .where("Manager_Email_Address", "==", storemailid)
                        .get()
                        .then(function (querySnapshot) {
                          querySnapshot
                            .forEach(function (doc) {
                              // doc.data() is never undefined for query doc snapshots
                              console.log(doc.id, " => ", doc.data());
                              db
                                .collection("Store_Request")
                                .doc(doc.id)
                                .update({Ref_MD_Store_Request_Status_Id: 2})
                                .then((result) => {

                                  resolve(result);

                                })
                                .catch((err) => {
                                  reject(err)
                                });
                            });
                        })
                        .catch(function (error) {
                          console.log("Error getting documents: ", error);
                          reject(error)
                        });

                    }
                  } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                  }
                })
                .catch(function (error) {
                  console.log("Error getting document:", error);
                });

            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(function (error) {
          var errorCode = error.code;

          reject(errorCode);

        });

    });
  }
}

export function resetPassword(email) {
  return dispatch => auth.sendPasswordResetEmail(email);
}

export function updatePassword(password1) {
  //console.log(password1);
  return dispatch => auth
    .currentUser
    .updatePassword(password1);
}

export function logout() {
  return dispatch => auth.signOut();
}

export function allcustomer() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      var arr_country_code = [];
      var arr_device=[];
      db
        .collection('MD_Country_Code')
        .get()
        .then(snap_country => {
          snap_country.forEach(doc_country => {
            var country_autoId = doc_country.id,
              country_code = doc_country
                .data()
                .Country_Code
            var obj_country_code = {
              country_autoId: country_autoId,
              country_code: country_code
            }
            arr_country_code.push(obj_country_code)
          });
          get_device_details(arr_country_code)
        })
        .catch(err => {
          console.log('Error getting country code', err);
          reject(err);
        });

        function get_device_details(arr_country_code)
        {
          db
        .collection('Customer_Device_Details')
        .get()
        .then(snap_country => {
          console.log(snap_country.size);
          snap_country.forEach(doc_country => {
            var customer_id = doc_country.id;
            var App_Version  = (doc_country.data().App_Version === undefined ? "-" : doc_country.data().App_Version);
            var Device_Make = doc_country.data().Device_Make;
            var Device_Model = doc_country.data().Device_Model;
            var Device_OS = doc_country.data().Device_OS;
            var obj_country_code = {
              customer_id: customer_id,
              App_Version: App_Version,
              Device_Make:Device_Make,
              Device_Model:Device_Model,
              Device_OS:Device_OS
            }
            arr_device.push(obj_country_code)
          });
          //console.log(arr_device)
          get_country_code(arr_country_code,arr_device)
        })
        .catch(err => {
          console.log('Error getting country code', err);
          reject(err);
        });
        }

      function get_country_code(arr_country_code,arr_device) {
        var arr_customer_status = [];
        db
          .collection('Customer_Shared_Information')
          .orderBy('Created_At', 'desc')
          .get()
          .then(snap_customer_status => {
            var snap_size = snap_customer_status.size;
            console.log(snap_size)
            snap_customer_status.forEach(doc_driver_status => {
              var dri_doc_id = doc_driver_status.id;
             // console.log(dri_doc_id);
              var Customer_Email_Address = doc_driver_status
                .data()
                .Customer_Email_Address;
              var Customer_First_Name = doc_driver_status
                .data()
                .Customer_First_Name;
              var Customer_Last_Name = doc_driver_status
                .data()
                .Customer_Last_Name;
              var Customer_Mobile_Number = doc_driver_status
                .data()
                .Customer_Mobile_Number;
              var Created_At = moment(doc_driver_status.data().Created_At.toDate()).format('MMMM Do YYYY, h:mm:ss a');;
              var Ref_Customer_Country_Code_Id = doc_driver_status
                .data()
                .Ref_Customer_Country_Code_Id;
                var order_count = doc_driver_status.data().Customer_Number_Of_Trips;

                // Customer Country Code
              if (Ref_Customer_Country_Code_Id) {
                var selectecountry_code = arr_country_code[
                  arr_country_code
                    .map(function (item) {
                      return item.country_autoId;
                    })
                    .indexOf(Ref_Customer_Country_Code_Id)
                ];
                var Country_code = selectecountry_code.country_code;
              } else {
                Country_code = '';
              }


              //Customer Device Information
              if (dri_doc_id) {
               // console.log(dri_doc_id)
                var selectedevice_code = arr_device[
                  arr_device
                    .map(function (item) {
                      return item.customer_id;
                    })
                    .indexOf(dri_doc_id)
                ];
                if(selectedevice_code !== undefined)
                {
                  var App_Version  = selectedevice_code.App_Version;
                  var Device_Make = selectedevice_code.Device_Make;
                  var Device_Model = selectedevice_code.Device_Model;
                  var Device_OS = selectedevice_code.Device_OS;
                }
                else
                {
                  App_Version  = "-";
                  Device_Make = "-";
                  Device_Model = "-";
                  Device_OS = "-";
                }
               
              } 

              var dri_phone_num = '+' + Country_code + Customer_Mobile_Number;
              var obj_customer = {
                key: dri_doc_id,
                Customer_Name: Customer_First_Name + " " + Customer_Last_Name,
                Customer_Email_Address: Customer_Email_Address,
                Customer_Mobile_Number: dri_phone_num,
                App_Version  : App_Version,
                Device_Make : Device_Make,
                Device_Model : Device_Model,
                Device_OS : Device_OS,
                create_at: Created_At,
                order_count:order_count
                //Ref_Customer_Country_Code_Id: Ref_Customer_Country_Code_Id
              }
              arr_customer_status.push(obj_customer);
            });
            var arr_length = arr_customer_status.length;
            if (arr_length === snap_size) {

              dispatch({type: GET_DRIVER, payload: arr_customer_status});
              resolve(arr_customer_status);

            }

          })
          .catch(err => {
            console.log('Error getting documents', err);
            reject(err);
          });
        //country code

      }
    });
  }
}
