import React, {Component} from "react";
import {connect} from "react-redux";
//import { Link } from "react-router-dom";
import {addPromo, promotable, currencyList} from "../actions/assetAction";
import debounce from "lodash/debounce";
import _ from "lodash";
import {db} from '../firebase';
import request from "superagent";
import {Dev_URL} from '../actionTypes';
import {
    Form,
    Row,
    Button,
    message,
    Col,
    Card,
    Select,
    Spin,
    Input,
    DatePicker,
    Upload,
    Icon,
    Empty,
    Avatar,
    Tag,
    Modal,
    InputNumber
} from "antd";
import moment from "moment";
import "../styles/css/App.css";

const {Option} = Select;
const {Meta} = Card;
const {TextArea} = Input;

let bucket_baseurl;

class AddPromo extends Component {
    constructor(props) {
        super(props);
        // state this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 800);
        this.state = {
            data: [],
            value: [],
            fetching: false,
            fileList1: "",
            symbol: "",
            fileList: [],
            prodList: [],
            singleprod: [],
            singleproddeal: [],
            proddeal: [],
            PromoTypeID: null,
            loading1: false,
            proddup: null,
            visible: false,
            dealpro: [],
            Display_Group_Id: 1,
            validateStatus: ""
        };
    }

    componentWillMount()
    {
        this
            .props
            .promotable();
        this
            .props
            .currencyList();
            db.collection("Constants").get().then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    bucket_baseurl = doc.data().Bucket_Base_Url;
                });
            });
    }

    componentDidMount() {
        this.setState({inputValue: this.props.inputValue});
    }

    showModal = () => {
        this
            .props
            .form
            .resetFields(["Group_Name", "Min_Selection_Count", "Max_Selection_Count", "product1"]);
        this.setState({visible: true, singleproddeal: []});
        this
            .props
            .form
            .setFieldsValue({Display_Group_Id: this.state.Display_Group_Id})
    };

    handleOk = e => {
        console.log(e);
        this.setState({visible: false});
    };

    handleCancel = e => {
        console.log(e);
        this.setState({visible: false});
    };
    fetchUser = async value => {
        //console.log('fetching user', value.length);
        if (value.length > 3) {
            this.setState({data: [], fetching: true});
            try {
                const res = await request
                    .post(Dev_URL + "/searchproduct")
                    .send({PromoTypeID: this.state.PromoTypeID, ProductText: value});

                // res.body, res.headers, res.status
                console.log(res.body);
                if (res.body.ReturnCode === 201) {
                    this.setState({fetching: false});
                } else {
                    const data = res
                        .body
                        .Product_Details
                        .map(user => ({text: `${user.Ref_Products_Display_Name}`, value: user.Product_Id, proimg:bucket_baseurl+user.Ref_Products_Image_Url[0], Promo_Exists_Already: user.Promo_Exists_Already}));
                    this.setState({data, fetching: false, prodList: res.body.Product_Details});
                    console.log(this.state.prodList);
                }
            } catch (err) {
                return err;
            }
        }
    };

    handleCurrencyChange = (value) => {
        db
            .collection("MD_Currency")
            .doc(value)
            .onSnapshot(doc => {
                console.log(doc.data().Currency_Symbol);
                this.setState({
                    symbol: doc
                        .data()
                        .Currency_Symbol
                });
            })
    }

    handleChange = value => {
        console.log(value);
        const productlist = this.state.prodList;
        const productlist1 = this.state.singleprod
        var selected_Product = productlist[
            productlist
                .map(function (item) {
                    return item.Product_Id;
                })
                .indexOf(value.key)
        ];

        var dup_Product = productlist1[
            productlist1
                .map(function (item) {
                    return item.Key;
                })
                .indexOf(value.key)
        ];
        console.log(dup_Product);

        if (dup_Product !== undefined) {
            message.error('Product Already Selected..!');
        } else {
            var productarr = this.state.singleprod;
            productarr.push(selected_Product);

            this.setState({value, fetching: false, singleprod: productarr});
        }

    };

    handlesearchproduct = value => {
        console.log(value.key, this.state.prodList);
        const productlist = this.state.prodList;
        const productlist1 = this.state.singleproddeal
        var selected_Product = productlist[
            productlist
                .map(function (item) {
                    return item.Product_Id;
                })
                .indexOf(value.key)
        ];
        var dup_Product = productlist1[
            productlist1
                .map(function (item) {
                    return item.Key;
                })
                .indexOf(value.key)
        ];
        console.log(dup_Product);

        if (dup_Product !== undefined) {
            message.error('Product Already Selected..!');
        } else {
            var productarr = this.state.singleproddeal;
            productarr.push(selected_Product);

            this.setState({fetching: false, singleproddeal: productarr});
        }

    };

    handleBuyOne = value => {

        console.log(value);

        const productlist = this.state.prodList;
        var selected_Product = productlist[
            productlist
                .map(function (item) {
                    return item.Product_Id;
                })
                .indexOf(value.key)
        ];

        console.log(selected_Product);
        if (selected_Product.Product_Is_Discounted === true) {
            this
                .props
                .form
                .setFieldsValue({
                    promo_price: parseFloat(Math.round(selected_Product.Product_Discounted_Price * 100) / 100).toFixed(2)
                });
        } else {
            this
                .props
                .form
                .setFieldsValue({
                    promo_price: parseFloat(Math.round(selected_Product.Product_Actual_Price * 100) / 100).toFixed(2)
                });
        }
        var productarr = [];
        productarr.push(selected_Product);

        this.setState({value, fetching: false, singleprod: productarr});
    };

    disabledStartDate = startValue => {
        return startValue && startValue < moment().startOf("day");
    };

    disabledEndDate = endValue => {
        const {startValue} = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };

    onChange = (field, value) => {
        this.setState({[field]: value});
    };

    onStartChange = value => {
        this.onChange("startValue", value);
    };

    onEndChange = value => {
        this.onChange("endValue", value);
    };

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({endOpen: true});
        }
    };

    handleEndOpenChange = open => {
        this.setState({endOpen: open});
    };

    renderCategory1() {
        return _.map(this.props.common, (common, key) => {
            return (
                <Option value={common.Promotion_Id} key={key}>
                    {common.promoname}
                </Option>
            );
        });
    }

    renderCurrency()
    {
        return _.map(this.props.currency, (currency, key) => {
            return (
                <Option value={currency.id} key={key}>
                    {currency.symbol}
                    - {currency.name}
                    - ({currency.code})
                </Option>
            );
        })
    }

    handlePromo = value => {
        console.log(`selected ${value}`);
        this.setState({PromoTypeID: value});
        this
            .props
            .form
            .resetFields();
        this.setState({
            data: [],
            value: [],
            fetching: false,
            fileList1: "",
            fileList: [],
            prodList: [],
            singleprod: []
        });
    };

    handleDeleteProduct = (key) => {
        console.log(key);
        const singleprod = [...this.state.singleprod];
        this.setState({
            singleprod: singleprod.filter(item => item.Key !== key)
        });
        //this.setState({ singleprod:[] });
        this
            .props
            .form
            .setFieldsValue({product: ""});
        console.log(this.state.singleprod);
    };

    handledealDeleteProduct = (key) => {
        console.log(key);
        var singleproddeal = [...this.state.singleproddeal];
        this.setState({
            singleproddeal: singleproddeal.filter(item => item.Key !== key)
        });

    };

    normFile = e => {
        console.log("Upload event:", e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    handleReset = () => {
        this
            .props
            .form
            .resetFields();
        this.setState({
            data: [],
            value: [],
            fetching: false,
            fileList1: "",
            fileList: [],
            prodList: [],
            singleprod: [],
            singleproddeal: [],
            PromoTypeID: null,
            symbol: ""
        });
    };
    handledealReset = () => {
        this
            .props
            .form
            .resetFields(["Group_Name", "Min_Selection_Count", "Max_Selection_Count", "product1"]);
        this.setState({singleproddeal: []});
    };

    editgroup = (p) => {
        console.log(p);
        this.setState({visible: true, singleproddeal: p.Product_Detail});
        this
            .props
            .form
            .setFieldsValue({
                Group_Name: p.Group_Name,
                Min_Selection_Count: p.Min_Selection_Count,
                Max_Selection_Count: p.Max_Selection_Count,
                product1: [p.productfield],
                Display_Group_Id: p.Display_Group_Id
            })
    }
    deletegroup = (p) => {
        console.log(p);
        var array = this.state.proddeal;
        var evens = _.remove(array, function (n, i) {
            console.log(array, n);
            return n.Group_Name === p.Group_Name;
        });
        console.log(array, evens);
        this.DecreasesetCount();
        this.setState({proddeal: array});
    }
    setCount() {
        console.log("setcount")
        this.setState((state) => ({
            Display_Group_Id: state.Display_Group_Id + 1
        }))
        this
            .props
            .form
            .setFieldsValue({Display_Group_Id: this.state.Display_Group_Id})
    }
    DecreasesetCount() {
        console.log("DecreasesetCount")
        this.setState((state) => ({
            Display_Group_Id: state.Display_Group_Id - 1
        }))

    }

    handlegroupname = (e) => {
        console.log(e.target.value)
        this.setState({inputValue: e.target.value});
    }

    checkgroupname = (value) => {
        //console.log(value)
        var prodeal = this.state.proddeal;
        var uniquename = _.find(prodeal, function (o) {
            return o.Group_Name === value;
        });
        console.log(uniquename)
        if (uniquename !== undefined) {
            this.setState({validateStatus: "error"})
        } else {
            this.setState({validateStatus: ""})
        }

    }

    handleSubmit1 = e => {
        e.preventDefault();
        this
            .props
            .form
            .validateFieldsAndScroll([
                "Group_Name", "Min_Selection_Count", "Max_Selection_Count", "product1", "Display_Group_Id"
            ], (err, values) => {
                if (!err) {
                    console.log(values);
                    var prodeal = this.state.proddeal;
                    var prodeal1 = this.state.dealpro;
                    // console.log(prodeal, prodeal1);
                    var dup_Product = prodeal[
                        prodeal
                            .map(function (item) {
                                return item.Group_Name;
                            })
                            .indexOf(values.Group_Name)
                    ];
                    console.log(dup_Product);
                    if (dup_Product !== undefined) {

                        var editindex = _.findIndex(prodeal, function (o) {
                            return o.Group_Name === values.Group_Name;
                        });
                        console.log(editindex);
                        prodeal[editindex].Max_Selection_Count = values.Max_Selection_Count;
                        prodeal[editindex].Min_Selection_Count = values.Min_Selection_Count;
                        prodeal[editindex].Group_Name = values.Group_Name;
                        prodeal[editindex].Product_Detail = this.state.singleproddeal;
                        prodeal[editindex].productfield = values.product1;
                        prodeal[editindex].Display_Group_Id = values.Display_Group_Id;
                        this.setState({proddeal: prodeal, visible: false, singleproddeal: []});
                        message.success('Product Group Edited Successfully..!');
                        this
                            .props
                            .form
                            .resetFields(["Group_Name", "Min_Selection_Count", "Max_Selection_Count", "product1"]);

                    } else {
                        prodeal.push({
                            Max_Selection_Count: values.Max_Selection_Count,
                            Min_Selection_Count: values.Min_Selection_Count,
                            Group_Name: values.Group_Name,
                            Selection_Count: 1,
                            Product_Detail: this.state.singleproddeal,
                            productfield: values.product1,
                            Display_Group_Id: values.Display_Group_Id
                        });

                        prodeal1.push({
                            Max_Selection_Count: parseInt(values.Max_Selection_Count),
                            Min_Selection_Count: parseInt(values.Min_Selection_Count),
                            Group_Name: values.Group_Name,
                            Selection_Count: 1,
                            Display_Group_Id: values.Display_Group_Id,
                            Product_Detail: this.state.singleproddeal
                        });
                        this.setCount();
                        this.setState({proddeal: prodeal, visible: false, singleproddeal: [], dealpro: prodeal1});
                        console.log(this.state.proddeal);

                        this
                            .props
                            .form
                            .resetFields(["Group_Name", "Min_Selection_Count", "Max_Selection_Count", "product1"]);
                    }

                }
            });
    }
    handleSubmit = e => {
        e.preventDefault();

        this
            .props
            .form
            .validateFieldsAndScroll([
                "promo_type",
                "Ref_Currency_Id",
                "promo_price",
                "Promo_display_name",
                "Promo_Description",
                "activation_date",
                "expiration_date",
                "banner_Url",
                "dragger",
                "product",
                "buy_quantity",
                "promo_title",
                "Offer_Banner_Image",
                "Offer_Name",
                "Offer_Description",
                "Start_Date",
                "End_Date"
            ], (err, values) => {
                if (!err) {

                    console.log(values, this.state.dealpro)
                    this.setState({iconLoading: true, loading1: true});
                    this.props.addPromo(values, this.state.fileList, this.state.fileList1, this.state.singleprod, this.state.dealpro)
                        .then(result => {
                            this.setState({iconLoading: false});

                            this
                                .props
                                .form
                                .resetFields();
                            this.setState({
                                data: [],
                                value: [],
                                fetching: false,
                                fileList1: "",
                                fileList: [],
                                prodList: [],
                                singleprod: [],
                                PromoTypeID: null,
                                loading1: false,
                                symbol: "",
                                proddeal: [],
                                proddup: null,
                                visible: false,
                                dealpro: []
                            });
                            message.success("Promo created successfully!");
                            //this.props.history.push("/coupon");
                        })
                        .catch(err => {
                            this.setState({iconLoading: false, loading1: false});
                            message.error(err.message);
                        });
                }
            });
    };
    //==================== Dynamic Field Settings =====================
    remove = k => {
        const {form} = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one passenger
        if (keys.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k)
        });
    };

    // ======================================================

    render() {
        const {endOpen, fileList1, fileList} = this.state;
        const {fetching, data, singleprod, singleproddeal, proddeal} = this.state;
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 24
                }
            },
            wrapperCol: {
                xs: {
                    span: 24
                },
                sm: {
                    span: 20
                }
            }
        };
        const propsthumb = {
            multiple: false,
            listType: "picture-card",
            accept: ".png,.jpg",
            showUploadList: {
                showPreviewIcon: false,
                showRemoveIcon: true
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state
                        .fileList1
                        .indexOf(file);
                    const newFileList = state
                        .fileList1
                        .slice();
                    newFileList.splice(index, 1);
                    return {fileList1: newFileList};
                });
                console.log(this.state.fileList1);
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList1: [
                        ...state.fileList1,
                        file
                    ]
                }));
                return false;
            },
            fileList1
        };

        const props = {
            multiple: true,
            accept: ".png,.jpg",
            listType: "picture",
            onRemove: file => {
                this.setState(state => {
                    const index = state
                        .fileList
                        .indexOf(file);
                    const newFileList = state
                        .fileList
                        .slice();
                    newFileList.splice(index, 1);
                    return {fileList: newFileList};
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [
                        ...state.fileList,
                        file
                    ]
                }));
                return false;
            },
            fileList
        };

        // =============================================================================
        // = ================================//

        return (
            <div>
                <Spin size="large" spinning={this.state.loading1}>
                    <div className="container-fluid addp">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-4">
                                    <h4>Add Promo</h4>
                                </div>
                                {/* <div className="col-sm-8 text-right">
                <Link to="/premierpromo">
                  <Button className="pull-right" type="dark" icon="arrow-left">
                    Back
                  </Button>
                </Link>
              </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid text-left addproduct">
                        <Form onSubmit={this.handleSubmit}>
                            <Row gutter={15}>
                                <Col
                                    xs={{
                                    span: 24
                                }}
                                    sm={{
                                    span: 12
                                }}
                                    lg={{
                                    span: 12
                                }}>
                                    <Form.Item {...formItemLayout} label="Promo Type">
                                        {getFieldDecorator("promo_type", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please Select Promo Type",
                                                    whitespace: false
                                                }
                                            ]
                                        })(
                                            <Select onChange={this.handlePromo} placeholder="Select Promo Type">
                                                {this.renderCategory1()}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            {(() => {
                                switch (this.state.PromoTypeID) {
                                    case '101':
                                        return (
                                            <div>
                                                <Row gutter={15}>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        <h4
                                                            className="formtitle"
                                                            style={{
                                                            marginTop: 50
                                                        }}>
                                                            Promo Info
                                                        </h4>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Currency">
                                                            {getFieldDecorator('Ref_Currency_Id', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Enter Currency',
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(
                                                                <Select placeholder="Select Currency type" onChange={this.handleCurrencyChange}>
                                                                    {this.renderCurrency()}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Promo Price">
                                                            {getFieldDecorator("promo_price", {

                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        pattern: new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"),
                                                                        message: "Currency format wrong!"
                                                                    }
                                                                ]
                                                            })(<Input placeholder="00.00" prefix={this.state.symbol} disabled/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Promo Description">
                                                            {getFieldDecorator("Promo_Description", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Enter Promo Description",
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(<TextArea rows={5}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Activation Date">
                                                            {getFieldDecorator("activation_date", {
                                                                initialValue: this.state.startValue,
                                                                rules: [
                                                                    {
                                                                        type: "object",
                                                                        required: true,
                                                                        message: "Enter Activation Date"
                                                                    }
                                                                ]
                                                            })(<DatePicker
                                                                style={{
                                                                width: 350
                                                            }}
                                                                disabledDate={this.disabledStartDate}
                                                                format="DD-MM-YYYY"
                                                                placeholder="Activation Date"
                                                                onChange={this.onStartChange}
                                                                onOpenChange={this.handleStartOpenChange}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Expiration Date">
                                                            {getFieldDecorator("expiration_date", {
                                                                rules: [
                                                                    {
                                                                        type: "object",
                                                                        required: true,
                                                                        message: "Enter Expiration Date"
                                                                    }
                                                                ]
                                                            })(<DatePicker
                                                                style={{
                                                                width: 350
                                                            }}
                                                                disabledDate={this.disabledEndDate}
                                                                format="DD-MM-YYYY"
                                                                placeholder="Expiration Date"
                                                                onChange={this.onEndChange}
                                                                open={endOpen}
                                                                onOpenChange={this.handleEndOpenChange}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item
                                                            {...formItemLayout}
                                                            label="Promo Images"
                                                            extra="Additional Promotion Images">
                                                            <div className="dropbox">
                                                                {getFieldDecorator("dragger", {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: "Please upload promotion images"
                                                                        }
                                                                    ]
                                                                })(
                                                                    <Upload.Dragger name="Product_Images" {...props} listType="picture">
                                                                        <p className="ant-upload-drag-icon">
                                                                            <Icon type="inbox"/>
                                                                        </p>
                                                                        <p className="ant-upload-text">
                                                                            Click or drag file to this area to upload
                                                                        </p>
                                                                        <p className="ant-upload-hint">
                                                                            Support for a single or bulk upload.
                                                                        </p>
                                                                    </Upload.Dragger>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Banner Image" // extra="Category Image"
                                                        >
                                                            {getFieldDecorator("banner_Url", {
                                                                valuePropName: "fileList",
                                                                getValueFromEvent: this.normFile,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Please Upload Banner Display Image!"
                                                                    }
                                                                ]
                                                            })(
                                                                <Upload name="Category_Image_Url" {...propsthumb}>
                                                                    {fileList1.length === 1
                                                                        ? null
                                                                        : (
                                                                            <div>
                                                                                <Icon type="plus"/>
                                                                                <div className="ant-upload-text">Choose</div>
                                                                            </div>
                                                                        )}
                                                                </Upload>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={15}>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        <h4
                                                            className="formtitle"
                                                            style={{
                                                            marginTop: 50
                                                        }}>
                                                            Product Info
                                                        </h4>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Search Product">
                                                            {getFieldDecorator("product", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Select atleast one product to create promo"
                                                                    }
                                                                ]
                                                            })(
                                                                <Select
                                                                    showSearch
                                                                    labelInValue
                                                                    placeholder="Type 4 characters to search..!"
                                                                    notFoundContent={fetching
                                                                    ? <Spin size="small"/>
                                                                    : <Empty/>}
                                                                    filterOption={false}
                                                                    onSearch={this.fetchUser}
                                                                    defaultActiveFirstOption={false}
                                                                    onChange={this.handleBuyOne}
                                                                    style={{
                                                                    width: "100%"
                                                                }}>
                                                                    {data.map(d => (
                                                                        <Option
                                                                            key={d.value}
                                                                            disabled={d.Promo_Exists_Already === true
                                                                            ? true
                                                                            : false}>
                                                                            <Avatar shape="square" src={d.proimg}/> {d.text}
                                                                            <Tag
                                                                                style={{
                                                                                float: "right"
                                                                            }}
                                                                                visible={d.Promo_Exists_Already === true
                                                                                ? true
                                                                                : false}
                                                                                color="volcano">
                                                                                Promo Already Applied
                                                                            </Tag>
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {singleprod.length !== 0
                                                    ? (
                                                        <Row gutter={15}>
                                                            <h5 className="resulttitle">Selected Product</h5>
                                                            <Col
                                                                xs={{
                                                                span: 24
                                                            }}
                                                                sm={{
                                                                span: 8
                                                            }}
                                                                lg={{
                                                                span: 24
                                                            }}>
                                                                <Row gutter={15}>
                                                                    {singleprod.map(d => (
                                                                        <Col
                                                                            key={d.Key}
                                                                            xs={{
                                                                            span: 24
                                                                        }}
                                                                            sm={{
                                                                            span: 8
                                                                        }}
                                                                            lg={{
                                                                            span: 6
                                                                        }}>
                                                                            <Card
                                                                                cover={< img alt = "example" src = {
                                                                                bucket_baseurl+d.Ref_Products_Image_Url[0]
                                                                            } />}
                                                                                actions={[< Icon type = "delete" onClick = {
                                                                                    () => this.handleDeleteProduct(d.Key)
                                                                                }
                                                                                key = "delete" />]}>
                                                                                <Meta title={d.Ref_Products_Display_Name}/>
                                                                            </Card>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    )
                                                    : null}
                                            </div>
                                        )
                                    case '201':
                                        return (
                                            <div>
                                                <Row gutter={15}>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        <h4
                                                            className="formtitle"
                                                            style={{
                                                            marginTop: 50
                                                        }}>
                                                            Promo Info
                                                        </h4>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Currency">
                                                            {getFieldDecorator('Ref_Currency_Id', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Enter Currency',
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(
                                                                <Select placeholder="Select Currency type" onChange={this.handleCurrencyChange}>
                                                                    {this.renderCurrency()}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Promo Price">
                                                            {getFieldDecorator("promo_price", {

                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        pattern: new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"),
                                                                        message: "Currency format wrong!"
                                                                    }
                                                                ]
                                                            })(<Input placeholder="00.00" prefix={this.state.symbol}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Buy Quantity">
                                                            {getFieldDecorator("buy_quantity", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Buy Quantity Should not be empty",
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(<Input/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Promo Description">
                                                            {getFieldDecorator("Promo_Description", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Enter Promo Description",
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(<TextArea rows={5}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Activation Date">
                                                            {getFieldDecorator("activation_date", {
                                                                initialValue: this.state.startValue,
                                                                rules: [
                                                                    {
                                                                        type: "object",
                                                                        required: true,
                                                                        message: "Enter Activation Date"
                                                                    }
                                                                ]
                                                            })(<DatePicker
                                                                style={{
                                                                width: 350
                                                            }}
                                                                disabledDate={this.disabledStartDate}
                                                                format="DD-MM-YYYY"
                                                                placeholder="Activation Date"
                                                                onChange={this.onStartChange}
                                                                onOpenChange={this.handleStartOpenChange}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Expiration Date">
                                                            {getFieldDecorator("expiration_date", {
                                                                rules: [
                                                                    {
                                                                        type: "object",
                                                                        required: true,
                                                                        message: "Enter Expiration Date"
                                                                    }
                                                                ]
                                                            })(<DatePicker
                                                                style={{
                                                                width: 350
                                                            }}
                                                                disabledDate={this.disabledEndDate}
                                                                format="DD-MM-YYYY"
                                                                placeholder="Expiration Date"
                                                                onChange={this.onEndChange}
                                                                open={endOpen}
                                                                onOpenChange={this.handleEndOpenChange}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item
                                                            {...formItemLayout}
                                                            label="Promo Images"
                                                            extra="Additional Promotion Images">
                                                            <div className="dropbox">
                                                                {getFieldDecorator("dragger", {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: "Please upload promotion images"
                                                                        }
                                                                    ]
                                                                })(
                                                                    <Upload.Dragger name="Product_Images" {...props} listType="picture">
                                                                        <p className="ant-upload-drag-icon">
                                                                            <Icon type="inbox"/>
                                                                        </p>
                                                                        <p className="ant-upload-text">
                                                                            Click or drag file to this area to upload
                                                                        </p>
                                                                        <p className="ant-upload-hint">
                                                                            Support for a single or bulk upload.
                                                                        </p>
                                                                    </Upload.Dragger>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Banner Image" // extra="Category Image"
                                                        >
                                                            {getFieldDecorator("banner_Url", {
                                                                valuePropName: "fileList",
                                                                getValueFromEvent: this.normFile,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Please Upload Banner Display Image!"
                                                                    }
                                                                ]
                                                            })(
                                                                <Upload name="Category_Image_Url" {...propsthumb}>
                                                                    {fileList1.length === 1
                                                                        ? null
                                                                        : (
                                                                            <div>
                                                                                <Icon type="plus"/>
                                                                                <div className="ant-upload-text">Choose</div>
                                                                            </div>
                                                                        )}
                                                                </Upload>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={15}>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        <h4
                                                            className="formtitle"
                                                            style={{
                                                            marginTop: 50
                                                        }}>
                                                            Product Info
                                                        </h4>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Search Product">
                                                            {getFieldDecorator("product", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Select atleast one product to create promo"
                                                                    }
                                                                ]
                                                            })(
                                                                <Select
                                                                    showSearch
                                                                    labelInValue
                                                                    placeholder="Type 4 characters to search..!"
                                                                    notFoundContent={fetching
                                                                    ? <Spin size="small"/>
                                                                    : <Empty/>}
                                                                    filterOption={false}
                                                                    onSearch={this.fetchUser}
                                                                    defaultActiveFirstOption={false}
                                                                    onChange={this.handleChange}
                                                                    style={{
                                                                    width: "100%"
                                                                }}>
                                                                    {data.map(d => (
                                                                        <Option
                                                                            key={d.value}
                                                                            disabled={d.Promo_Exists_Already === true
                                                                            ? true
                                                                            : false}>
                                                                            <Avatar shape="square" src={d.proimg}/> {d.text}
                                                                            <Tag
                                                                                style={{
                                                                                float: "right"
                                                                            }}
                                                                                visible={d.Promo_Exists_Already === true
                                                                                ? true
                                                                                : false}
                                                                                color="volcano">
                                                                                Promo Already Applied
                                                                            </Tag>
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {singleprod.length !== 0
                                                    ? (
                                                        <Row gutter={15}>
                                                            <h5 className="resulttitle">Selected Product</h5>
                                                            <Col
                                                                xs={{
                                                                span: 24
                                                            }}
                                                                sm={{
                                                                span: 8
                                                            }}
                                                                lg={{
                                                                span: 24
                                                            }}>
                                                                <Row gutter={15}>
                                                                    {singleprod.map(d => (
                                                                        <Col
                                                                            key={d.Key}
                                                                            xs={{
                                                                            span: 24
                                                                        }}
                                                                            sm={{
                                                                            span: 8
                                                                        }}
                                                                            lg={{
                                                                            span: 6
                                                                        }}>
                                                                            <Card
                                                                                cover={< img alt = "example" src = {
                                                                                    bucket_baseurl+d.Ref_Products_Image_Url[0]
                                                                            } />}
                                                                                actions={[< Icon type = "delete" onClick = {
                                                                                    () => this.handleDeleteProduct(d.Key)
                                                                                }
                                                                                key = "delete" />]}>
                                                                                <Meta title={d.Ref_Products_Display_Name}/>
                                                                            </Card>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    )
                                                    : null}
                                            </div>
                                        )
                                        case '501':
                                        return (
                                            <div>
                                                <Row gutter={15}>
                                                <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Currency">
                                                            {getFieldDecorator('Ref_Currency_Id', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Enter Currency',
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(
                                                                <Select placeholder="Select Currency type" onChange={this.handleCurrencyChange}>
                                                                    {this.renderCurrency()}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        <h4
                                                            className="formtitle"
                                                            style={{
                                                            marginTop: 50
                                                        }}>
                                                            Promo Info
                                                        </h4>
                                                    </Col>
                                 
                                       
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Offer Name">
                                                            {getFieldDecorator("Offer_Name", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Enter Offer Name Should not be empty",
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(<Input/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Offer Description">
                                                            {getFieldDecorator("Promo_Description", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Enter Offer Description Should not be empty",
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(<TextArea rows={5}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Start Date">
                                                            {getFieldDecorator("activation_date", {
                                                                initialValue: this.state.startValue,
                                                                rules: [
                                                                    {
                                                                        type: "object",
                                                                        required: true,
                                                                        message: "Enter Activation Date"
                                                                    }
                                                                ]
                                                            })(<DatePicker
                                                                style={{
                                                                width: 350
                                                            }}
                                                                disabledDate={this.disabledStartDate}
                                                                format="DD-MM-YYYY"
                                                                placeholder="Activation Date"
                                                                onChange={this.onStartChange}
                                                                onOpenChange={this.handleStartOpenChange}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="End Date">
                                                            {getFieldDecorator("expiration_date", {
                                                                rules: [
                                                                    {
                                                                        type: "object",
                                                                        required: true,
                                                                        message: "Enter Expiration Date"
                                                                    }
                                                                ]
                                                            })(<DatePicker
                                                                style={{
                                                                width: 350
                                                            }}
                                                                disabledDate={this.disabledEndDate}
                                                                format="DD-MM-YYYY"
                                                                placeholder="Expiration Date"
                                                                onChange={this.onEndChange}
                                                                open={endOpen}
                                                                onOpenChange={this.handleEndOpenChange}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item
                                                            {...formItemLayout}
                                                            label="Offer Banner Image"
                                                            >
                                                            <div className="dropbox">
                                                                {getFieldDecorator("Offer_Banner_Image", {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: "Please upload banner images"
                                                                        }
                                                                    ]
                                                                })(
                                                                    <Upload.Dragger name="Product_Images" {...props} listType="picture">
                                                                        <p className="ant-upload-drag-icon">
                                                                            <Icon type="inbox"/>
                                                                        </p>
                                                                        <p className="ant-upload-text">
                                                                            Click or drag file to this area to upload
                                                                        </p>
                                                                        <p className="ant-upload-hint">
                                                                            Support for a single or bulk upload.
                                                                        </p>
                                                                    </Upload.Dragger>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Banner Image" // extra="Category Image"
                                                        >
                                                            {getFieldDecorator("banner_Url", {
                                                                valuePropName: "fileList",
                                                                getValueFromEvent: this.normFile,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Please Upload Banner Display Image!"
                                                                    }
                                                                ]
                                                            })(
                                                                <Upload name="Category_Image_Url" {...propsthumb}>
                                                                    {fileList1.length === 1
                                                                        ? null
                                                                        : (
                                                                            <div>
                                                                                <Icon type="plus"/>
                                                                                <div className="ant-upload-text">Choose</div>
                                                                            </div>
                                                                        )}
                                                                </Upload>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={15}>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        <h4
                                                            className="formtitle"
                                                            style={{
                                                            marginTop: 50
                                                        }}>
                                                            Product Info
                                                        </h4>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Search Product">
                                                            {getFieldDecorator("product", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Select atleast one product to create promo"
                                                                    }
                                                                ]
                                                            })(
                                                                <Select
                                                                    showSearch
                                                                    labelInValue
                                                                    placeholder="Type 4 characters to search..!"
                                                                    notFoundContent={fetching
                                                                    ? <Spin size="small"/>
                                                                    : <Empty/>}
                                                                    filterOption={false}
                                                                    onSearch={this.fetchUser}
                                                                    defaultActiveFirstOption={false}
                                                                    onChange={this.handleChange}
                                                                    style={{
                                                                    width: "100%"
                                                                }}>
                                                                    {data.map(d => (
                                                                        <Option
                                                                            key={d.value}
                                                                            disabled={d.Promo_Exists_Already === true
                                                                            ? true
                                                                            : false}>
                                                                            <Avatar shape="square" src={d.proimg}/> {d.text}
                                                                            <Tag
                                                                                style={{
                                                                                float: "right"
                                                                            }}
                                                                                visible={d.Promo_Exists_Already === true
                                                                                ? true
                                                                                : false}
                                                                                color="volcano">
                                                                                Promo Already Applied
                                                                            </Tag>
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {singleprod.length !== 0
                                                    ? (
                                                        <Row gutter={15}>
                                                            <h5 className="resulttitle">Selected Product</h5>
                                                            <Col
                                                                xs={{
                                                                span: 24
                                                            }}
                                                                sm={{
                                                                span: 8
                                                            }}
                                                                lg={{
                                                                span: 24
                                                            }}>
                                                                <Row gutter={15}>
                                                                    {singleprod.map(d => (
                                                                        <Col
                                                                            key={d.Key}
                                                                            xs={{
                                                                            span: 24
                                                                        }}
                                                                            sm={{
                                                                            span: 8
                                                                        }}
                                                                            lg={{
                                                                            span: 6
                                                                        }}>
                                                                            <Card
                                                                                cover={< img alt = "example" src = {
                                                                                bucket_baseurl+d.Ref_Products_Image_Url[0]
                                                                            } />}
                                                                                actions={[< Icon type = "delete" onClick = {
                                                                                    () => this.handleDeleteProduct(d.Key)
                                                                                }
                                                                                key = "delete" />]}>
                                                                                <Meta title={d.Ref_Products_Display_Name}/>
                                                                            </Card>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    )
                                                    : null}
                                            </div>
                                        )
                                    case '401':
                                        return (
                                            <div>
                                                <Row gutter={15}>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        <h4
                                                            className="formtitle"
                                                            style={{
                                                            marginTop: 50
                                                        }}>
                                                            Promo Info
                                                        </h4>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Currency">
                                                            {getFieldDecorator('Ref_Currency_Id', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Enter Currency',
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(
                                                                <Select placeholder="Select Currency type" onChange={this.handleCurrencyChange}>
                                                                    {this.renderCurrency()}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Promo Price">
                                                            {getFieldDecorator("promo_price", {

                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        pattern: new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"),
                                                                        message: "Currency format wrong!"
                                                                    }
                                                                ]
                                                            })(<Input placeholder="00.00" prefix={this.state.symbol}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Promo Title">
                                                            {getFieldDecorator("promo_title", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Promo title should not be empty",
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(<Input/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Promo Description">
                                                            {getFieldDecorator("Promo_Description", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Enter Promo Description",
                                                                        whitespace: false
                                                                    }
                                                                ]
                                                            })(<TextArea rows={5}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Activation Date">
                                                            {getFieldDecorator("activation_date", {
                                                                initialValue: this.state.startValue,
                                                                rules: [
                                                                    {
                                                                        type: "object",
                                                                        required: true,
                                                                        message: "Enter Activation Date"
                                                                    }
                                                                ]
                                                            })(<DatePicker
                                                                style={{
                                                                width: 350
                                                            }}
                                                                disabledDate={this.disabledStartDate}
                                                                format="DD-MM-YYYY"
                                                                placeholder="Activation Date"
                                                                onChange={this.onStartChange}
                                                                onOpenChange={this.handleStartOpenChange}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Expiration Date">
                                                            {getFieldDecorator("expiration_date", {
                                                                rules: [
                                                                    {
                                                                        type: "object",
                                                                        required: true,
                                                                        message: "Enter Expiration Date"
                                                                    }
                                                                ]
                                                            })(<DatePicker
                                                                style={{
                                                                width: 350
                                                            }}
                                                                disabledDate={this.disabledEndDate}
                                                                format="DD-MM-YYYY"
                                                                placeholder="Expiration Date"
                                                                onChange={this.onEndChange}
                                                                open={endOpen}
                                                                onOpenChange={this.handleEndOpenChange}/>)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item
                                                            {...formItemLayout}
                                                            label="Promo Images"
                                                            extra="Additional Promotion Images">
                                                            <div className="dropbox">
                                                                {getFieldDecorator("dragger", {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: "Please upload promotion images"
                                                                        }
                                                                    ]
                                                                })(
                                                                    <Upload.Dragger name="Product_Images" {...props} listType="picture">
                                                                        <p className="ant-upload-drag-icon">
                                                                            <Icon type="inbox"/>
                                                                        </p>
                                                                        <p className="ant-upload-text">
                                                                            Click or drag file to this area to upload
                                                                        </p>
                                                                        <p className="ant-upload-hint">
                                                                            Support for a single or bulk upload.
                                                                        </p>
                                                                    </Upload.Dragger>
                                                                )}
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 12
                                                    }}>
                                                        <Form.Item {...formItemLayout} label="Banner Image" // extra="Category Image"
                                                        >
                                                            {getFieldDecorator("banner_Url", {
                                                                valuePropName: "fileList",
                                                                getValueFromEvent: this.normFile,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Please Upload Banner Display Image!"
                                                                    }
                                                                ]
                                                            })(
                                                                <Upload name="Category_Image_Url" {...propsthumb}>
                                                                    {fileList1.length === 1
                                                                        ? null
                                                                        : (
                                                                            <div>
                                                                                <Icon type="plus"/>
                                                                                <div className="ant-upload-text">Choose</div>
                                                                            </div>
                                                                        )}
                                                                </Upload>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={15}>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        <h4
                                                            className="formtitle"
                                                            style={{
                                                            marginTop: 50
                                                        }}>
                                                            Group Info
                                                        </h4>
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 24
                                                    }}
                                                        lg={{
                                                        span: 24
                                                    }}>
                                                        {proddeal.length !== 0
                                                            ? (
                                                                <div>

                                                                    {proddeal.map((d) => (

                                                                        <Card key={d.Key} className="productgroup">
                                                                            <Row gutter={15}>
                                                                                <Col
                                                                                    xs={{
                                                                                    span: 24
                                                                                }}
                                                                                    sm={{
                                                                                    span: 8
                                                                                }}
                                                                                    lg={{
                                                                                    span: 8
                                                                                }}>
                                                                                    <h6>Group name</h6>
                                                                                    <h5 className="resulttitle">{d.Group_Name}</h5>
                                                                                </Col>
                                                                                <Col
                                                                                    xs={{
                                                                                    span: 24
                                                                                }}
                                                                                    sm={{
                                                                                    span: 8
                                                                                }}
                                                                                    lg={{
                                                                                    span: 8
                                                                                }}>
                                                                                    <h6>Min buy quantity</h6>
                                                                                    <h5 className="resulttitle">{d.Min_Selection_Count}</h5>
                                                                                </Col>
                                                                                <Col
                                                                                    xs={{
                                                                                    span: 24
                                                                                }}
                                                                                    sm={{
                                                                                    span: 8
                                                                                }}
                                                                                    lg={{
                                                                                    span: 8
                                                                                }}>
                                                                                    <h6>Max buy quantity</h6>
                                                                                    <h5 className="resulttitle">{d.Max_Selection_Count}</h5>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row gutter={15}>
                                                                                <h6>Selected products</h6>
                                                                                {d
                                                                                    .Product_Detail
                                                                                    .map((p) => (
                                                                                        <Col
                                                                                            key={p.Key}
                                                                                            xs={{
                                                                                            span: 24
                                                                                        }}
                                                                                            sm={{
                                                                                            span: 8
                                                                                        }}
                                                                                            lg={{
                                                                                            span: 4
                                                                                        }}>
                                                                                            <Card
                                                                                                cover={< img alt = "example" src = {
                                                                                                bucket_baseurl+p.Ref_Products_Image_Url[0]
                                                                                            } />}>
                                                                                                <Meta title={p.Ref_Products_Display_Name}/>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    ))}
                                                                            </Row>
                                                                            <hr/>
                                                                            <Row gutter={15}>
                                                                                <Col className="text-right">
                                                                                    <Button onClick={() => this.editgroup(d)}>
                                                                                        <Icon type="edit"/>
                                                                                        Edit
                                                                                    </Button>
                                                                                    <Button type="danger" ghost onClick={() => this.deletegroup(d)}>
                                                                                        <Icon type="delete"/>
                                                                                        Delete
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card>

                                                                    ))}
                                                                </div>
                                                            )
                                                            : null}
                                                    </Col>
                                                    <Col
                                                        xs={{
                                                        span: 24
                                                    }}
                                                        sm={{
                                                        span: 12
                                                    }}
                                                        lg={{
                                                        span: 8
                                                    }}>
                                                        {/* <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                  <Icon type="plus" /> Add Group
                </Button> */}
                                                        <Button type="dashed" block onClick={this.showModal}>
                                                            <Icon type="plus"/>
                                                            Add Group
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </div>

                                        )
                                    default:
                                        return null;
                                }
                            })()}

                            <Row gutter={15} className="m-t-100">
                                <Col
                                    xs={{
                                    span: 24
                                }}
                                    sm={{
                                    span: 24
                                }}
                                    lg={{
                                    span: 24
                                }}>
                                    <Form.Item
                                        wrapperCol={{
                                        span: 12
                                    }}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            loading={this.state.iconLoading}>
                                            Submit
                                        </Button>
                                        <Button
                                            style={{
                                            marginLeft: 15
                                        }}
                                            size="large"
                                            onClick={this.handleReset}>
                                            Clear
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        {this.state.PromoTypeID === "401"
                            ? (
                                <div>
                                    <Modal
                                        title="Add Group"
                                        visible={this.state.visible}
                                        onOk={this.handleOk}
                                        onCancel={this.handleCancel}
                                        footer={null}
                                        style={{
                                        top: 20
                                    }}
                                        width={1000}>
                                        <Form onSubmit={this.handleSubmit1}>
                                            <Row gutter={15}>
                                                <Col
                                                    className="hidden-visible"
                                                    xs={{
                                                    span: 24
                                                }}
                                                    sm={{
                                                    span: 24
                                                }}
                                                    lg={{
                                                    span: 24
                                                }}>
                                                    <Form.Item {...formItemLayout}>
                                                        {getFieldDecorator("Display_Group_Id", {

                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    pattern: new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"),
                                                                    message: "Display_Group_Id format wrong!"
                                                                }
                                                            ]
                                                        })(<InputNumber/>)}

                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={{
                                                    span: 24
                                                }}
                                                    sm={{
                                                    span: 24
                                                }}
                                                    lg={{
                                                    span: 8
                                                }}>
                                                    <Form.Item
                                                        {...formItemLayout}
                                                        label='Group Title'
                                                        hasFeedback
                                                        validateStatus={this.state.validateStatus}
                                                        help={this.state.validateStatus === "error"
                                                        ? "Group Title already exist another group, please try with new name"
                                                        : null}>
                                                        {getFieldDecorator("Group_Name", {
                                                            validateTrigger: [
                                                                'onChange', 'onBlur'
                                                            ],
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: "Enter Promo Group Title"
                                                                }
                                                            ]
                                                        })(<Input
                                                            id={this.state.validateStatus === "error"
                                                            ? "error"
                                                            : null}
                                                            onChange={(e) => this.handlegroupname(e)}
                                                            onBlur={() => this.checkgroupname(this.state.inputValue)}/>)}

                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={{
                                                    span: 24
                                                }}
                                                    sm={{
                                                    span: 24
                                                }}
                                                    lg={{
                                                    span: 8
                                                }}>
                                                    <Form.Item {...formItemLayout} label='Minimum Selection Count'>
                                                        {getFieldDecorator("Min_Selection_Count", {
                                                            initialValue: "1",
                                                            validateTrigger: [
                                                                'onChange', 'onBlur'
                                                            ],
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: "Minimum Selection Count Should not be empty"
                                                                }
                                                            ]
                                                        })(<Input/>)}

                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xs={{
                                                    span: 24
                                                }}
                                                    sm={{
                                                    span: 24
                                                }}
                                                    lg={{
                                                    span: 8
                                                }}>
                                                    <Form.Item {...formItemLayout} label='Maximum Selection Count'>
                                                        {getFieldDecorator("Max_Selection_Count", {
                                                            initialValue: "1",
                                                            validateTrigger: [
                                                                'onChange', 'onBlur'
                                                            ],
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: "Maximum Selection Count Should not be empty"
                                                                }
                                                            ]
                                                        })(<Input/>)}

                                                    </Form.Item>
                                                </Col>

                                                <Col
                                                    xs={{
                                                    span: 24
                                                }}
                                                    sm={{
                                                    span: 24
                                                }}
                                                    lg={{
                                                    span: 24
                                                }}>
                                                    <Form.Item {...formItemLayout} label="Search Product">
                                                        {getFieldDecorator("product1", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Select atleast one product to create promo"
                                                                }
                                                            ]
                                                        })(
                                                            <Select // mode="multiple"
    showSearch labelInValue placeholder="Type 4 characters to search..!" notFoundContent={fetching
                                                                ? <Spin size="small"/>
                                                                : <Empty/>} filterOption={false} onSearch={this.fetchUser} defaultActiveFirstOption={false} onChange={this.handlesearchproduct} style={{
                                                                width: "100%"
                                                            }}>
                                                                {data.map(d => (
                                                                    <Option
                                                                        key={d.value}
                                                                        disabled={d.Promo_Exists_Already === true
                                                                        ? true
                                                                        : false}>
                                                                        <Avatar shape="square" src={bucket_baseurl+d.proimg}/> {d.text}
                                                                        <Tag
                                                                            style={{
                                                                            float: "right"
                                                                        }}
                                                                            visible={d.Promo_Exists_Already === true
                                                                            ? true
                                                                            : false}
                                                                            color="volcano">
                                                                            Promo Already Applied
                                                                        </Tag>
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                {singleproddeal.length !== 0
                                                    ? (
                                                        <div>
                                                            <h5 className="resulttitle">Selected Product</h5>
                                                            <Row gutter={15} className="selectedprodmodal">
                                                                {singleproddeal.map((d, j) => (
                                                                    <Col
                                                                        key={d.Key}
                                                                        xs={{
                                                                        span: 24
                                                                    }}
                                                                        sm={{
                                                                        span: 4
                                                                    }}
                                                                        lg={{
                                                                        span: 4
                                                                    }}>
                                                                        <Card
                                                                            cover={< img alt = "example" src = {
                                                                            bucket_baseurl+d.Ref_Products_Image_Url[0]
                                                                        } />}
                                                                            actions={[< Icon type = "delete" onClick = {
                                                                                () => this.handledealDeleteProduct(d.Key)
                                                                            }
                                                                            key = "delete" />]}>
                                                                            <Meta title={d.Ref_Products_Display_Name}/>
                                                                        </Card>
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        </div>
                                                    )
                                                    : null}

                                            </Row>
                                            <Row gutter={15} className="m-t-100">
                                                <Col
                                                    xs={{
                                                    span: 24
                                                }}
                                                    sm={{
                                                    span: 24
                                                }}
                                                    lg={{
                                                    span: 24
                                                }}>
                                                    <Form.Item
                                                        wrapperCol={{
                                                        span: 12
                                                    }}>
                                                        <Button
                                                            disabled={this.state.validateStatus === "error"
                                                            ? true
                                                            : false}
                                                            type="primary"
                                                            size="large"
                                                            htmlType="submit"
                                                            loading={this.state.iconLoading}>
                                                            Submit
                                                        </Button>
                                                        <Button
                                                            style={{
                                                            marginLeft: 15
                                                        }}
                                                            size="large"
                                                            onClick={this.handledealReset}>
                                                            Clear
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Modal>
                                </div>
                            )
                            : null}

                    </div>
                </Spin>
            </div>

        );
    }
}

function mapStateToProps(state, ownProps) {
    return {user: state.user, common: state.common, currency: state.currency};
}

const WrappedRegistrationForm = Form.create()(AddPromo);

export default connect(mapStateToProps, {addPromo, promotable, currencyList})(WrappedRegistrationForm);