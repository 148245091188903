import React, {Component} from 'react';
import {connect} from 'react-redux';
import {dailyorderreport,StoreAll} from '../actions/assetAction';
import {Button,Form,Divider,Row,Col,Select,Empty, Table,Input,Icon } from 'antd';
import _ from "lodash";
import Highlighter from 'react-highlight-words';
import '../styles/css/App.css';
const {Option} = Select;


class vieworder extends Component {

  constructor(props) {
    super(props);
    // state
    this.state = {
      
      loading: false,
      order:"",
      tdata:[]

    };

  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => { this.searchInput = node; }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
            </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
            </Button>
        </div>
      ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
    console.log(this.state.searchText);
  }
  componentDidMount()
  {
	
      this.props.StoreAll()
        .then((result) => {
          console.log(result)
        })
        .catch((err) => {});
    
  }

  
  
  renderCategory1() {
    return _.map(this.props.order, (order, key) => {
        return (
            <Option value={order.key} key={key}>
                {order.store_name}
            </Option>
        );
    });
}


handleSubmit = e => {
  e.preventDefault();
  
  this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        
        this.setState({loading: true});

        console.log(this.state.loading);
        this.props.dailyorderreport(values.store_name).then((result) =>{
        // console.log('resolved',this.props.dashtoday)
        this.setState({loading: false,tdata:this.props.common});
        }).catch(error => {
        console.log("error");
        this.setState({loading: false});
        })

      }
    });
};
handleResett = () => {
  this.props.form.resetFields();   
  this.setState({tdata:[]});
}
  // ======================================================

  render() {

   
    
    const {getFieldDecorator} = this.props.form;
    // ==============================================================================
    const columns = [
      {
        title: "Date",
        dataIndex: "DeliveredDate",
        key: "DeliveredDate",
        ...this.getColumnSearchProps('DeliveredDate'),
        
        
      }, {
        title: "Delivered Order Count",
        dataIndex: "Total_Delivery_Count",
        key: "Total_Delivery_Count",
        
      }, {
        title: "Delivered Order Amout",
        dataIndex: "Total_Delivery_Amount",
        key: "Total_Delivery_Amount"
      }
    ];
    // ================================//

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
    <h4>Daily Order Report</h4>
              </div>
              

            </div>
          </div>
        </div>
        
          <div className="container-fluid m-t-30 text-left invoice-pad">
          <Row className="m-b-30">
            <Col>
            <Form layout="inline" onSubmit={this.handleSubmit}>
            <Form.Item label="Store Name">
                    {getFieldDecorator("store_name", {
                      rules: [
                        {
                          required: true,
                          message: "Please Select Store",
                          whitespace: false,
                        },
                      ],
                    })(
                      <Select  style={{
                        width: 340
                      }} onChange={this.handlePromo} placeholder="Select Store">
                        {this.renderCategory1()}
                      </Select>
                    )}
                  </Form.Item>
                 
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Generate
          </Button>
          <Button style={{marginLeft: 15}} type="default" onClick={this.handleResett}>Clear</Button>
        </Form.Item>
      </Form>
            </Col>
          </Row>
          <Divider dashed />
         
          {this.state.tdata.length !== 0 ? 
          ( 
    
    <div className="row">
    	<div className="col-md-12">
    		<div className="panel panel-default">
    			<div className="panel-heading">
                <h3 className="panel-title"><strong>Order Report</strong></h3>
    			</div>
    			<div className="panel-body" style={{padding: 25}}>
    			<Table
            columns={columns}
            dataSource={this.state.tdata}
            size="middle"
            bordered/>
    			</div>
    		</div>
    	</div>
    </div>
) : ( <Empty />) }
		 
</div>
          
  

         
       
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {user: state.user, order: state.order,common:state.common};
}


const WrappedRegistrationForm = Form.create()(vieworder);
export default connect(mapStateToProps, {dailyorderreport,StoreAll})(WrappedRegistrationForm);
