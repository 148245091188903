/* global google */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow
} from "react-google-maps";
import Icon from "../styles/img/delivery-bike.svg";
import Icon1 from "../styles/img/destination.svg";
import demoFancyMapStyles from "../styles/css/style.json";
import decodePolyline from "decode-google-map-polyline";
import { getLivelocation } from "../actions/assetAction";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCcugEZd84OFFpaKZP1Eo0uCMmc0mA8" +
      "bSk&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: (
      <div
        style={{
          height: `100vh`
        }}
      />
    ),
    containerElement: (
      <div
        style={{
          height: `100vh`
        }}
      />
    ),
    mapElement: (
      <div
        style={{
          height: `100%`
        }}
      />
    )
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultCenter={props.defaultCenter}
    defaultOptions={{
      styles: demoFancyMapStyles
    }}
    zoom={props.zoom}
    ref={props.reff}
    onZoomChanged={props.onZoomChanged}
  >
    {/* <DirectionsRenderer directions={props.directionss} /> */}
    <Marker position={props.sPosition} icon={Icon} onClick={props.onToggleOpen}>
      <InfoWindow>
        <div>
          {" "}
          Estimated time: {props.duration}
          <br />
          Distance : {props.distance}
        </div>
      </InfoWindow>
    </Marker>
    <Marker position={props.storeposition} icon={Icon1} />

    <Marker position={props.dPosition} icon={Icon1}>
      <InfoWindow>
        <div> Estimated time: {props.duration}</div>
      </InfoWindow>
    </Marker>
    <Polyline
      path={props.polycoordinates}
      geodesic={true}
      options={{
        strokeColor: "#000",
        strokeOpacity: 0.75,
        strokeWeight: 1.5,
        icons: [
          {
            icon: Icon1,
            offset: "0",
            repeat: "20px"
          }
        ]
      }}
    />
  </GoogleMap>
));

class tracking extends Component {
  state = {
    isMarkerShown: true,
    desLatLng: {
      lat: 13.01897,
      lng: 80.278
    },
    storepos: {
      lat: 13.02897,
      lng: 80.12
    },
    zoom: 16
  };

  componentDidMount() {
    // this.intervalId = setInterval(this.timer.bind(this), 5000);
  }

  componentWillReceiveProps(nextProps, prevProps) {

   
    if (nextProps.currentLatLng !== prevProps.currentLatLng) {
        console.log(this.props.currentLatLng);
      this.timer();
    }
  }

  componentWillMount() {
    // this.getGeoLocation();
    this.props.getLivelocation();
  }

  // onZoomChanged = () => {   this.setState({zoom: this.map.getZoom()});
  // setTimeout(() => {       this.setState({         zoom : 18       })     },
  // 5000)     }

  timer = () => {
    const DirectionsService = new google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: this.props.currentLatLng,
        destination: this.state.desLatLng,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          var polyline = result.routes[0].overview_polyline;
          var polylines = decodePolyline(polyline);
          // console.log(decodePolyline(polyline));
          this.setState({
            polycoordinatess: polylines,
            directionss: result,
            distance: result.routes[0].legs[0].distance.text,
            duration: result.routes[0].legs[0].duration.text
          });

          console.log(this.state.directionss);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  // getGeoLocation = () => {   if (navigator.geolocation) {
  // navigator.geolocation.getCurrentPosition(       position => {
  // this.setState({           currentLatLng: {               lat:
  // position.coords.latitude,               lng: position.coords.longitude
  //      }         })       }     )   } else {     // error => console.log(error)
  //   } }

  render() {
    console.log(this.props.currentLatLng);

    return (
      <MyMapComponent
        sPosition={this.props.currentLatLng}
        dPosition={this.state.desLatLng}
        storeposition={this.state.storepos}
        defaultCenter={this.state.desLatLng} // onZoomChanged = {this.onZoomChanged}
        zoom={this.state.zoom}
        reff={map => (this.map = map)}
        duration={this.state.duration}
        distance={this.state.distance}
        polycoordinates={this.state.polycoordinatess}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { currentLatLng: state.currentLatLng };
}

export default connect(
  mapStateToProps,
  { getLivelocation }
)(tracking);
