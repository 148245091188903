/* global google */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow
} from "react-google-maps";
import Icon from "../styles/img/delivery-bike.svg";
import Icon1 from "../styles/img/destination.svg";
import Shop from "../styles/img/shop.svg";
import { Link } from 'react-router-dom';
import demoFancyMapStyles from "../styles/css/style.json"
import decodePolyline from 'decode-google-map-polyline'
import {getLivelocation, OrderDetail} from '../actions/assetAction';
import {Button} from 'antd';




const MyMapComponent = compose(
  withProps({
    googleMapURL:
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyAaWzxDh_bPoKCS-Ajv0khh6vxRae91jNA&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: (
      <div
        style={{
          height: `80vh`
        }}
      />
    ),
    containerElement: (
      <div
        style={{
          height: `80vh`
        }}
      />
    ),
    mapElement: (
      <div
        style={{
          height: `100%`
        }}
      />
    )
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultCenter={props.defaultCenter}
    defaultOptions={{
      styles: demoFancyMapStyles
    }}
    zoom={props.zoom}
    ref={props.reff}
    onZoomChanged={props.onZoomChanged}
  >
    {/* <DirectionsRenderer directions={props.directionss} /> */}
    <Marker position={props.sPosition} icon={Icon} onClick={props.onToggleOpen}>
      <InfoWindow>
        <div>
          {" "}
          Estimated time: {props.duration}
          <br />
          Distance : {props.distance}
        </div>
      </InfoWindow>
    </Marker>
    <Marker position={props.storeposition} icon={Shop} />

    <Marker position={props.dPosition} icon={Icon1}>
      <InfoWindow>
        <div> Estimated time: {props.duration}</div>
      </InfoWindow>
    </Marker>
    <Polyline
      path={props.polycoordinates}
      geodesic={true}
      options={{
        strokeColor: "#000",
        strokeOpacity: 0.75,
        strokeWeight: 1.75,
        icons: [
          {
            icon: Icon1,
            offset: "0",
            repeat: "20px"
          }
        ]
      }}
    />
  </GoogleMap>
));


class Map extends Component {
  state = {
    isMarkerShown: true,
    currentLatLng: {
        lat: 13.02897,
        lng: 80.12000
      },
      desLatLng: {
        lat: 13.01897,
        lng: 80.27800
      },
      storepos:{
        lat: 13.02897,
        lng: 80.12000
      },
      zoom : 13,
      activepanel : 1,
      visible: false,
      directionvis: false,
      dellicon: false
  }

  componentDidMount() {
    // this.intervalId = setInterval(this.timer.bind(this), 5000);
  }

  componentWillReceiveProps(nextProps, prevProps) {

   
    if (nextProps.currentLatLng !== prevProps.currentLatLng) {
        console.log(this.props.currentLatLng);
      this.timer();
    }
  }

  componentWillMount(){
    // this.getGeoLocation();
    
    var orderid;
        if (this.props.location.orderid === undefined)
        {
          orderid = localStorage.getItem('drid');
            this.props.OrderDetail(orderid).then((result) => {
               this.setState({ desLatLng: result.store_loc});
                console.log(result);
                this.props.getLivelocation(orderid);
                
            }).catch((err) => {
                
            });
            console.log("if Block ", orderid);
        }
        else
        {
            localStorage.setItem('drid', this.props.location.orderid);
            orderid = localStorage.getItem('drid');
            this.props.OrderDetail(orderid).then((result) => {
              this.setState({ desLatLng: result.store_loc});
              console.log(result);
                this.props.getLivelocation(orderid);
            }).catch((err) => {
                
            });
            console.log(orderid);
        }
  }
 
 

  
    
  timer = () => {
    const DirectionsService = new google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: this.props.currentLatLng,
        destination: this.props.order.del_loc,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          var polyline = result.routes[0].overview_polyline;
          var polylines = decodePolyline(polyline);
          // console.log(decodePolyline(polyline));
          this.setState({
            polycoordinatess: polylines,
            directionss: result,
            distance: result.routes[0].legs[0].distance.text,
            duration: result.routes[0].legs[0].duration.text
          });

          console.log(this.state.directionss);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  

  render() {
            // console.log(this.props.currentLatLng);

    return (

      <div>
        <div className="container-fluid addp">
        
              <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
               <h4>Order Live Tracking</h4>
               </div>
               <div className="col-sm-8 text-right">
                <Link to="/order"><Button className="pull-right" type="dark" icon="arrow-left">Back To Order</Button></Link>
              </div>
              </div>
             
              </div>
              </div>
      <div className="container-fluid trackingdiv">
      <div className="row">
        <div className="col-xs-12">
    		<div className="invoice-title">
    			<h3>Order ID # {this.props.order.order_id}</h3>
    		</div>
    		<hr/>
    		<div className="row">
    			<div className="col-xs-6">
    				<address>
    				<strong>Customer information:</strong><br/>
            {this.props.order.customer_name}<br/>
            {this.props.order.cus_email}<br/>
            {this.props.order.cus_phone}
    				</address>
    			</div>
    			<div className="col-xs-6 text-right">
    				<address>
        			<strong>Delivered To:</strong><br/>
    				{this.props.order.del_address}
    				</address>
    			</div>
    		</div>
    		<div className="row">
    			<div className="col-xs-6">
    				<address>
    					<strong>Payment Method:</strong><br/>
    					Pay by Card<br/>
              {this.props.order.cus_email}
    				</address>
    			</div>
    			<div className="col-xs-6 text-right">
    				<address>
    					<strong>Order Date & Time:</strong><br/>
    					{this.props.order.order_date}<br/><br/>
    				</address>
    			</div>
    		</div>
    	</div>
    </div>

      <div className="row">
     
      <div className="col-md-12 trackingdiv">
      <MyMapComponent
        sPosition={this.props.currentLatLng}
        dPosition={this.props.order.del_loc}
        storeposition={this.state.desLatLng}
        defaultCenter={this.state.desLatLng} // onZoomChanged = {this.onZoomChanged}
        zoom={this.state.zoom}
        reff={map => (this.map = map)}
        duration={this.state.duration}
        distance={this.state.distance}
        polycoordinates={this.state.polycoordinatess}
      />
      </div>
      </div>
 
      
      
      </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentLatLng: state.currentLatLng,
    order: state.order
};
}


export default connect(mapStateToProps, {getLivelocation, OrderDetail})(Map);
