import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {updatestore, slottable,citytable,checkemail,StoreEdit, cityCheck,catList} from '../actions/assetAction';
import {Form, Input, Icon, Row,Radio,Drawer, Button, message, Col,Spin,Select,InputNumber,Popconfirm,Modal,Avatar} from 'antd';
import Map from './automap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import '../styles/css/App.css';



const { TextArea } = Input;
const { Option } = Select;

class Addstore extends Component {

    constructor(props) {
        super(props);
        // state
        this.state = {
          fileList1: "",
          filetag:1,
          iconLoading: false ,
          valstatus:'',
          place: [],
          visible: false,
          loading: true,
          Discounted:"",
          markedPrice:"",
          src: null,
          modal: false,
          croppedImage:"false",
          ImageUrl:'',
          crop: {
            unit: "px",
            width: 300,
            height: 300,
            aspect: 4 / 3
          }
          
        };

    }

    componentWillMount()
    {
      this.props.catList();
      this.props.slottable();
      this.props.citytable();
      var reqid;
      if (this.props.location.requestid === undefined)
      {
        reqid = localStorage.getItem('drid');
        console.log("if Block ", reqid);
        this.props.StoreEdit(reqid).then((result) => {
          this.setState({ loading: false, Discounted:this.props.storerequest.Stores_Master_Commission_Applicable});
      }).catch((err) => {
          
      });
      }
      else
      {
        localStorage.setItem('drid', this.props.location.requestid);
        reqid = localStorage.getItem('drid');
        this.props.StoreEdit(reqid).then((result) => {
          this.setState({ loading: false, Discounted:this.props.storerequest.Stores_Master_Commission_Applicable});
      }).catch((err) => {
          
      });
        console.log(reqid);
      }

    }  
    
    
    renderCategory()
    {
         return _.map(this.props.slot,(slot,key) => {
                return (
                  <Option value={slot.id} key={key}> 
                 
                  {slot.sname}
                  {/* ({categories.cid})  */}
                  </Option>
                );
              })
    }
    renderCategory1()
    {
         return _.map(this.props.common,(common,key) => {
                return (
                  <Option value={common.id} key={key}> 
                 
                  {common.cname}
                  {/* ({categories.cid})  */}
                  </Option>
                );
              })
    }
    renderCategory2()
    {
         return _.map(this.props.categories,(categories,key) => {
                return (
                  <Option value={categories.id} key={key}> 
                  <Avatar src={categories.imgUrl} />
                  {categories.cname}
                  {/* ({categories.cid})  */}
                  </Option>
                );
              })
    }
    checkAddress = (rule, value, callback) => { 
      console.log('changed', value);
       if (value === undefined)  
        {
        callback('Input store address ...!');
         return;
       }
       callback();
     }

     handleChange = (value) => {
  console.log(`selected ${value}`);
}

    handleReset = () => {
      this.props.form.resetFields();
      this.setState({
        iconLoading: false,
        fileList1: ""
      });	
    }
  

    handleSubmit = (e) => {
      e.preventDefault();
     

      this.props.form.validateFieldsAndScroll((err, values) => {
       console.log(values);
        if (!err) 
        {
          this.setState({
            iconLoading: true,
          });	
        var reqid = localStorage.getItem("drid");
        this.props.updatestore( values, this.state.NewCroppedFile, this.props.address, reqid)
        .then((result) => {

          this.setState({
            iconLoading: false,
          });	
          message.success("Store updated successfully!");
            setTimeout(() => {
               this.props.history.push("/viewstore");
            }, 2000);
          
        }).catch((err) => {
          
        });
            
          
    

        }
      });
    }

    normFile = e => {
      console.log("Upload event:", e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };

    updateData = (result) => {
      console.log(result);
      this.setState({place: result})
    }

    showDrawer = () => {
      this.setState({
        visible: true,
      });
    };
  
    onClose = () => {
      this.setState({
        visible: false,
      });
      this.props.cityCheck(this.props.address.city)
      .then((result) => {
         
        console.log(result);
        this.props.form.setFieldsValue({
          store_address: this.props.address.address,
          ref_city :result
        })
        
      }).catch((err) => {

        console.log(err);
        message.error('Currently Service not available in this city, Please contact system Administrator', 15);
        
      });
      
    };

    onTextChange = (val) => {
      var newVal = val;
      this.setState({val: newVal});
 }
 checkPrice = (rule, value, callback) => { 
  // console.log('changed', value);
   const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
   if ((!Number.isNaN(value) && reg.test(value)) || value === '')
    {
     callback();
     return;
   }
   callback('Input only a numermic value...!');
 }

 //========================= image crop config=============


 onSelectFile = e => {
  if (e.target.files && e.target.files.length > 0) {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
    this.setState({ src: reader.result, modal: true })
    );
    reader.readAsDataURL(e.target.files[0]);
  }
};

// If you setState the crop in here you should return false.
onImageLoaded = image => {
  this.imageRef = image;
};

onCropComplete = crop => {
  this.makeClientCrop(crop);
};

onCropChange = (crop, percentCrop) => {
  // You could also use percentCrop:
  // this.setState({ crop: percentCrop });
  this.setState({ crop , croppedImage:"false"});
};
cropImageAction = () =>
{
  this.setState({ src: null, modal: false, croppedImage: "true" });
}
async makeClientCrop(crop) {
  if (this.imageRef && crop.width && crop.height) {
    const croppedImageUrl = await this.getCroppedImg( this.imageRef, crop, "newFile.png");
    this.setState({ croppedImageUrl });
  }
}

getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    
    const myFilename = "previewFile.png"
    const base64Image = canvas.toDataURL('image/png');
    const myNewCroppedFile = this.base64StringtoFile(base64Image, myFilename)
    console.log(myNewCroppedFile)
    this.setState({ NewCroppedFile:myNewCroppedFile });
    resolve(base64Image);
  });
}
base64StringtoFile = (base64String, filename) => {
  var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {type: mime})
}
deleteImage = () => {
  this.setState({
      filetag:0
    });	
}
handleCancel = () => {
  this.setState({ src: null, modal: false });
  this.props.form.setFieldsValue({
    Store_Image_Url: ""
  });
};
handleDiscounted = (e) => {
  this.setState({
    Discounted: e.target.value,
  });
  console.log(this.state.Discounted)
}
markedPrice = (e) => {
     
  this.setState({
    markedPrice: e.target.value
  });
 
}
// ======================================================

    render() {

     
      const { crop, croppedImageUrl, ImageUrl, croppedImage, src } = this.state;
      
    
      const { getFieldDecorator } = this.props.form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };

      

//==============================================================================================================//
       
          return (
            <div>
              <div className="container-fluid addp">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-4">
                      <h4>Edit Store</h4>
                    </div>
                    <div className="col-sm-8 text-right">
                      <Link to="/viewstore">
                        <Button
                          className="pull-right"
                          type="dark"
                          icon="arrow-left"
                        >
                          Back To Store
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="container-fluid text-left addproduct">
              <Spin spinning={this.state.loading}>
                <Drawer
                  width={720}
                  onClose={this.onClose}
                  visible={this.state.visible}
                >
                  <Map
                    google={this.props.google}
                    center={{ lat: 18.5204, lng: 73.8567 }}
                    height="300px"
                    zoom={15}
                  />
                  <Button type="primary" className="m-t-100" size="large" htmlType="submit" onClick={this.onClose}>Submit</Button>
                </Drawer>

                <Row gutter={15}>
                  <Form onSubmit={this.handleSubmit}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <h4
                        className="formtitle"
                        style={{ marginTop: 0 }}
                      >
                        Store Information
                      </h4>
                    </Col>
                   
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Store Name"
                      >
                        {getFieldDecorator("Store_Name", {
                          initialValue: this.props.storerequest.Store_Name,
                          rules: [
                            {
                              required: true,
                              message: "Enter store name",
                              whitespace: false
                            }
                          ]
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    

                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Store address"
                      >
                        <Row gutter={8}>
                          <Col span={18}>
                            {getFieldDecorator("store_address", {
                              initialValue:this.props.storerequest.Store_Address,
                              rules: [
                                {
                                  required: true,
                                  validator: this.checkAddress
                                }
                              ]
                            })(<TextArea rows={3}  />)}
                          </Col>
                          <Col span={6}>
                            <Button
                              type="primary"
                              onClick={this.showDrawer}
                            >
                              <Icon type="global" /> Select Address
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Store Message"
                      >
                        {getFieldDecorator(
                          "Configuration_Message_One",
                          {
                            initialValue:this.props.storerequest.Configuration_Message_One,
                            rules: [
                              {
                                required: false,
                                message: "Enter Category Description",
                                whitespace: false
                              }
                            ]
                          }
                        )(<TextArea rows={3} />)}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Additional Store Message"
                      >
                        {getFieldDecorator(
                          "Configuration_Message_Two",
                          {
                            initialValue:this.props.storerequest.Configuration_Message_Two,
                            rules: [
                              {
                                required: false,
                                message: "Enter Category Description",
                                whitespace: false
                              }
                            ]
                          }
                        )(<TextArea rows={3} />)}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Operation Slot"
                      >
                        {getFieldDecorator("ref_slot", {
                            initialValue:this.props.storerequest.Ref_Slot_Time,
                          rules: [
                            {
                              required: true,
                              message: "select opeartion slot "
                            }
                          ]
                        })(
                          <Select
                            placeholder="Select any one slot"
                            onChange={this.handleChange}
                          >
                             <Option value="1">1 Hours</Option>
                        <Option value="2">2 Hours</Option>
                        <Option value="3">3 Hours</Option>
                        <Option value="4">4 Hours</Option>
                        <Option value="5">5 Hours</Option>
                        <Option value="6">6 Hours</Option>
                        <Option value="7">7 Hours</Option>
                        <Option value="8">8 Hours</Option>
                        <Option value="9">9 Hours</Option>
                        <Option value="10">10 Hours</Option>
                        <Option value="11">11 Hours</Option>
                        <Option value="12">12 Hours</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                    <Form.Item
                  {...formItemLayout}
                  label="Min Order Category List"
                >
                  {getFieldDecorator('Exclude_Min_Order_Category_List', {
                     initialValue:this.props.storerequest.Exclude_Min_Order_Category_List,
                    rules: [{ required: true, message: 'Select Category', type: 'array' }],
                  })(
                    <Select
                      placeholder="Select Category" mode="multiple"
                    >
                    {this.renderCategory2()}
                    </Select>
                  )}
                </Form.Item></Col>
                
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Operating City"
                      >
                        {getFieldDecorator("ref_city", {
                            initialValue:this.props.storerequest.Ref_Operating_City_Id,
                          rules: [
                            {
                              required: true,
                              message: "Select opearting city"
                            }
                          ]
                        })(
                          <Select disabled
                            onChange={this.handleChange}
                          >
                            {this.renderCategory1()}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                <Form.Item
                  {...formItemLayout}
                  label="18+ Category List"
                >
                  {getFieldDecorator('Category_18_List', {
                    initialValue:this.props.storerequest.Category_18_List,
                    rules: [{ required: true, message: 'Select Category', type: 'array' }],
                  })(
                    <Select
                      placeholder="Select Category" mode="multiple"
                    >
                    {this.renderCategory2()}
                    </Select>
                  )}
                </Form.Item></Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Minimum Order Amount"
                  >
                    {getFieldDecorator("Min_Order_Amount", {
                      initialValue:this.props.storerequest.Min_Order_Amount,
                      rules: [{ required: true, validator: this.checkPrice }]
                    })(<InputNumber style={{ width: 400 }} />)}
                  </Form.Item>
                </Col>
               
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 8}}>
                <Form.Item  {...formItemLayout}
                  label="Delivery Fee">
                  {getFieldDecorator('Delivery_Fee', {
                    initialValue:this.props.storerequest.Delivery_Fee,
                    rules: [
              {
                required: true,
                pattern: new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"),
                message: "Wrong format!"
              }
            ]
                  })(
                    <Input placeholder="00.00"  style={{ width: 400 }} onChange={this.markedPrice} />
                  )}
                </Form.Item></Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Maximum Replaceable Amount"
                      >
                        {getFieldDecorator("replaceable_amount", {
                            initialValue:this.props.storerequest.Maximum_Replaceable_Amount,
                          rules: [{ required: true, validator: this.checkPrice }],
                        })(<InputNumber style={{ width: 400}}/>)}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Store Status"
                      >
                        {getFieldDecorator("Store_Status", {
                            
                          initialValue: this.props.storerequest.Status,
                          rules: [
                            {
                              required: true,
                              message: "Select Store Status",
                              whitespace: false
                            }
                          ]
                        })(
                          <Radio.Group>
                            <Radio value="true">Active</Radio>
                            <Radio value="false">In-Active</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Col>
                    { this.state.filetag === 0 ? 
                    (         
                      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item
                    {...formItemLayout}
                    label="Store Image"
                    // extra="Category Image"
                  >
                    {getFieldDecorator("Store_Image_Url", {
                      initialValue: ImageUrl,
                      rules: [
                        {
                          required: true,
                          message: "Please upload store display image!"
                        }
                      ]
                    })(<input type="file" onChange={this.onSelectFile} />)}
                  </Form.Item>

                  <Row gutter={8}>
                    <Col span={18}>
                      <div>
                        {croppedImage === "true" ? (
                          <img
                            alt="Crop"
                            style={{ maxWidth: "100%" }}
                            src={croppedImageUrl}
                          />
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>) : 
                    (<Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                    
                    <Form.Item
                  {...formItemLayout}
                  label="Store Image"
                >
                <Row gutter={15}>
                <Col span={16}>
                 <img className="img-responsive" src={this.props.storerequest.Store_Logo} alt="" />
                 </Col>
                  <Col span={8}>
                  <Popconfirm title="Are you sure delete this Image, This will cause permanent loss of image ?" onConfirm={() => this.deleteImage()} onCancel={this.cancel} okText="Yes" cancelText="No">  <Button
                              type="danger"
                             
                            >
                              <Icon type="delete" /> 
                            </Button></Popconfirm>
                          </Col></Row>
                </Form.Item>
                    </Col>) }
                    <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
            <h4 className="formtitle" style={{ marginTop: 80}}>Commission Information</h4>
            </Col>
                 <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <Form.Item {...formItemLayout} label="Commission Applicable">
                    {getFieldDecorator("Stores_Master_Commission_Applicable", {
                      initialValue: "true",
                      rules: [
                        {
                          required: true,
                          message: "Store Available Status",
                          whitespace: false
                        }
                      ]
                    })(
                      <Radio.Group  onChange={this.handleDiscounted}>
                        <Radio value="true">Yes</Radio>
                        <Radio value="false">No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                {this.state.Discounted === "true" ? (
                <Col xs={{ span: 24}} sm={{span: 12}} lg={{ span: 12}}>
                <Form.Item
                  {...formItemLayout}
                  label="Commission %:"
                >
                  {getFieldDecorator('Stores_Commission_Percentage', {
                    
                    initialValue:this.props.storerequest.Stores_Commission_Percentage,
                   
                    rules: [{ required: true, validator: this.checkPrice }],
                  })(<InputNumber style={{ width: 300 }} />)}
                </Form.Item>
                </Col>) : null}
           
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <h4
                        className="formtitle"
                        style={{ marginTop: 80 }}
                      >
                        Store Manager Information
                      </h4>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Email ID"
                        hasFeedback 
                        validateStatus={this.state.valstatus}
                      >
                        {getFieldDecorator("Email_ID", {
                          initialValue: this.props.storerequest.Manager_Email_Address,
                          rules: [
                            {
                              required: true,
                              message: "Enter Email ID",
                              whitespace: false
                            }
                          ]
                        })(<Input id={this.state.valstatus} disabled/>)}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Name"
                      >
                        {getFieldDecorator("Name", {
                          initialValue: this.props.storerequest.Manager_Name,
                          rules: [
                            {
                              required: true,
                              message: "Enter name",
                              whitespace: false
                            }
                          ]
                        })(<Input  disabled/>)}
                      </Form.Item>
                    </Col>
                   
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      lg={{ span: 12 }}
                    >
                      <Form.Item
                        {...formItemLayout}
                        label="Phone Number"
                      >
                        {getFieldDecorator("phone", {
                          initialValue: this.props.storerequest.Manager_Mobile_Number,
                          rules: [{ required: true, validator: this.checkPrice }],
                        })(<Input disabled/>)}
                      </Form.Item>
                    </Col>

                    {/* =============================== Submit Button ======================================== */}

                    <Col xs={{ span: 24}} sm={{span: 24}} lg={{ span: 24}}>
        <Form.Item
          wrapperCol={{ span: 12}}
        >
          <Button type="primary" size="large" htmlType="submit" loading={this.state.iconLoading}>Submit</Button>
          <Button style={{ marginLeft: 15 }} size="large" onClick={this.handleReset}>
              Clear
            </Button>
        </Form.Item>
        </Col>
                  </Form>
                </Row>
                </Spin>
                <Modal
            title="Crop Store Image"
            visible={this.state.modal}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.cropImageAction}
              >
                <Icon type="scissor" /> Crop Image
              </Button>
            ]}
          >
            <Row gutter={8}>
              <Col span={24}>
                {src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </Col>
            </Row>
          </Modal>
              </div>
            </div>
          );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    user: state.user,
    address:state.address,
    slot: state.slot,
    common:state.common,
    storerequest:state.storerequest,
    categories: state.categories
  };
}

const WrappedRegistrationForm = Form.create()(Addstore);

export default connect(mapStateToProps, {updatestore, slottable, citytable,checkemail,StoreEdit, cityCheck,catList})(WrappedRegistrationForm);
