import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { emailLogin, logout } from '../actions/userAction';
import { saveAsset, productList, catList, subcatList, filterProduct, filtersubCat } from '../actions/assetAction';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { db } from '../firebase';
import { Icon, message, Popconfirm, Select,Avatar, Button, Input, Table, Modal,Switch, Tooltip,Divider,Tag,Typography  } from 'antd';
//import 'antd/dist/antd.css';
import '../styles/css/App.css';
import Highlighter from 'react-highlight-words';
import firebase from 'firebase/app';
import 'firebase/firestore';

const Option = Select.Option;
const { Text } = Typography;

class Products extends Component {

  constructor(props) {
    super(props);
    // state
    this.state = {
      // category: "",
      // subcategory: "",
      disabled: true,
      searchText: '',
      tdata: null,
      loading: true,
      mimage:''
    };

  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => { this.searchInput = node; }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
            </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
            </Button>
        </div>
      ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    console.log(selectedKeys);
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
    console.log(this.state.searchText);
  }


  confirm = (key) => {

    console.log(key);
    db.collection("Product_Details").doc(key).update({Is_Active : false,Updated_At: firebase
      .firestore
      .FieldValue
      .serverTimestamp()}).then(function () {
      message.success("Product successfully deleted!");
    }).catch(function (error) {
      message.error("Error removing document: ", error);
    });
    const tdata = [...this.state.tdata];
    this.setState({ tdata: tdata.filter(item => item.key !== key) });
  }

  cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }

  handleCategory = (value) => {
    this.setState({
      category: value,
      subcategory: "",
      disabled: false
    });
    this.props.filtersubCat(value);
  }

  handlesubCategory = (value) => {
    this.setState({
      subcategory: value,
      loading: true
    });
    var cat = this.state.category;
    var subcat = value;
    this.props.filterProduct(cat, subcat).then(res => {
      console.log(res);
      this.setState({
        tdata: this.props.products,
        loading: false
      });
    })
      .catch(error => {
        console.log(error);
      }
      )
  }

  handleResett = () => {
    //  componentWillMount
    this.setState({
      category: "",
      disabled: true,
      subcategory: "",
      loading: true
    });
    this.props.productList().then(res => {
      console.log(res);
      this.setState({
        tdata: this.props.products,
        loading: false
      });
    })
      .catch(error => {
        console.log(error);
      }
      )

  }
  
  
  componentDidMount() {
    
    this.props.productList().then(res => {
      console.log(res);
      this.setState({
        tdata: this.props.products,
        loading: false
      });
    })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false
        });
      }
      )
    this.props.catList();
    this.props.subcatList();

  }
  
  // ======================================================
  renderCategory() {
    return _.map(this.props.categories, (categories, key) => {
      return (
        <Option value={categories.id} key={key}>
          <Avatar src={categories.imgUrl} />
          {categories.cname}
          {/* ({categories.cid})  */}
        </Option>
      );
    })
  }
  renderSubCategory() {
    return _.map(this.props.subcategories, (subcategories, key) => {
      return (
        <Option value={subcategories.id} key={key}>
          <Avatar src={subcategories.imgUrl} />
          {subcategories.sname}
          {/* ({subcategories.sid})  */}
        </Option>
      );
    })
  }
  renderNotes() {

    return _.map(this.props.products, (products, key) => {

     
      return (

        <tr key={key}>
          <td>{products.imgUrl}</td>
          <td>{products.pid}</td>
          <td>{products.Product_Price}</td>
          <td>{products.pname}</td>
          <td>{products.catname}</td>
          <td>{products.subcatname}</td>
          <td>{products.isstock.toString()}</td>
          <td>{products.updated_at}</td>
        </tr>

      )
      
    });

  }
  
  handleMenuClick = (e) => {
    //message.info('Click on menu item.');
    console.log('click', e.key);
    this.props.history.push(e.key);
  }
  
  showModal = (e, data) => {

    console.log(data)

    this.setState({
      visible: true,
      mimage:data
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  
  onChange = (checked, key) => {
    console.log(`switch to ${checked}`);
    console.log(key);
    this.setState({
      loading: true
    });
    db.collection("Product_Details").doc(key).update({Is_Stock_Available : checked ,Updated_At: firebase
      .firestore
      .FieldValue
      .serverTimestamp()}).then(res => {

        if(checked === false)
        {
          // Add a new document in collection "cities"
          db.collection("Product_Out_Stock").doc(key).set({
            Ref_Product_Id: key,
            Updated_At: firebase.firestore.FieldValue.serverTimestamp(),
            Created_At: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(res => {
            console.log("Document successfully written!");
            this.props.productList().then(res => {
              //console.log(res);
              this.setState({
                tdata: this.props.products,
                loading: false
              });
              message.success("Product successfully Updated!");
            })
              .catch(error => {
                console.log(error);
                this.setState({
                  loading: false
                });
              })
          })
          .catch(error => {
            console.error("Error writing document: ", error);
          });

        }
        else
        {

          db.collection("Product_Out_Stock").doc(key).delete().then(res => {
            console.log("Document successfully deleted!");
            this.props.productList().then(res => {
              //console.log(res);
              this.setState({
                tdata: this.props.products,
                loading: false
              });
              message.success("Product successfully Updated!");
            })
              .catch(error => {
                console.log(error);
                this.setState({
                  loading: false
                });
              })
          
        }).catch(error =>{
            console.error("Error removing document: ", error);
        });

        }
        
      
    }).catch(error => {
      message.error("Error removing document: ", error);
    });
  }
  //===============================================

  render() {

    
    
    // console.log(this.props.products);
    const columns = [
      {
        title: 'Product Image',
        dataIndex: 'imgUrl',
        key: 'imgUrl',
        width: '10%',
        render: (text, record) => (
          <span>
            <Avatar shape="square" size={64} src={record.imgUrl} onClick={(e) => this.showModal(e, record.imgUrl)} />
          </span>
        ),

      },
     
      {
        title: 'ID',
        dataIndex: 'pid',
        key: 'pid',
        ...this.getColumnSearchProps('pid'),
      },
      {
        title: 'Price',
        dataIndex: 'Product_Price',
        key: 'Product_Price',
        width: '8%',
        
      }, {
        title: 'Name',
        dataIndex: 'pname',
        key: 'pname',
        ...this.getColumnSearchProps('pname'),
        render: (text, record) => (
          <span>
            
      <Text >{record.pname}</Text ><br /> 
      
     
      
            
           
          </span>
        ),
      },
      {
        title: 'Category',
        dataIndex: 'catname',
        key: 'catname',
        ...this.getColumnSearchProps('catname'),
      },
      {
        title: 'Sub-Category',
        dataIndex: 'subcatname',
        key: 'subcatname',
        ...this.getColumnSearchProps('subcatname'),
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        render: (text, record) => (
          <span>
            
            {record.Status === "true" ?
      <Tag color="green">Active</Tag>
      :<Tag color="volcano">In Active</Tag>
      }
            
           
          </span>
        ),
       
      },
      {
        title: 'Stock Status',
        dataIndex: 'stockstatus',
        key: 'stockstatus',
        render: (text, record) => (
          <span>
            {record.Status === "true" ?
            (<Switch defaultChecked={record.isstock} onChange={(e) => this.onChange(e, record.key)}
      checkedChildren={<Icon type="check" />}
      unCheckedChildren={<Icon type="close" />}
    />):( <Switch disabled defaultChecked={record.isstock} onChange={(e) => this.onChange(e, record.key)}
    checkedChildren={<Icon type="check" />}
    unCheckedChildren={<Icon type="close" />}
  />)}
            
           
          </span>
        ),
       
      },
      {
        title: 'Updated At',
        dataIndex: 'updated_at',
        key: 'updated_at',

      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '12%',
        render: (text, record) => (
            <span>
                <Link to={{ pathname: '/editproduct',  productid: record.key }}>
                    <Tooltip title="Edit Product">
                    <Button type="primary" shape="circle" icon="edit"/> 
                    </Tooltip>
                </Link>

               <Divider type="vertical" />

               <Popconfirm title="Are you sure delete this Product?" onConfirm={() => this.confirm(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
               <Button type="danger" shape="circle" icon="delete"/>
                </Popconfirm>
            </span>
          ),

      }
    ];



    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Non-Premier Products</h4>
              </div>
              <div className="col-sm-8 text-right">
              <Link to="/addproduct"><Button className="pull-right" type="primary" icon="shopping">Add Product</Button></Link>
              </div>
            </div>

          </div>
        </div>
        <div className="container-fluid allproduct">
        <div className="row ">
        <div className="text-left filterdiv col-md-4">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="text-left download-table-xls-button btn ant-btn-primary"
                table="table-to-xls"
                filename="Shopezy-Product-Report"
                sheet="tablexls"
                buttonText="Export Product Report"/></div>
          <div className="text-right filterdiv col-md-8">
            <span style={{ marginRight: 10 }}>Filter:</span>
            <Select
              showSearch
              placeholder="Category"
              optionFilterProp="children"
              onChange={this.handleCategory}
              value={this.state.category}
              filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
              style={{ width: 200 }}
            >
              {this.renderCategory()}
            </Select>
            <Select
              showSearch
              placeholder="Sub-Category"
              optionFilterProp="children"
              onChange={this.handlesubCategory}
              value={this.state.subcategory}
              disabled={this.state.disabled}
              filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
              style={{ width: 200, marginLeft: 10 }}
            >
              {this.renderSubCategory()}
            </Select>
            <Button style={{ marginLeft: 15 }} onClick={this.handleResett}>
              Clear
            </Button>

          </div>
          </div>
          <div>

<table className="hidden" id="table-to-xls">
  <thead>
    <tr>
      <th>img_url</th>
      <th>product_Id</th>
      <th>price</th>
      <th>name</th>
      <th>category</th>
      <th>subcategory</th>
      <th>stockstatus</th>
      <th>updated_at</th>
    </tr>
  </thead>
  <tbody>
    {this.renderNotes()}
  </tbody>
</table>
</div>
          <Table columns={columns} dataSource={this.state.tdata} loading={this.state.loading} bordered />
        </div>
        <Modal
                
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Close
            </Button>,
          ]}
        >
              <div className="center-item">
                <img src={this.state.mimage} className="img-responsive"  alt=""/>
                </div>
              </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    products: state.products,
    categories: state.categories,
    subcategories: state.subcategories,
  };
}


export default connect(mapStateToProps, { emailLogin, logout, saveAsset, productList, catList, subcatList, filterProduct, filtersubCat })(Products);
