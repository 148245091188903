import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  UpdatePromo,
  promotable,
  Singlepromo,
  currencyList
} from "../actions/assetAction";
import debounce from "lodash/debounce";
import _ from "lodash";
import { db } from "../firebase";
import request from "superagent";
import { Dev_URL } from "../actionTypes";
import {
  Form,
  Row,
  Button,
  message,
  Col,
  Card,
  Select,
  Spin,
  Input,
  DatePicker,
  Upload,
  Icon,
  Modal,
  Avatar,
  Tag,
  Empty,
  Popconfirm,
  InputNumber
} from "antd";
import moment from "moment";
import "../styles/css/App.css";

const { Option } = Select;
const { Meta } = Card;
const { TextArea } = Input;

let bucket_baseurl;

class EditPromo extends Component {
  constructor(props) {
    super(props);
    // state this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      symbol: "",
      fileList: [],
      prodList: [],
      singleprod: [],
      singleproddeal: [],
      proddeal: [],
      PromoTypeID: null,
      loading1: false,
      proddup: null,
      filetag: 1,
      promosid: "",
      visible: false,
      Display_Group_Id: "",
      validateStatus: ""
    };
  }

  componentDidMount() {
    db.collection("Constants").get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          bucket_baseurl = doc.data().Bucket_Base_Url;
      });
  });
    this.setState({ loading1: true });
    this.props.promotable();
    this.props.currencyList();
    var reqid;
    var promosid;
    if (this.props.location.promoid === undefined) {
      reqid = localStorage.getItem("drid");
      promosid = localStorage.getItem("psid");
      this.props
        .Singlepromo(reqid)
        .then(result => {
          console.log(result);
          this.setState({
            promosid: promosid,
            loading1: false,
            PromoTypeID: result.Ref_Promo_Type_Id,
            startValue: result.From_Date,
            singleprod: result.promo_group,
            proddeal: result.Product_Group,
            symbol: result.symbol,
            fileList: result.Promo_Images,
            Display_Group_Id: result.Product_Group.length + 1
          });
          this.props.form.setFieldsValue({
            promo_type: result.Ref_Promo_Type_Id,
            Ref_Currency_Id: result.Ref_Currency_Id,
            dragger: result.Promo_Images
          });
        })
        .catch(err => {});
      console.log(reqid, promosid);
    } else {
      localStorage.setItem("drid", this.props.location.promoid);
      localStorage.setItem("psid", this.props.location.Promo_Status);
      reqid = localStorage.getItem("drid");
      promosid = localStorage.getItem("psid");
      this.props
        .Singlepromo(reqid)
        .then(result => {
          this.setState({
            promosid: promosid,
            loading1: false,
            PromoTypeID: result.Ref_Promo_Type_Id,
            startValue: result.From_Date,
            singleprod: result.promo_group,
            proddeal: result.Product_Group,
            symbol: result.symbol,
            fileList: result.Promo_Images,
            Display_Group_Id: result.Product_Group.length + 1
          });
          this.props.form.setFieldsValue({
            promo_type: result.Ref_Promo_Type_Id,
            Ref_Currency_Id: result.Ref_Currency_Id,
            dragger: result.Promo_Images
          });
        })
        .catch(err => {});
      console.log(reqid, promosid);
    }
  }

  fetchUser = async value => {
    //console.log('fetching user', value.length);
    if (value.length > 3) {
      this.setState({ data: [], fetching: true });
      try {
        const res = await request
          .post(Dev_URL + "/searchproduct")
          .send({ PromoTypeID: this.state.PromoTypeID, ProductText: value });

        // res.body, res.headers, res.status
        console.log(res.body);
        if (res.body.ReturnCode === 201) {
          this.setState({ fetching: false });
        } else {
          const data = res.body.Product_Details.map(user => ({
            text: `${user.Ref_Products_Display_Name}`,
            value: user.Product_Id,
            proimg: bucket_baseurl+user.Ref_Products_Image_Url[0],
            Promo_Exists_Already: user.Promo_Exists_Already
          }));
          this.setState({
            data,
            fetching: false,
            prodList: res.body.Product_Details
          });
        }
      } catch (err) {
        return err;
      }
    }
  };

  handleCurrencyChange = value => {
    db.collection("MD_Currency")
      .doc(value)
      .onSnapshot(doc => {
        console.log(doc.data().Currency_Symbol);
        this.setState({
          symbol: doc.data().Currency_Symbol
        });
      });
  };

  handlesearchproduct = value => {
    console.log(value.key, this.state.prodList);
    const productlist = this.state.prodList;
    const productlist1 = this.state.singleproddeal;
    var selected_Product =
      productlist[
        productlist
          .map(function(item) {
            return item.Product_Id;
          })
          .indexOf(value.key)
      ];
    var dup_Product =
      productlist1[
        productlist1
          .map(function(item) {
            return item.Key;
          })
          .indexOf(value.key)
      ];
    console.log(dup_Product);

    if (dup_Product !== undefined) {
      message.error("Product Already Selected..!");
    } else {
      var productarr = this.state.singleproddeal;
      productarr.push(selected_Product);

      this.setState({ fetching: false, singleproddeal: productarr });
    }
  };

  disabledStartDate = startValue => {
    return startValue && startValue < moment().startOf("day");
  };

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  onStartChange = value => {
    this.onChange("startValue", value);
  };

  onEndChange = value => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  renderCategory1() {
    return _.map(this.props.common, (common, key) => {
      return (
        <Option value={common.Promotion_Id} key={key}>
          {common.promoname}
        </Option>
      );
    });
  }

  renderCurrency() {
    return _.map(this.props.currency, (currency, key) => {
      return (
        <Option value={currency.id} key={key}>
          {currency.symbol}- {currency.name}- ({currency.code})
        </Option>
      );
    });
  }

  handlePromo = value => {
    console.log(`selected ${value}`);
    this.setState({ PromoTypeID: value });
    this.props.form.resetFields();
    this.setState({
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      fileList: [],
      prodList: [],
      singleprod: []
    });
  };

  handledealDeleteProduct = key => {
    // console.log(key);
    var singleproddeal = [...this.state.singleproddeal];
    //console.log(singleproddeal);
    this.setState({
      singleproddeal: singleproddeal.filter(item => item.Key !== key)
    });
  };

  normFile = e => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      data: [],
      value: [],
      fetching: false,
      fileList1: "",
      fileList: [],
      prodList: [],
      singleprod: [],
      PromoTypeID: null,
      symbol: ""
    });
  };
  handledealReset = () => {
    this.props.form.resetFields([
      "Group_Name",
      "Min_Selection_Count",
      "Max_Selection_Count",
      "product"
    ]);
    this.setState({ singleproddeal: [] });
  };
  editgroup = p => {
    console.log(p);
    this.setState({ visible: true, singleproddeal: p.Product_Detail });
    this.props.form.setFieldsValue({
      Group_Name: p.Group_Name,
      Min_Selection_Count: p.Min_Selection_Count.toString(),
      Max_Selection_Count: p.Max_Selection_Count.toString(),
      product1: [],
      Display_Group_Id: p.Display_Group_Id
    });
  };
  deletegroup = p => {
    console.log(p);
    var array = this.state.proddeal;
    var evens = _.remove(array, function(n, i) {
      console.log(array, n);
      return n.Group_Name === p.Group_Name;
    });
    console.log(array, evens);
    this.setState({ proddeal: array });
  };
  setCount() {
    console.log("setcount");
    this.setState(state => ({
      Display_Group_Id: state.Display_Group_Id + 1
    }));
    this.props.form.setFieldsValue({
      Display_Group_Id: this.state.Display_Group_Id
    });
  }
  DecreasesetCount() {
    console.log("DecreasesetCount");
    this.setState(state => ({
      Display_Group_Id: state.Display_Group_Id - 1
    }));
  }

  handlegroupname = e => {
    console.log(e.target.value);
    this.setState({ inputValue: e.target.value });
  };

  checkgroupname = value => {
    //console.log(value)
    var prodeal = this.state.proddeal;
    var uniquename = _.find(prodeal, function(o) {
      return o.Group_Name === value;
    });
    console.log(uniquename);
    if (uniquename !== undefined) {
      this.setState({ validateStatus: "error" });
    } else {
      this.setState({ validateStatus: "" });
    }
  };
  handleSubmit1 = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      [
        "Group_Name",
        "Min_Selection_Count",
        "Max_Selection_Count",
        "product1",
        "Display_Group_Id"
      ],
      (err, values) => {
        if (!err) {
          // console.log(values);
          var prodeal = this.state.proddeal;

          console.log(prodeal);
          var dup_Product =
            prodeal[
              prodeal
                .map(function(item) {
                  return item.Group_Name;
                })
                .indexOf(values.Group_Name)
            ];
          //console.log(dup_Product);
          if (dup_Product !== undefined) {
            var editindex = _.findIndex(prodeal, function(o) {
              return o.Group_Name === values.Group_Name;
            });
            console.log(editindex);
            prodeal[editindex].Max_Selection_Count = parseInt(
              values.Max_Selection_Count
            );
            prodeal[editindex].Min_Selection_Count = parseInt(
              values.Min_Selection_Count
            );
            prodeal[editindex].Group_Name = values.Group_Name;
            prodeal[editindex].Product_Detail = this.state.singleproddeal;
            prodeal[editindex].Display_Group_Id = values.Display_Group_Id;
            //  console.log(prodeal);
            this.setState({
              proddeal: prodeal,
              dealpro: prodeal,
              visible: false,
              singleproddeal: []
            });
            console.log(this.state.proddeal);
            message.success("Product group Edited Successfully..!");
            this.props.form.resetFields([
              "Group_Name",
              "Min_Selection_Count",
              "Max_Selection_Count",
              "product1"
            ]);
          } else {
            prodeal.push({
              Max_Selection_Count: parseInt(values.Max_Selection_Count),
              Min_Selection_Count: parseInt(values.Min_Selection_Count),
              Group_Name: values.Group_Name,
              Selection_Count: 1,
              Product_Detail: this.state.singleproddeal,
              Display_Group_Id: values.Display_Group_Id
            });

            this.setState({
              proddeal: prodeal,
              visible: false,
              singleproddeal: []
            });
            console.log(this.state.proddeal);
            this.props.form.resetFields([
              "Group_Name",
              "Min_Selection_Count",
              "Max_Selection_Count",
              "product1"
            ]);
          }
        }
      }
    );
  };
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      [
        "promo_type",
        "Ref_Currency_Id",
        "promo_price",
        "Promo_display_name",
        "Promo_Description",
        "activation_date",
        "expiration_date",
        "banner_Url",
        "dragger",
        "product",
        "promo_title"
      ],
      (err, values) => {
        if (!err) {
          var promoid = localStorage.getItem("drid");
          // console.log(values) console.log(this.state.fileList)
          // console.log(this.state.fileList1) console.log(this.state.singleprod)
          this.setState({ iconLoading: true, loading1: true });
          this.props
            .UpdatePromo(
              values,
              this.state.fileList,
              this.state.fileList1,
              this.state.singleprod,
              this.props.promo,
              promoid,
              this.state.proddeal
            )
            .then(result => {
              this.setState({ iconLoading: false });

              this.props.form.resetFields();
              this.setState({
                data: [],
                value: [],
                fetching: false,
                fileList1: "",
                fileList: [],
                prodList: [],
                singleprod: [],
                PromoTypeID: null,
                loading1: false,
                symbol: ""
              });
              message.success("Promo updated successfully!");
              setTimeout(() => {
                this.props.history.push("/premierpromo");
              }, 3000);
            })
            .catch(err => {
              this.setState({ iconLoading: false, loading1: false });
              message.error(err.message);
            });
        }
      }
    );
  };
  deleteImage = () => {
    this.setState({ filetag: 0 });
  };
  updatefilelist = d => {
    console.log(d);
    this.props.form.setFieldsValue({ dragger: d });
  };
  //==================== Dynamic Field Settings =====================
  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  showModal = () => {
    this.props.form.resetFields([
      "Group_Name",
      "Min_Selection_Count",
      "Max_Selection_Count",
      "product1"
    ]);
    this.setState({ visible: true, singleproddeal: [] });
    this.props.form.setFieldsValue({
      Display_Group_Id: this.state.Display_Group_Id
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({ visible: false });
  };
  handleCancel = e => {
    console.log(e);
    this.setState({ visible: false });
  };
  // ======================================================
  // ======================================================

  render() {
    const { endOpen, fileList1, fileList } = this.state;
    const { proddeal, singleproddeal, data, fetching } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 24
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 20
        }
      }
    };
    const propsthumb = {
      multiple: false,
      listType: "picture-card",
      accept: ".png,.jpg",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true
      },
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList1.indexOf(file);
          const newFileList = state.fileList1.slice();
          newFileList.splice(index, 1);
          return { fileList1: newFileList };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList1: [...state.fileList1, file]
        }));
        return false;
      },
      fileList1
    };
    const props = {
      multiple: true,
      accept: ".png,.jpg",
      listType: "picture",
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          this.updatefilelist(newFileList);
          return { fileList: newFileList };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file]
        }));
        return false;
      },
      fileList
    };

    //==============================================================================
    //================================//

    return (
      <div>
        <Spin size="large" spinning={this.state.loading1}>
          <div className="container-fluid addp">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-4">
                  <h4>View Promo</h4>
                </div>
                <div className="col-sm-8 text-right">
                  <Link to="/premierpromo">
                    <Button
                      className="pull-right"
                      type="dark"
                      icon="arrow-left"
                    >
                      Back
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid text-left addproduct">
            <Form onSubmit={this.handleSubmit}>
              <Row gutter={15}>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  <Form.Item {...formItemLayout} label="Promo Type">
                    {getFieldDecorator("promo_type", {
                      rules: [
                        {
                          required: true,
                          message: "Please Select Promo Type",
                          whitespace: false
                        }
                      ]
                    })(
                      <Select
                        disabled
                        onChange={this.handlePromo}
                        placeholder="Select Promo Type"
                      >
                        {this.renderCategory1()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
               
              </Row>

              <Row gutter={15}>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 24
                  }}
                  lg={{
                    span: 24
                  }}
                >
                  <h4
                    className="formtitle"
                    style={{
                      marginTop: 50
                    }}
                  >
                    Promo Info
                  </h4>
                </Col>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  <Form.Item {...formItemLayout} label="Currency">
                    {getFieldDecorator("Ref_Currency_Id", {
                      rules: [
                        {
                          required: true,
                          message: "Enter Currency",
                          whitespace: false
                        }
                      ]
                    })(
                      <Select
                        placeholder="Select Currency type"
                        onChange={this.handleCurrencyChange}
                      >
                        {this.renderCurrency()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  <Form.Item {...formItemLayout} label="Promo Price">
                    {getFieldDecorator("promo_price", {
                      initialValue: this.props.promo.Promo_Price,

                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                          ),
                          message: "Currency format wrong!"
                        }
                      ]
                    })(
                      <Input placeholder="00.00" prefix={this.state.symbol} />
                    )}
                  </Form.Item>
                </Col>

                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  <Form.Item {...formItemLayout} label="Promo Title">
                    {getFieldDecorator("promo_title", {
                      initialValue: this.props.promo.Promo_Title,
                      rules: [
                        {
                          required: true,
                          message: "Promo title should not be empty",
                          whitespace: false
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  <Form.Item {...formItemLayout} label="Promo Description">
                    {getFieldDecorator("Promo_Description", {
                      initialValue: this.props.promo.Promo_Description,
                      rules: [
                        {
                          required: true,
                          message: "Enter Promo Description",
                          whitespace: false
                        }
                      ]
                    })(<TextArea rows={5} />)}
                  </Form.Item>
                </Col>

                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  <Form.Item {...formItemLayout} label="Activation Date">
                    {getFieldDecorator("activation_date", {
                      initialValue: this.props.promo.From_Date,
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "Enter Activation Date"
                        }
                      ]
                    })(
                      <DatePicker
                        style={{
                          width: 350
                        }}
                        disabledDate={this.disabledStartDate}
                        format="DD-MM-YYYY"
                        placeholder="Activation Date"
                        onChange={this.onStartChange}
                        onOpenChange={this.handleStartOpenChange}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  <Form.Item {...formItemLayout} label="Expiration Date">
                    {getFieldDecorator("expiration_date", {
                      initialValue: this.props.promo.To_Date,
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "Enter Expiration Date"
                        }
                      ]
                    })(
                      <DatePicker
                        style={{
                          width: 350
                        }}
                        disabledDate={this.disabledEndDate}
                        format="DD-MM-YYYY"
                        placeholder="Expiration Date"
                        onChange={this.onEndChange}
                        open={endOpen}
                        onOpenChange={this.handleEndOpenChange}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  <Form.Item
                    {...formItemLayout}
                    label="Promo Images"
                    extra="Additional Promotion Images"
                  >
                    <div className="dropbox">
                      {getFieldDecorator("dragger", {
                        rules: [
                          {
                            required: true,
                            message: "Please upload promotion images"
                          }
                        ]
                      })(
                        <Upload.Dragger
                          name="Product_Images"
                          {...props}
                          listType="picture"
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                          </p>
                        </Upload.Dragger>
                      )}
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 12
                  }}
                >
                  {this.state.filetag === 0 ? (
                    <Form.Item
                      {...formItemLayout}
                      label="Banner Image" // extra="Category Image"
                    >
                      {getFieldDecorator("banner_Url", {
                        valuePropName: "fileList",
                        getValueFromEvent: this.normFile,
                        rules: [
                          {
                            required: true,
                            message: "Please Upload Banner Display Image!"
                          }
                        ]
                      })(
                        <Upload name="Category_Image_Url" {...propsthumb}>
                          {fileList1.length === 1 ? null : (
                            <div>
                              <Icon type="plus" />
                              <div className="ant-upload-text">Choose</div>
                            </div>
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                  ) : (
                    <Form.Item {...formItemLayout} label="Banner Image">
                      <Row gutter={15}>
                        <Col span={24}>
                          <Card
                            style={{
                              width: 240
                            }}
                            cover={
                              <img
                                alt="example"
                                src={this.props.promo.Banner_image}
                              />
                            }
                            actions={[
                              <Popconfirm
                                title="Are you sure delete this Image, This will cause permanent loss of image ?"
                                onConfirm={() => this.deleteImage()}
                                onCancel={this.cancel}
                                okText="Yes"
                                cancelText="No"
                              >
                                {" "}
                                <Button type="danger">
                                  {" "}
                                  <Icon type="delete" />{" "}
                                </Button>{" "}
                              </Popconfirm>
                            ]}
                          ></Card>
                        </Col>
                      </Row>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={15}>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 24
                  }}
                  lg={{
                    span: 24
                  }}
                >
                  <h4
                    className="formtitle"
                    style={{
                      marginTop: 50
                    }}
                  >
                    Group Info
                  </h4>
                </Col>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 24
                  }}
                  lg={{
                    span: 24
                  }}
                >
                  {proddeal.length !== 0 ? (
                    <div>
                      {proddeal.map(d => (
                        <Card key={d.Key} className="productgroup">
                          <Row gutter={15}>
                            <Col
                              xs={{
                                span: 24
                              }}
                              sm={{
                                span: 8
                              }}
                              lg={{
                                span: 8
                              }}
                            >
                              <h6>Group name</h6>
                              <h5 className="resulttitle">{d.Group_Name}</h5>
                            </Col>
                            <Col
                              xs={{
                                span: 24
                              }}
                              sm={{
                                span: 8
                              }}
                              lg={{
                                span: 8
                              }}
                            >
                              <h6>Min buy quantity</h6>
                              <h5 className="resulttitle">
                                {d.Min_Selection_Count}
                              </h5>
                            </Col>
                            <Col
                              xs={{
                                span: 24
                              }}
                              sm={{
                                span: 8
                              }}
                              lg={{
                                span: 8
                              }}
                            >
                              <h6>Max buy quantity</h6>
                              <h5 className="resulttitle">
                                {d.Max_Selection_Count}
                              </h5>
                            </Col>
                          </Row>
                          <Row gutter={15}>
                            <h6>Selected products</h6>
                            {d.Product_Detail.map(p => (
                              <Col
                                key={p.Key}
                                xs={{
                                  span: 24
                                }}
                                sm={{
                                  span: 8
                                }}
                                lg={{
                                  span: 4
                                }}
                              >
                                <Card
                                  cover={
                                    <img
                                      alt="example"
                                      src={bucket_baseurl+p.Ref_Products_Image_Url[0]}
                                    />
                                  }
                                >
                                  <Meta title={p.Ref_Products_Display_Name} />
                                </Card>
                              </Col>
                            ))}
                          </Row>
                          <hr />
                          <Row gutter={15}>
                            <Col className="text-right">
                              <Button onClick={() => this.editgroup(d)}>
                                <Icon type="edit" />
                                Edit
                              </Button>
                              <Button
                                type="danger"
                                ghost
                                onClick={() => this.deletegroup(d)}
                              >
                                <Icon type="delete" />
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </div>
                  ) : null}
                </Col>
                <Col
                  xs={{
                    span: 24
                  }}
                  sm={{
                    span: 12
                  }}
                  lg={{
                    span: 8
                  }}
                >
                  {/* <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                  <Icon type="plus" /> Add Group
                </Button> */}
                  <Button type="dashed" block onClick={this.showModal}>
                    <Icon type="plus" />
                    Add Group
                  </Button>
                </Col>
              </Row>

              {this.state.promosid === "1" ? null : (
                <Row gutter={15} className="m-t-100">
                  <Col
                    xs={{
                      span: 24
                    }}
                    sm={{
                      span: 24
                    }}
                    lg={{
                      span: 24
                    }}
                  >
                    <Form.Item
                      wrapperCol={{
                        span: 12
                      }}
                    >
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={this.state.iconLoading}
                      >
                        Submit
                      </Button>
                      <Button
                        style={{
                          marginLeft: 15
                        }}
                        size="large"
                        onClick={this.handleReset}
                      >
                        Clear
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Spin>
        <Modal
          title="Add Group"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          style={{
            top: 20
          }}
          width={1000}
        >
          <Form onSubmit={this.handleSubmit1}>
            <Row gutter={15}>
              <Col
                className="hidden-visible"
                xs={{
                  span: 24
                }}
                sm={{
                  span: 24
                }}
                lg={{
                  span: 24
                }}
              >
                <Form.Item {...formItemLayout}>
                  {getFieldDecorator("Display_Group_Id", {
                    rules: [
                      {
                        required: true,
                        pattern: new RegExp(
                          "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
                        ),
                        message: "Display_Group_Id format wrong!"
                      }
                    ]
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col
                xs={{
                  span: 24
                }}
                sm={{
                  span: 24
                }}
                lg={{
                  span: 8
                }}
              >
                <Form.Item
                  {...formItemLayout}
                  label="Group Title"
                  hasFeedback
                  validateStatus={this.state.validateStatus}
                  help={
                    this.state.validateStatus === "error"
                      ? "Group Title already exist another group, please try with new name"
                      : null
                  }
                >
                  {getFieldDecorator("Group_Name", {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Enter Promo Group Title"
                      }
                    ]
                  })(
                    <Input
                      id={
                        this.state.validateStatus === "error" ? "error" : null
                      }
                      onChange={e => this.handlegroupname(e)}
                      onBlur={() => this.checkgroupname(this.state.inputValue)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col
                xs={{
                  span: 24
                }}
                sm={{
                  span: 24
                }}
                lg={{
                  span: 8
                }}
              >
                <Form.Item {...formItemLayout} label="Minimum Selection Count">
                  {getFieldDecorator("Min_Selection_Count", {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Minimum Selection Count Should not be empty"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col
                xs={{
                  span: 24
                }}
                sm={{
                  span: 24
                }}
                lg={{
                  span: 8
                }}
              >
                <Form.Item {...formItemLayout} label="Maximum Selection Count">
                  {getFieldDecorator("Max_Selection_Count", {
                    validateTrigger: ["onChange", "onBlur"],
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: "Maximum Selection Count Should not be empty"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col
                xs={{
                  span: 24
                }}
                sm={{
                  span: 24
                }}
                lg={{
                  span: 24
                }}
              >
                <Form.Item {...formItemLayout} label="Search Product">
                  {getFieldDecorator("product1", {
                    rules: [
                      {
                        required: false,
                        message: "Select atleast one product to create promo"
                      }
                    ]
                  })(
                    <Select // mode="multiple"
                      showSearch
                      labelInValue
                      placeholder="Type 4 characters to search..!"
                      notFoundContent={
                        fetching ? <Spin size="small" /> : <Empty />
                      }
                      filterOption={false}
                      onSearch={this.fetchUser}
                      defaultActiveFirstOption={false}
                      onChange={this.handlesearchproduct}
                      style={{
                        width: "100%"
                      }}
                    >
                      {data.map(d => (
                        <Option
                          key={d.value}
                          disabled={
                            d.Promo_Exists_Already === true ? true : false
                          }
                        >
                          <Avatar shape="square" src={d.proimg} /> {d.text}
                          <Tag
                            style={{
                              float: "right"
                            }}
                            visible={
                              d.Promo_Exists_Already === true ? true : false
                            }
                            color="volcano"
                          >
                            Promo Already Applied
                          </Tag>
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {singleproddeal.length !== 0 ? (
                <div>
                  <h5 className="resulttitle">Selected Product</h5>
                  <Row gutter={15} className="selectedprodmodal">
                    {singleproddeal.map((d, j) => (
                      <Col
                        key={d.Key}
                        xs={{
                          span: 24
                        }}
                        sm={{
                          span: 4
                        }}
                        lg={{
                          span: 4
                        }}
                      >
                        <Card
                          cover={
                            <img
                              alt="example"
                              src={d.Ref_Products_Image_Url[0]}
                            />
                          }
                          actions={[
                            <Icon
                              type="delete"
                              onClick={() =>
                                this.handledealDeleteProduct(d.Key)
                              }
                              key="delete"
                            />
                          ]}
                        >
                          <Meta title={d.Ref_Products_Display_Name} />
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : null}
            </Row>
            <Row gutter={15} className="m-t-100">
              <Col
                xs={{
                  span: 24
                }}
                sm={{
                  span: 24
                }}
                lg={{
                  span: 24
                }}
              >
                <Form.Item
                  wrapperCol={{
                    span: 12
                  }}
                >
                  <Button
                    disabled={
                      this.state.validateStatus === "error" ? true : false
                    }
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={this.state.iconLoading}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      marginLeft: 15
                    }}
                    size="large"
                    onClick={this.handledealReset}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    common: state.common,
    currency: state.currency,
    promo: state.promo
  };
}

const WrappedRegistrationForm = Form.create()(EditPromo);

export default connect(mapStateToProps, {
  UpdatePromo,
  promotable,
  Singlepromo,
  currencyList
})(WrappedRegistrationForm);
