import React, {Component} from 'react';
import {connect} from 'react-redux';
import {emailLogin, logout} from '../actions/userAction';
import {customeallorder} from '../actions/assetAction';
import {Link} from 'react-router-dom';
import {
  Icon,
  Tooltip,
  Button,
  Input,
  Tag,
  Skeleton,
  Col,
  Row,
  Table,Divider,message,DatePicker,Form,notification
} from 'antd';
import '../styles/css/App.css';
import Highlighter from 'react-highlight-words';
import {TimeAgo} from "@n1ru4l/react-time-ago";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import _ from 'lodash';
import StatCard from './templates/StatCard';
import { ShoppingCart, Target} from 'react-feather';
import {customdashreport,orderlive} from '../actions/dashboardAction';
//import moment from 'moment';
import { db } from '../firebase'
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import UIfx from 'uifx'; 
import beep from '../styles/audio/there-you-go.mp3';
const tick = new UIfx(beep);

const openNotificationWithIcon = () => {
  notification["success"]({
    message: 'New Order Placed',
    duration: 0,
  });
};
class Order extends Component {

  constructor(props) {
    super(props);
    // state
    this.state = {
      disabled: true,
      searchText: '',
      tdata: null,
      loading: true,
      dashstats:"",
      startValue: null,
      endValue: null,
      endOpen: false,
      visible:false,
      volume:0,
    };

  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{
        padding: 8
      }}>
        <Input
          ref={node => {
          this.searchInput = node;
        }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value
          ? [e.target.value]
          : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
          width: 188,
          marginBottom: 8,
          display: 'block'
        }}/>
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
          width: 90,
          marginRight: 8
        }}>
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{
          width: 90
        }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon
      type="search"
      style={{
      color: filtered
        ? '#1890ff'
        : undefined
    }}/>,
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (<Highlighter
      highlightStyle={{
      backgroundColor: '#ffc069',
      padding: 0
    }}
      searchWords={[this.state.searchText]}
      autoEscape
      textToHighlight={text.toString()}/>)
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({searchText: selectedKeys[0]});
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({searchText: ''});
    console.log(this.state.searchText);
  }

  componentDidMount()
  {
   const today = moment();
   const from_date = today.startOf('day').format(); 
   const to_date = today.endOf('end').format(); 

    //  componentWillMount
    this.props.customdashreport(from_date,to_date).then((result) =>{
      console.log('resolved',this.props.dashtoday)
      this.setState({dashstats:this.props.dashtoday});
      }).catch(error => {
      console.log("error");
        this.setState({loading: false});
      })
    this.props.customeallorder(from_date,to_date)
      .then(res => {
        console.log(res);
        this.setState({tdata: this.props.order, loading: false,visible:true});
      })
      .catch(error => {
      console.log("error");
        this.setState({loading: false});
      })
	 this.props.orderlive(from_date,to_date);
   setTimeout(() => {
    this.setState({volume: 1});
  }, 7000)
  }
  componentDidUpdate(prevProps) {
       
    if(this.props.dashtoday !== prevProps.dashtoday) {
      console.log("Did Update new")
      this.setState({ dashstats:this.props.dashtoday });
     
    }
    
  }  
  componentWillReceiveProps(nextProps)
  {
    if(this.props.common !== nextProps.common ) 
    {
      if(parseInt(this.props.common) !== 0)
      {
        console.log("will receive");
        tick.setVolume(this.state.volume).play();
        if(this.state.volume === 1)
        {
          openNotificationWithIcon();
        }
       
      }
      
    }
  } 
  handleResett = () => {
    this.props.form.resetFields();    
    const initialDate = new Date();
    const today = moment();
   const from_date = today.startOf('day').format(); 
   const to_date = today.endOf('end').format(); 

    this.setState({loading: true});
    this.props.customdashreport(from_date,to_date).then((result) =>{
      console.log('resolved',this.props.dashtoday)
      this.setState({dashstats:this.props.dashtoday});
      }).catch(error => {
      console.log("error");
        this.setState({loading: false});
      })
    this.props.customeallorder(from_date,to_date)
      .then(res => {
        console.log(res);
        this.setState({tdata: this.props.order, loading: false,date: initialDate});
      })
      .catch(error => {
        console.log(error);
        this.setState({loading: false});
      })

  }

  renderNotes() {

    return _.map(this.props.order, (order, key) => {

      return (

        <tr key={key}>
          <td>{order.order_id}</td>
          <td>{order.customer_name}</td>
          <td>{order.delivery_address}</td>
          <td>{order.store_name}</td>
          <td>{order.order_status}</td>
          <td>{order.Total_Price}</td>
          <td>{order.orderno}</td>
          <td>{order.order_date}</td>
        </tr>

      )

    });

  }
  confirmdelete = (key) => {
    const initialDate = new Date();

    this.setState({loading: true});

    //console.log(key);
    db.collection("Order").doc(key).update(
      {
        Ref_Driver_Id  : "", 
        Ref_Driver_Name:"",
        Ref_Order_Status_Id : 5,
        Driver_Accepted_Address:"",
        Driver_Accepted_Latitude:0,
        Driver_Accepted_Longitude:0,
        Updated_At: firebase.firestore.FieldValue.serverTimestamp()
      }).then(res =>{

        this.props.dashreporttoday().then((result) =>{
          console.log('resolved',this.props.dashtoday)
          this.setState({dashstats:this.props.dashtoday});
          }).catch(error => {
          console.log("error");
            this.setState({loading: false});
          })
        this.props.allorder()
          .then(res => {
            console.log(res);
            this.setState({tdata: this.props.order, loading: false, date: initialDate});
            message.success("Order successfully updated!");
          })
          .catch(error => {
            console.log(error);
            this.setState({loading: false});
          })
    
    }).catch(error => {
      message.error("Error removing document: ", error);
      this.setState({loading: false});
    });
    
  
  }
  disabledStartDate = startValue => {
    // const { endValue } = this.state; 
    // if (!startValue || !endValue) 
    // {   return false; }
   return startValue && startValue > moment().endOf("day");
  };

  disabledEndDate = endValue => {
    // const {startValue} = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

  onChange = (field, value) => {
    this.setState({[field]: value});
  };

  onStartChange = value => {
    this.onChange("startValue", value);
  };

  onEndChange = value => {
    this.onChange("endValue", value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({endOpen: true});
    }
  };

  handleEndOpenChange = open => {
    this.setState({endOpen: open});
  };
  handleSubmit = e => {
    e.preventDefault();
  
    this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          
         this.setState({loading: true});
  
          console.log(values);
          this.props.customdashreport(values.start_date,values.end_date).then((result) =>{
           console.log('resolved',this.props.dashtoday)
          this.setState({dashstats:this.props.dashtoday});
          }).catch(error => {
          console.log("error");
          this.setState({loading: false});
          })
        this.props.customeallorder(values.start_date,values.end_date)
        .then(res => {
          console.log(res);
          this.setState({tdata: this.props.order, loading: false,visible:true});
        })
        .catch(error => {
        console.log("error");
          this.setState({loading: false});
        })
  
        }
      });
  };
  //===============================================

  render() {
    const {dashstats,endOpen} = this.state;
    const {getFieldDecorator} = this.props.form;
    // console.log(this.props.products);
    const columns = [
      {
        title: "Order ID",
        dataIndex: "order_id",
        width:140,
        key: "order_id",
        ...this.getColumnSearchProps("order_id")
      }, {
        title: "Customer Name",
        dataIndex: "customer_name",
        key: "customer_name",
        ...this.getColumnSearchProps("customer_name")
      }, {
        title: "Customer Phone",
        dataIndex: "customer_phone",
        key: "customer_phone",
        ...this.getColumnSearchProps("customer_phone")
      },
      {
        title: "No. of Order",
        dataIndex: "orderno",
        width:80,
        key: "orderno"
      }, {
        title: "Driver Name",
        dataIndex: "driver_name",
        key: "driver_name",
        ...this.getColumnSearchProps("driver_name")
      }, {
        title: "Order Status",
        dataIndex: "order_status",
        width:150,
        key: "order_status",
        render: (text, record) => (
          <span>
            {(() => {
              switch (record.orderstatus_id) {
                case 7:
                  return (
                    <Tag color="lime">{record.order_status}</Tag>
                  );
                case 12:
                  return (
                    <Tag color="#87d068">{record.order_status}</Tag>
                  );
                case 9:
                  return (
                    <Tag color="volcano">{record.order_status}</Tag>
                  );

                default:
                  return (
                    <Tag color="orange">{record.order_status}</Tag>
                  );
              }
            })()}
          </span>
        )
      }, {
        title: "Order Date",
        dataIndex: "order_date",
        width:130,
        key: "order_date"
      },
      {
        title: "Store Name",
        dataIndex: "store_name",
        key: "store_name"
      },
      {
        title: "Payment Type",
        dataIndex: "Payment_Type",
        key: "Payment_Type"
      },
       {
        title: "Price",
        dataIndex: "Total_Price",
        key: "Total_Price",
        ...this.getColumnSearchProps("Total_Price")
      },
      {
        title: "App Version",
        dataIndex: "App_version",
        key: "App_version",
        ...this.getColumnSearchProps("App_version")
      },
      {
        title: "Order Device",
        dataIndex: "Order_Request_Device",
        key: "Order_Request_Device",
        ...this.getColumnSearchProps("Order_Request_Device")
      },
      {
        title: "OTP",
        dataIndex: "OTP_Num",
        key: "OTP_Num"
      },{
        title: "Action",
        dataIndex: "action",
        fixed: 'right',
        width: 150,
        key: "action",
        render: (text, record) => (
<span>

{record.orderstatus_id === 10
  ? (
    <span>
    <Link
      to={{
      pathname: '/trackorder',
      orderid: record.key
    }}>
      <Tooltip title="Order Tracking">
        <Button type="primary" shape="circle" icon="environment" />

      </Tooltip>
    </Link>
    <Divider type="vertical" />
    <Link
      to={{
      pathname: '/orderview',
      orderid: record.key
    }}>
      <Tooltip title="View Order ">
        <Button type="primary" shape="circle" icon="eye" />

      </Tooltip>
    </Link>
    <Divider type="vertical" />
    <Tooltip title="Revoke Driver">
        <Button type="primary" shape="circle" onClick={() => this.confirmdelete(record.key)} disabled={record.orderstatus_id === 7 || record.orderstatus_id === 5 || record.orderstatus_id === 17 || record.orderstatus_id === 9 ? false : true} icon="user" />

      </Tooltip>
    </span>
  )
  : (
    <span>
    <Tooltip title="Order Tracking">
      <Button disabled type="primary" shape="circle" icon="environment" />
    </Tooltip>
     <Divider type="vertical" />
     <Link
       to={{
       pathname: '/orderview',
       orderid: record.key
     }}>
       <Tooltip title="View Order ">
        <Button type="primary" shape="circle" icon="eye" />

      </Tooltip>
     </Link>
     <Divider type="vertical" />
    <Tooltip title="Revoke Driver">
        <Button type="primary" shape="circle" onClick={() => this.confirmdelete(record.key)} disabled={record.orderstatus_id === 7 || record.orderstatus_id === 5 || record.orderstatus_id === 17 || record.orderstatus_id === 9 ? false : true} icon="user"  />

      </Tooltip></span>
  )}

</span>
        )
      }
    ];

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h4>Order History</h4>
              </div>

            </div>

          </div>
        </div>
        <div className="container-fluid allproduct">
        
        <Row className="m-b-30">
            <Col>
            <Form layout="inline" onSubmit={this.handleSubmit}>
            <Form.Item label="From Date">
                  {getFieldDecorator("start_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required"
                      }
                    ]
                  })(<DatePicker
                    style={{
                    width: 350
                  }}
                    disabledDate={this.disabledStartDate}
                    
                    format="DD-MM-YYYY"
                    placeholder="Select Date"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}/>)}
                </Form.Item>
            <Form.Item label="To Date">
                  {getFieldDecorator("end_date", {
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "This field is required"
                      }
                    ]
                  })(<DatePicker
                    style={{
                    width: 350
                  }}
                    disabledDate={this.disabledEndDate}
                    
                    format="DD-MM-YYYY"
                    placeholder="Select Date"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}/>)}
                </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          <Button style={{marginLeft: 15}} type="default" onClick={this.handleResett}>Clear</Button>
        </Form.Item>
      </Form>
            </Col>
          </Row>
          <Divider dashed />
        <Row gutter={16} className={this.state.visible === true ?"":"hidden"}>
        <Col xs={24} sm={12} md={6}>
        <Skeleton loading={this.state.loading}>
        <StatCard
          type="fill"
          title="Total Orders"
          value={dashstats.today_total_order}
          icon={<ShoppingCart size={20} strokeWidth={2} />}
          color={"primary"}
          
        />
        </Skeleton>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Skeleton loading={this.state.loading}>
        <StatCard
          type="fill"
          title="Pending Orders"
          value={dashstats.total_pending_order}
          icon={<ShoppingCart size={20} strokeWidth={2} />}
          color={"primary"}
          
        />
        </Skeleton>
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Skeleton loading={this.state.loading}>
        <StatCard
          type="fill"
          title="Delivered Orders"
          value={dashstats.total_order}
          icon={<ShoppingCart size={20} strokeWidth={2} />}
          color={"primary"}
          
        />
        </Skeleton>
      </Col>
       
     
      
      <Col xs={24} sm={12} md={6}>
       <Skeleton loading={this.state.loading}>
        <StatCard
          type="fill"
          title="Turnover"
          value={dashstats.total_cost}
          icon={<Target size={20} strokeWidth={2} />}
          color={"primary"}
          
        />
        </Skeleton>
      </Col>
      
    </Row>
    <div className="row m-t-30">
            <div className="text-left filterdiv col-md-6">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="text-left download-table-xls-button btn ant-btn-primary"
                table="table-to-xls"
                filename="Shopezy-Order-Report"
                sheet="tablexls"
                buttonText="Export Order Report"/></div>
            <div className="text-right filterdiv col-md-6">

              <span style={{
                marginRight: 10
              }}>Last Refreshed Time :
                <TimeAgo date={new Date()}>{({value}) => <b><Icon type="clock-circle" theme="outlined"/> {value}</b>}</TimeAgo>
              </span>
              <Button
                type="primary"
                style={{
                marginLeft: 15
              }}
                onClick={this.handleResett}>
                Refresh
              </Button>

            </div>
          </div>
          <div>

            <table className="hidden" id="table-to-xls">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Customer Name</th>
                  <th>Delivery address</th>
                  <th>Store Name</th>
                  <th>Order Status</th>
                  <th>Amount</th>
                  <th>No_Order</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {this.renderNotes()}
              </tbody>
            </table>
          </div>

          <Table
            columns={columns}
            dataSource={this.state.tdata}
            loading={this.state.loading}
            scroll={{ x: 1660 }}
            size="middle"
            bordered/>
        </div>

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user, 
    order: state.order,
    dashtoday:state.dashtoday,
	common:state.common
  };
}

const WrappedRegistrationForm = Form.create()(Order);

export default connect(mapStateToProps, {emailLogin, logout, orderlive,customeallorder,customdashreport})(WrappedRegistrationForm);
