import {GET_ADDRESS} from '../actionTypes';

export function dispatchaddress(city,address, lat, lng)
{
    return dispatch => {
     
        dispatch({
            type: GET_ADDRESS,
            payload: {city,address, lat, lng}
        })

    }
}