import React, {Component} from 'react';
import {connect} from 'react-redux';
import {allcustomer} from '../actions/userAction';
import {Icon,Table,Popconfirm,Drawer, Form, Button, Col, Row, Input,Spin,message} from 'antd';
//import 'antd/dist/antd.css';
import '../styles/css/App.css';
import Highlighter from 'react-highlight-words';
import {TimeAgo} from "@n1ru4l/react-time-ago";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import _ from 'lodash';
import request from "superagent";
import {Dev_URL} from '../actionTypes';
class Drivers extends Component {

  constructor(props) {
    super(props);
    // state
    this.state = {
      // category: "", subcategory: "",
      disabled: true,
      searchText: '',
      tdata: null,
      loading: true,
      date: '',
      admin_user: '',
      visible: false,
      loader:false,
    };

  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{
        padding: 8
      }}>
        <Input
          ref={node => {
          this.searchInput = node;
        }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value
          ? [e.target.value]
          : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{
          width: 188,
          marginBottom: 8,
          display: 'block'
        }}/>
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{
          width: 90,
          marginRight: 8
        }}>
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{
          width: 90
        }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon
      type="search"
      style={{
      color: filtered
        ? '#1890ff'
        : undefined
    }}/>,
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (<Highlighter
      highlightStyle={{
      backgroundColor: '#ffc069',
      padding: 0
    }}
      searchWords={[this.state.searchText]}
      autoEscape
      textToHighlight={text || ''}/>)
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({searchText: selectedKeys[0]});
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({searchText: ''});
    console.log(this.state.searchText);
  }

  handleCategory = (value) => {
    this.setState({category: value, subcategory: "", disabled: false});
    this
      .props
      .filtersubCat(value);
  }

  handlesubCategory = (value) => {
    this.setState({subcategory: value, loading: true});
    var cat = this.state.category;
    var subcat = value;
    this
      .props
      .filterProduct(cat, subcat)
      .then(res => {
        console.log(res);
        this.setState({tdata: this.props.products, loading: false});
      })
      .catch(error => {
        console.log(error);
      })
  }

  handleResett = () => {
    //  componentWillMount

    const initialDate = new Date();

    this.setState({loading: true});

    this
      .props
      .allcustomer()
      .then(res => {
        //  console.log(res);
        this.setState({tdata: this.props.driver, loading: false, date: initialDate});
      })
      .catch(error => {
        console.log(error);
        this.setState({loading: false});
      })


  }

  componentDidMount() {

    const initialDate = new Date();

    this
      .props
      .allcustomer()
      .then(res => {
        //  console.log(res);
        this.setState({tdata: this.props.driver, loading: false, date: initialDate});
      })
      .catch(error => {
        console.log(error);
        this.setState({loading: false});
      })

  }

  renderNotes() {

    return _.map(this.props.driver, (driver, key) => {

      return (

        <tr key={key}>
          <td>{driver.Customer_Name}</td>
          <td>{driver.Customer_Mobile_Number}</td>
          <td>{driver.Customer_Email_Address}</td>
          <td>{driver.order_count}</td>
          <td>{driver.App_Version}</td>
          <td>{driver.Device_Make}</td>
          <td>{driver.Device_Model}</td>
          <td>{driver.Device_OS}</td>
          <td>{driver.create_at}</td>
        </tr>

      )

    });

  }
  confirm = (key) => {
    this.setState({
      visible: true,
    });
    this.props.form.setFieldsValue({phonenumber: key});  
  }

  cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) 
      {
        this.setState({
          loader: true, visible: false,
        });
      var dataobj = {
        phonenumber : values.phonenumber,
        message : values.message
      }
    request.post(Dev_URL+'/sendsmsweb')
    .send(dataobj)
    .then((res) => {
      console.log(res.body);
      if (res.body.ReturnCode === 200) {
        console.log(res.body);
        this.setState({
         loader: false,
        });
        this.props.form.resetFields();
        message.success('SMS Sent Successfully!');
      } 
      else 
      {
        this.setState({
          loader: false, visible: true,
        });
        message.error('Something went wrong!');
      }
    })
    .catch((err) => {
      console.log(err);
    });
      }
    })
  }
  // ======================================================
  // ===============================================

  render() {

    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'Customer_Name',
        key: 'Customer_Name',

        ...this.getColumnSearchProps('Customer_Name')
      }, {
        title: 'Mobile Number',
        dataIndex: 'Customer_Mobile_Number',
        key: 'Customer_Mobile_Number',
        ...this.getColumnSearchProps('Customer_Mobile_Number')
      }, {
        title: 'Email address',
        dataIndex: 'Customer_Email_Address',
        key: 'Customer_Email_Address',
        ...this.getColumnSearchProps('Customer_Email_Address')
      },
      {
        title: 'No. of Orders',
        dataIndex: 'order_count',
        key: 'order_count',
      },
      {
        title: 'App Version',
        dataIndex: 'App_Version',
        key: 'App_Version',
        ...this.getColumnSearchProps('App_Version')
      },
      {
        title: 'Device Make',
        dataIndex: 'Device_Make',
        key: 'Device_Make',
        ...this.getColumnSearchProps('Device_Make')
      },
      {
        title: 'Device Model',
        dataIndex: 'Device_Model',
        key: 'Device_Model',
        ...this.getColumnSearchProps('Device_Model')
      },
      {
        title: 'Device OS',
        dataIndex: 'Device_OS',
        key: 'Device_OS',
        ...this.getColumnSearchProps('Device_OS')
        
      }, {
        title: 'Created At',
        dataIndex: 'create_at',
        key: 'create_at'
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: (text, record) => (
            <span>

               <Popconfirm title="Are you sure ?" onConfirm={() => this.confirm(record.Customer_Mobile_Number)} onCancel={this.cancel} okText="Yes" cancelText="No">
               <Button type="primary">Send SMS</Button>
                </Popconfirm>
            </span>
          ),

      }
    ];

    return (
      <div>
         <Spin style={{ zIndex: 9999 }} spinning={this.state.loader} tip="Sending SMS...">
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h4>Customer</h4>
              </div>

            </div>

          </div>
        </div>
        <div className="container-fluid allproduct">
        <div className="row m-t-30">
        <div className="text-left filterdiv col-md-6">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="text-left download-table-xls-button btn ant-btn-primary"
                table="table-to-xls"
                filename="Shopezy-Customer-Report"
                sheet="Customer"
                buttonText="Export Customer Report"/></div>
          <div className="text-right filterdiv col-md-6">
            <span style={{
              marginRight: 10
            }}>Last Refreshed Time :
              <TimeAgo date={new Date()}>{({value}) => <b><Icon type="clock-circle" theme="outlined"/> {value}</b>}</TimeAgo>
            </span>
            <Button
              type="primary"
              style={{
              marginLeft: 15
            }}
              onClick={this.handleResett}>
              Refresh
            </Button>

          </div>
          </div>
          <div>

            <table className="hidden" id="table-to-xls">
              <thead>
                <tr>
                  <th>Customer_Name</th>
                  <th>Customer_Mobile_Number</th>
                  <th>Customer_Email_Address</th>
                  <th>No_Of_Orders</th>
                  <th>App_Version</th>
                  <th>Device_Make</th>
                  <th>Device_Model</th>
                  <th>Device_OS</th>
                  <th>Created_At</th>
                </tr>
              </thead>
              <tbody>
                {this.renderNotes()}
              </tbody>
            </table>
          </div>
          <Table
            columns={columns}
            dataSource={this.state.tdata}
            loading={this.state.loading}
            scroll={{ x: 1300 }}
            bordered/>
        </div>
        <Drawer
          title="Send Message to Customer"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}>
             
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="Phone Number">
                  {getFieldDecorator('phonenumber', {
                    rules: [{ required: true, message: 'Please enter user name' }],
                  })(<Input disabled/>)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Message">
                  {getFieldDecorator('message', {
                    rules: [
                      {
                        required: true,
                        message: 'please enter message',
                      },
                      { max: 160, message: 'Message text maximum 160 characters only' }
                    ],
                  })(<Input.TextArea rows={4} placeholder="please enter message" />)}
                </Form.Item>
              </Col>
            </Row>
            <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </div>
          </Form>
        </Drawer>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {user: state.user, driver: state.driver};
}

const App = Form.create()(Drivers);

export default connect(mapStateToProps, {allcustomer})(App);

