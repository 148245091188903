import React, {Component} from 'react';
import {connect} from 'react-redux';
import {mostorder} from '../actions/chartAction';
import {Button,Table,Input,Icon } from 'antd';
import _ from "lodash";
import Highlighter from 'react-highlight-words';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import '../styles/css/App.css';



class mostorderproduct extends Component {

  constructor(props) {
    super(props);
    // state
    this.state = {
      
      loading: true,
      order:"",
      tdata:[]

    };

  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => { this.searchInput = node; }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
            </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
            </Button>
        </div>
      ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
    console.log(this.state.searchText);
  }
  componentDidMount()
  {
	
    this.props.mostorder()
    .then((result) => {
          console.log(result)
          this.setState({loading: false,tdata:result});
    })
    .catch((err) => {});
    
  }
  renderNotes() {

    return _.map(this.state.tdata, (order, key) => {

      return (

        <tr key={key}>
          <td>{order.product_name}</td>
          <td>{order.brand_id}</td>
          <td>{order.ordercount}</td>
        </tr>

      )

    });

  }
  // ======================================================

  render() {

    // ==============================================================================
    const columns = [
      {
        title: "Product Name",
        dataIndex: "product_name",
        key: "product_name",
        ...this.getColumnSearchProps('DeliveredDate'),
        
        
      }, {
        title: "Brand Id",
        dataIndex: "brand_id",
        key: "brand_id",
        
      }, {
        title: "No.of Sales",
        dataIndex: "ordercount",
        key: "ordercount"
      }
    ];
    // ================================//

    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
         
            <div className="row">
              <div className="col-sm-4">
    <h4>Product Sales Report</h4>
              </div>
              

            </div>
          </div>
        </div>
        
          <div className="container-fluid m-t-30 text-left invoice-pad">
          
         
          <div className="row">
        <div className="text-left filterdiv col-md-6">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="text-left download-table-xls-button btn ant-btn-primary"
                table="table-to-xls"
                filename="Shopezy-Product-Report"
                sheet="Product"
                buttonText="Export Report"/></div>
        
          </div>
    
    <div className="row">
    	<div className="col-md-12">
        <div>

<table className="hidden" id="table-to-xls">
  <thead>
    <tr>
      <th>Product_Name</th>
      <th>Brand_Id</th>
      <th>No_Of_Sales</th>
    </tr>
  </thead>
  <tbody>
    {this.renderNotes()}
  </tbody>
</table>
</div>
    			<Table
            columns={columns}
            dataSource={this.state.tdata}
            loading={this.state.loading}
            size="middle"
            bordered/>
    		
    	</div>
    </div>

		 
</div>
          
  

         
       
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {user: state.user};
}

export default connect(mapStateToProps, {mostorder})(mostorderproduct);
