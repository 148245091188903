import { firedb } from "../firebase";
import { GET_CHAT, GET_CHAT_ALL } from "../actionTypes";
import moment from "moment";
import _ from "lodash";

export function allchat() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      firedb
        .ref("/Messages_Master/")
        .orderByChild("Chat_Type").equalTo(1)
        .on("value", function(snapshot) {
          var snap_size = snapshot.numChildren();
          console.log(snap_size);
          var arr = [];
          snapshot.forEach(function(msgmaster2) {
            console.log(msgmaster2.val());
            var Ref_Customer_Name = msgmaster2.val().Ref_Customer_Name;
            var Ref_Customer_Profile_Pic = msgmaster2.val().Ref_Customer_Profile_Pic;
            var Updated_At = msgmaster2.val().Updated_At;
            var doc_id = msgmaster2.key;
            // console.log(doc_id);
            firedb
              .ref("/Messages/" + doc_id)
              .orderByChild("Is_Read")
              .equalTo("False")
              .once("value", function(dataSnapshot) {
                var snap_update_child = dataSnapshot.numChildren();
                var obj = {
                  id: doc_id,
                  Ref_Customer_Name: Ref_Customer_Name,
                  Ref_Customer_Profile_Pic: Ref_Customer_Profile_Pic,
                  Updated_At: Updated_At,
                  Count: snap_update_child,
                  sortdate: Updated_At
                };
                console.log(obj);
                arr.push(obj);
                console.log(snap_size, arr.length);
                if (snap_size === arr.length) {
                  var sortedarr = _.orderBy(arr, ['sortdate'],['desc']);   
                  console.log(sortedarr)     
                  dispatch({
                    type: GET_CHAT_ALL,
                    payload: sortedarr
                  });
                  resolve(sortedarr);
                }
              });
          });
        });
    });
  };
}

export function singlechat(chatid, prechatid) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      console.log(chatid, prechatid);
      firedb.ref("/Messages/" + prechatid).off("value");
      firedb.ref("/Messages/" + chatid).on("value", function(dataSnapshot) {
        var snap_update_child = dataSnapshot.numChildren();
        console.log(snap_update_child);
        dataSnapshot.forEach(function(childsnap_update) {
          // console.log(childsnap_update)
          var Trip_autoid = childsnap_update.key;
          var IsRead = childsnap_update.val().Is_Read;
          // alert(Trip_autoid+'/ '+IsRead)
          if (IsRead === "False") {
            var msgref_update = firedb
              .ref()
              .child("Messages/" + chatid + "/" + Trip_autoid);
             
            msgref_update.update({
              Is_Read: "True"
            });
          }
        });
        dispatch({
          type: GET_CHAT,
          payload: dataSnapshot.val()
        });
        resolve(dataSnapshot.val());
      });
    });
  };
}

export function postchat(admin_user, active_user, msg) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      var postData = {
        Admin_Id: admin_user,
        Updated_At: parseInt(moment().format('X'))
      };
      firedb
        .ref("Messages_Master/" + active_user)
        .update(postData)
        .then(result => {
          firedb
            .ref("Messages_Master/" + active_user)
            .once("value")
            .then(function(snapshot) {
              console.log(snapshot.val().Customer_Id);
              var ref_newapp = firedb.ref("Messages").child(active_user);
              var auto_id = ref_newapp.push();
              auto_id.set({
                From_Id: admin_user,
                Is_Read: "False",
                Message_Date: moment().format("DD-MM-YYYY"),
                Message_Time: moment().format("H:mm:ss"),
                Text: msg,
                Time_Stamp: parseInt(moment().format("X")),
                To_Id: snapshot.val().Customer_Id
              });

              resolve(snapshot);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };
}