import React, {Component} from "react";
import {connect} from "react-redux";
import {logout} from "../actions/userAction";
import {Layout, Menu, Icon} from "antd";

import {Link} from "react-router-dom";

import createHistory from "history/createBrowserHistory";
import Logo from "../styles/img/logo-main-white.svg";

const SubMenu = Menu.SubMenu;

const {Sider} = Layout;

class Navi extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      current: "1",
      collapsed: false
    };
  }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({collapsed});
  };
  componentWillMount() {
    const history = createHistory();
    // Get the current location.
    const location = history.location;
    console.log(location.pathname);
    this.setState({current: location.pathname});
  }

  handleClick = e => {
    // console.log('click ', e);
    this.setState({current: e.key});
  };

  render() {
    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
        console.log(broken);
      }}>
        <div className="logo">
          <img src={Logo} className="img-responsive mainlogo" alt="Logo"/>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          onClick={this.handleClick}
          selectedKeys={[this.state.current]}
          style={{
          lineHeight: "64px"
        }}>
          <Menu.Item key="/">
            <Link to="/">
              <Icon type="dashboard"/>
              <span className="nav-text">
                Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/customer">
            <Link to="/customer">
              <Icon type="user"/>
              <span className="nav-text">
                Customer</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/category">
            <Link to="/category">
              <Icon type="profile"/>
              <span className="nav-text">
                Category</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/subcategory">
            <Link to="/subcategory">
              <Icon type="profile"/>
              <span className="nav-text">
                Sub-Category</span>
            </Link>
          </Menu.Item>
          <SubMenu
            title={< span className = "submenu-title-wrapper" > <Icon type="gold"/> < span className = "nav-text" > Products </span> </span >}>
            {/* <Menu.Item key="/addproduct">
              <Link to="/addproduct">
                <Icon type="gold" />
                <span className="nav-text">Add Products</span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="/premierproducts">
              <Link to="/premierproducts">
                <Icon type="gold"/>
                <span className="nav-text">Premier Products</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/product">
              <Link to="/product">
                <Icon type="gold"/>
                <span className="nav-text">
                  Non-Premier Products</span>
              </Link>
            </Menu.Item>
          </SubMenu>
         
          <Menu.Item key="/user">
            <Link to="/user">
              <Icon type="user"/>
              <span className="nav-text">Driver</span>
            </Link>
          </Menu.Item>
          <SubMenu
            title={< span className = "submenu-title-wrapper" > <Icon type="shop"/> <span className = "nav-text"> Store </span> </span >}>
            <Menu.Item key="/viewstore">
              <Link to="/viewstore">
                <Icon type="shop"/>
                <span className="nav-text">
                  View Store</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/storerequest">
              <Link to="/storerequest">
                <Icon type="shop"/>
                <span className="nav-text">
                  Store Request</span>
              </Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="/order">
            <Link to="/order">
              <Icon type="shop"/>
              <span className="nav-text">
                Order</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/coupon">
            <Link to="/coupon">
              <Icon type="thunderbolt"/>
              <span className="nav-text">
                Coupon</span>
            </Link>
          </Menu.Item>
          <SubMenu
            title={< span className = "submenu-title-wrapper" > <Icon type="shop"/> <span className = "nav-text" > Premier promo </span> </span >}>
            <Menu.Item key="/addpromo">
              <Link to="/addpromo">
                <Icon type="shop"/>
                <span className="nav-text">
                  Add promo</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/premierpromo">
              <Link to="/premierpromo">
                <Icon type="shop"/>
                <span className="nav-text">
                  View promo</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            title={< span className = "submenu-title-wrapper" > <Icon type="shop"/> <span className = "nav-text" > Report </span> </span >}>
            <Menu.Item key="/dailyorderreport">
              <Link to="/dailyorderreport">
                <Icon type="shop"/>
                <span className="nav-text">
                  Order Report</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/mostorder">
              <Link to="/mostorder">
                <Icon type="shop"/>
                <span className="nav-text">
                 Product Sales Report</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/bulksms">
              <Link to="/bulksms">
                <Icon type="message"/>
                <span className="nav-text">
                Bulk SMS Sender</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/notification">
              <Link to="/notification">
                <Icon type="message"/>
                <span className="nav-text">
                Push Notification</span>
              </Link>
            </Menu.Item>
          <Menu.Item key="/chat">
            <Link to="/chat">
              <Icon type="thunderbolt"/>
              <span className="nav-text">
                Chat</span>
            </Link>
          </Menu.Item>

        </Menu>
      </Sider>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {user: state.user};
}

export default connect(mapStateToProps, {logout})(Navi);
