import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { productpromo,promoList,filterproductpromo} from '../actions/assetAction';
import { Icon, message, Select,Avatar, Button, Input, Table, Modal,Tag,Divider,Popconfirm,Tooltip } from 'antd';
import '../styles/css/App.css';
import Highlighter from 'react-highlight-words';
import request from "superagent";
import {Dev_URL} from '../actionTypes';

const Option = Select.Option;


class Premierpromo extends Component {

  constructor(props) {
    super(props);
    // state
    this.state = {
      // category: "",
      // subcategory: "",
      disabled: true,
      searchText: '',
      tdata: null,
      loading: true,
      mimage:''
    };

  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => { this.searchInput = node; }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
            </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
            </Button>
        </div>
      ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
    console.log(this.state.searchText);
  }


  confirm = async key  => {
    this.setState({
      loading: true,
    });
    try {
      const res = await request
        .post(
          Dev_URL+"/activatepromo"
        )
        .send({ PromoID: key, Promo_Status :"2"});

      // res.body, res.headers, res.status
      console.log(res.body);
      let msg = res.body.Message;
      this.props.productpromo().then(res => {
        console.log(res);
        this.setState({
          tdata: this.props.pormoall,
          loading: false
        });
        message.success(msg);
      })
        .catch(error => {
          console.log(error);
        }
        )
    } catch (err) {
      return err;
    }
  }

  confirm1 = async key  => {
    this.setState({
      loading: true,
    });
    try {
      const res = await request
        .post(
          Dev_URL+"/activatepromo"
        )
        .send({ PromoID: key, Promo_Status :"1"});

      // res.body, res.headers, res.status
      console.log(res.body);
      let msg = res.body.Message;
      this.props.productpromo().then(res => {
        console.log(res);
        this.setState({
          tdata: this.props.pormoall,
          loading: false
        });
        message.success(msg);
      })
        .catch(error => {
          console.log(error);
        }
        )
    } catch (err) {
      return err;
    }
  }
  
  cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }

  handleCategory = (value) => {
      console.log(value)
    this.setState({
      category: value,
      loading: true
    });
    this.props.filterproductpromo(value).then(res => {
        console.log(res);
        this.setState({
          tdata: this.props.pormoall,
          loading: false
        });
      })
        .catch(error => {
          console.log(error);
          this.setState({
            tdata: null,
            loading: false
          });
        }
        )
  }

  

  handleResett = () => {
    //  componentWillMount
    this.setState({
      category: "",
      loading: true
    });
    this.props.productpromo().then(res => {
      console.log(res);
      this.setState({
        tdata: this.props.pormoall,
        loading: false
      });
    })
      .catch(error => {
        console.log(error);
      }
      )

  }


  componentDidMount() {
    
    this.props.productpromo().then(res => {
     // console.log(res);
      this.setState({
        tdata: this.props.pormoall,
        loading: false
      });
    })
      .catch(error => {
        console.log(error);
        this.setState({
            tdata: null,
            loading: false
          });
      }
      )
this.props.promoList();
  }
  
  // ======================================================
  renderCategory() {
    return _.map(this.props.common, (common, key) => {
      return (
        <Option value={common.Promotion_Id} key={key}>
          <Avatar src={common.Promotion_Image_Url} />
          {common.Promotion_Type}
          {/* ({categories.cid})  */}
        </Option>
      );
    })
  }
  
  showModal = (e, data) => {

    console.log(data)

    this.setState({
      visible: true,
      mimage:data
    });
  };
  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  //===============================================

  render() {

    
  
    // console.log(this.props.products);
    const columns = [
      {
        title: 'Doc Id',
        dataIndex: 'key',
        key: 'key',
        ...this.getColumnSearchProps('key'),
      },
      {
        title: 'Banner image',
        dataIndex: 'Banner_image',
        key: 'Banner_image',
        width: '10%',
        render: (text, record) => (
          <span>
            <Avatar shape="square" size={64} src={record.Banner_image} onClick={(e) => this.showModal(e, record.Banner_image)} />
          </span>
        ),

      }, {
        title: 'Name',
        dataIndex: 'Promo_Title',
        key: 'Promo_Title',
        ...this.getColumnSearchProps('Promo_Title'),
        render: (text, record) => (
            <span>
               <Tag color="orange">{record.Promo_Title}</Tag>
            </span>
          ),
      },
      {
        title: 'Price',
        dataIndex: 'Promo_Price',
        key: 'Promo_Price',
        ...this.getColumnSearchProps('Promo_Price'),
      },
      {
        title: 'Activation Date',
        dataIndex: 'From_Date',
        key: 'From_Date',
        ...this.getColumnSearchProps('From_Date'),
      },
      {
        title: 'Expired Date',
        dataIndex: 'To_Date',
        key: 'To_Date',
        ...this.getColumnSearchProps('To_Date'),
      },
      {
        title: 'Status',
        dataIndex: 'Promo_Status',
        key: 'Promo_Status',
        render: (text, record) => (
              
            <span>
              {(() => {
                switch (record.Promo_Status_Id) {
                  case 1:
                    return (
                      <Tag color="green">{record.Promo_Status}</Tag>
                    );
                    case 3:
                    return (
                      <Tag color="orange">{record.Promo_Status}</Tag>
                    );
                    case 2:
                    return (
                      <Tag color="orange">{record.Promo_Status}</Tag>
                    );

                    case 4:
                    return (
                      <Tag color="volcano">{record.Promo_Status}</Tag>
                    );

                  default:
                    return (
                      <Tag color="orange">{record.order_status}</Tag>
                    );
                }
              })()}
             
            </span>
          ),
      },
      {
        title: 'Modified Date',
        dataIndex: 'Updated_At',
        key: 'Updated_At',
        ...this.getColumnSearchProps('Updated_At'),
      },
      
      {
        title: 'Action',
        dataIndex: 'action',
        width:120,
        key: 'action',
        render: (text, record) => (
            <span>
              {(() => {
        switch(record.Ref_Promo_Type_Id) {
          case 401:
            return (<span> {record.Promo_Status_Id === 1 ?
               ( <Link to={{ pathname: '/viewdeal',  promoid: record.key, Promo_Status:record.Promo_Status_Id}}>
                   
                       <Button type="primary" shape="circle" icon="eye" />
                   
                </Link>):(<Link to={{ pathname: '/editdeal',  promoid: record.key, Promo_Status:record.Promo_Status_Id}}>
                   
                   <Button type="primary" shape="circle" icon="edit" />
               
            </Link>)}</span>);
          
          default:
            return (<span> {record.Promo_Status_Id === 1 ?
               ( <Link to={{ pathname: '/viewpromo',  promoid: record.key, Promo_Status:record.Promo_Status_Id}}>
                   
                       <Button type="primary" shape="circle" icon="eye" />
                   
                </Link>):(<Link to={{ pathname: '/editpromo',  promoid: record.key, Promo_Status:record.Promo_Status_Id}}>
                   
                   <Button type="primary" shape="circle" icon="edit" />
               
            </Link>)}</span>);
        }
      })()}
             
               {record.Promo_Status_Id === 3 || record.Promo_Status_Id === 2 || record.Promo_Status_Id === 1 ?
              ( <Divider type="vertical" /> ) : null}
              {record.Promo_Status_Id === 3 || record.Promo_Status_Id === 1 ?
(<Tooltip title="Deactivate promo"><Popconfirm title="Are you sure deactivate this promo?" onConfirm={() => this.confirm(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
<Button type="danger" shape="circle"  icon="close-circle" />
 </Popconfirm></Tooltip> ): null}
 {record.Promo_Status_Id === 2 ?
(<Tooltip title="Activate promo"><Popconfirm title="Are you sure activate this promo?" onConfirm={() => this.confirm1(record.key)} onCancel={this.cancel} okText="Yes" cancelText="No">
<Button type="primary" shape="circle"  icon="check-circle" />
 </Popconfirm></Tooltip> ): null}
              
            </span>
          ),

      }
    ];



    return (
      <div>
        <div className="container-fluid addp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <h4>Premier Promo</h4>
              </div>
              <div className="col-sm-8 text-right">
              <Link to="/addpromo"><Button className="pull-right" type="primary" icon="shopping">Add Promo</Button></Link>
              </div>
            </div>

          </div>
        </div>
        <div className="container-fluid allproduct">
          <div className="text-right filterdiv">
            <span style={{ marginRight: 10 }}>Filter:</span>
            <Select
              showSearch
              placeholder="Select promo type"
              optionFilterProp="children"
              onChange={this.handleCategory}
              value={this.state.category}
              filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
              style={{ width: 250 }}
            >
              {this.renderCategory()}
            </Select>
           
            <Button style={{ marginLeft: 15 }} onClick={this.handleResett}>
              Clear
            </Button>

          </div>
          <Table columns={columns} dataSource={this.state.tdata} loading={this.state.loading} bordered />
        </div>
        <Modal
                
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Close
            </Button>,
          ]}
        >
              <div className="center-item">
                <img src={this.state.mimage} className="img-responsive"  alt=""/>
                </div>
              </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    pormoall: state.pormoall,
    common:state.common
    
  };
}


export default connect(mapStateToProps, { productpromo,promoList,filterproductpromo})(Premierpromo);
